import DropDownInput from './DropDownInput';
import TextInput, { MASK_TYPES } from './TextInput';
import AddressInput from './AddressInput';
import SwitchInput from './SwitchInput';
import TitledInput from './TitledInput';
import FormContainer from './FormContainer';
import FormLabel from './FormLabel';

export default {
    DropDownInput,
    TextInput,
    AddressInput,
    SwitchInput,
    TitledInput,
    FormLabel,
    FormContainer,
    TYPES: {
        TEXT_MASK: {
            ...MASK_TYPES,
        },
    },
};
