
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { TextField, inputLabelClasses, useTheme } from '@mui/material';

import { validateEmail } from 'services/utils/validation';

import Modal from 'components/Modal';

const InviteRepairerModal = ({ open, onSubmit, onClose, success = true })=> {
    const theme = useTheme();
    const [email, setEmail] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    const handleEmailChange = (newEmail) => {
        setEmail(newEmail);
        setErrorMsg(validateEmail('Email', newEmail));
    };

    const emailFormElem = (
        <TextField
            variant='outlined'
            sx={{ width: '100%', my: 2 }}
            id='contact-email-input'
            onChange={(event) => handleEmailChange(event.target.value)}
            label='Enter email'
            value={email}
            InputLabelProps={{
                sx: {
                    backgroundColor:'white',
                    px: .5,
                    color: `${theme.palette.grey[500]} !important`,
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    [`&.${inputLabelClasses.shrink}`]: {
                        color: errorMsg?.length > 0 ?
                            `${theme.palette.red[700]} !important` :
                            `${theme.palette.primary[700]} !important`,
                    },
                },
            }}
            error={errorMsg?.length > 0}
            helperText={errorMsg}
        />
    );

    return (
        <Modal
            open={open}
            title={'Invite New Repairer'}
            subTitle={'Description/instructions will go here'}
            onClose={ () => {
                setEmail('');
                onClose();
            }}
            onSubmit={() => {
                onSubmit(email);
                setEmail('');
            }}
            showCloseButton
            showSubmitButton={true}
            submitButtonDisabled={!email || email.length === 0 || errorMsg?.length > 0}
            submitButtonTooltip={errorMsg}
            success={success}
        >
            {emailFormElem}
        </Modal>
    );
};

InviteRepairerModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    success: PropTypes.bool,
};

export default InviteRepairerModal;
