import get from 'lodash.get';
import set from 'lodash.set';

// TODO add this and possibly more to an Error validator on VIN inputs
export const isValidVIN = (vin) => {
    if (vin.length > 17 || vin.toUpperCase().match(/[IOQ]/)) {
        return false;
    }
    return true;
};

export const validateVIN = (value, defaultKey = 'vin', message) => {
    const result = {};
    if (value && !isValidVIN(value.trim())) {
        result[defaultKey] = message || 'Please use a valid VIN';
    }
    return result;
};

export const validateRequired = (propName, value) => {
    if (value == null || value.length == 0) {
        return `${propName} is required.`;
    }
};

export const validateWithRegex = (propName, value, regex) => {
    // Accept empty values to separate required validation from regex validation
    if (value == null || value.length == 0) {
        return;
    }

    if (!RegExp(regex).test(value)) {
        return `${propName} is not valid.`;
    }
};

export const validatePhone = (propName, value) => {
    return validateWithRegex(propName, value, /^(\+?1\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
};

export const validateEmail = (propName, value) => {
    return validateWithRegex(propName, value, /^[\w-.]+@([\w-]+\.)+[\w-]{2,6}$/i);
};

export const validateAddress = (propName, place) => {
    // MTODO: Minimum required props for valid address?
    const requiredProps = [
        'street_name',
        'city',
        'state',
        'country',
    ];

    for (const p of requiredProps) {
        if (!place?.[p]?.length) {
            return 'Selected address is not valid.';
        }
    }
};

export const validateCompound = (propName, value, validators) => {
    const msgs = [];

    let validatorFuncs = validators;
    if (!Array.isArray(validatorFuncs)) {
        validatorFuncs = [validators];
    }

    validatorFuncs.forEach((validator) => {
        if (!validator || typeof validator !== 'function') {
            throw new Error(`Unrecognized Validator type for prop ${propName}: ${JSON.stringify(validator)}`);
        }

        const validationMsg = validator(propName, value);

        if (validationMsg) {
            msgs.push(validationMsg);
        }
    });

    if (msgs.length) {
        return msgs.join(',');
    }
};

export const getValidationMessage = (messages) => {
    if (!messages || typeof messages !== 'object' || Object.values(messages).length < 1) {
        return '';
    }

    const joinMsgs = (msgs) => `${msgs
        .filter((m) => m?.length > 0)
        .map((m) => m.endsWith('.') ? m.slice(0, m.length - 1) : m)
        .join(', ')}.`;

    const extraxtMsgs = (obj) => {
        let objMsg = '';

        for (const val of Object.values(obj)) {
            if (typeof val === 'string') {
                objMsg = joinMsgs([objMsg, val]);
            }

            if (typeof val === 'object') {
                objMsg = joinMsgs([objMsg, extraxtMsgs(val)]);
            }
        }

        return objMsg;
    };

    return extraxtMsgs(messages);
};

// validationDefinitions:
// Define how to validate location properties, each row is an array of the following values:
// validators: Validation function or an array of validation functions
// propPath:  Path to the property to validate (also used to store validation message)
// propTitle: Readable title of the property to use in validation messages
// getValue: optional function used to retrieve the value to be verified in case of complicated logic (i.e. validating address properties)
// EXAMPLE:
// const LOCATION_PROP_VALIDATION = [
//     [validateRequired, 'nickname', 'Location Name'],
//     [[validateRequired, validateAddress], 'address', 'Address', (location) => location],
//     [validatePhone, 'contact_info.phone_number', 'Phone Number'],
//     [validateEmail, 'contact_info.email', 'Email'],
// ];
export const validateObject = (object, validationDefinitions) => {
    const msgs = {};

    const objectToValidate = object ?? {};

    for (const [validators, propName, propTitle, getValue] of validationDefinitions) {
        if (!validators) {
            continue;
        }

        const propValue = getValue && typeof getValue === 'function' ? getValue(objectToValidate) : get(objectToValidate, propName);

        const msg = validateCompound(propTitle, propValue, validators);

        if (msg?.length) {
            set(msgs, propName, msg);
        }
    }

    return msgs;
};
