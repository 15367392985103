import React, { useEffect } from 'react';

import { Box, Stack, Typography } from '@mui/material';

import { useError } from 'contexts/ErrorContext';
import { useCompany, actions, TYPES as COMPANY_TYPES } from 'services/company';
import { getValidationMessage } from 'services/utils/validation';

import CompanyFrom from './CompanyForm';
import Loading from 'components/Loading';

const CompanyInfo = () => {
    const errorContext = useError();
    const companyContext = useCompany();
    const company = companyContext.state?.company ?? {};
    const errorMessage = getValidationMessage(companyContext?.state?.validations || {});

    const handlePropChange = (propName, propValue) => {
        companyContext.DISPATCH({
            type: COMPANY_TYPES.EDIT_COMPANY_PROP,
            payload: { propName, propValue },
        });

    };

    const handleSubPropChange = (mainProp, propName, propValue) => {
        companyContext.DISPATCH({
            type: COMPANY_TYPES.EDIT_SUB_PROP,
            payload: { mainProp, propName, propValue },
        });
    };

    const handleAddressChange = (type, place) => {
        companyContext.DISPATCH({
            type: COMPANY_TYPES.EDIT_ADDRESS,
            payload: { type, place },
        });
    };

    const saveUpdatedCompany = () => {
        actions.updateCompany(company, companyContext, errorContext);
    };

    const undoCompanyChanges = () => {
        actions.refreshCompany(companyContext);
    };

    useEffect(() => {
        actions.validateCompany(companyContext);
    }, [company]);

    // MTODO: make loading based on actual actual loading behavior (like on cancel, reloading company should reshow loading elem)
    const loadingElem = Object.values(company).length ? null : <Loading loading fullScreen={false} width={150} height={750} />;

    const detailsElem = (
        <Stack direction='column' sx={{ width: '80%' }}>
            <Typography textAlign={'center'} my={5} sx={{ width: '100%', px: 2, fontSize: 20, fontWeight: 400 }}>
                Details
            </Typography>
            <CompanyFrom
                company={company}
                handlePropChange={handlePropChange}
                handleSubPropChange={handleSubPropChange}
                handleMailingAddressChange={(place) => handleAddressChange('mailing_address', place)}
                handleBillingAddressChange={(place) => handleAddressChange('billing_address', place)}
                validations={companyContext?.state?.validations || {}}
                showCancelBtn={true}
                showSubmitBtn={true}
                cancelBtnDisabled={!company.edited}
                submitBtnDisabled={
                    !company.edited || Object.values(companyContext?.state?.validations || {}).length > 0
                }
                onSubmit={saveUpdatedCompany}
                onCancel={undoCompanyChanges}
                submitBtnTooltip={errorMessage}
            />
        </Stack>
    );

    return (
        <Box display='flex' sx={{ width: '100%', justifyContent: 'center' }}>
            {loadingElem ?? detailsElem}
        </Box>
    );
};

export default CompanyInfo;
