import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
    Box,
    Button,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    Typography,
    useTheme,
} from '@mui/material';

import SortIcon from '@mui/icons-material/Sort';

import Modal from 'components/Modal';
import ServiceHistoryCard from './ServiceHistoryCard';

const sortingOptions = {
    'asc': 'Newest to Oldest',
    'desc': 'Oldest to Newest',
};

const ServiceHistoryModal = ({ open, onClose, service, supplier }) => {
    const theme = useTheme();
    const [orderBy, setOrderBy] = useState(sortingOptions.asc);
    const sortingByAscending = orderBy === sortingOptions.asc;
    const history = service['service_history'];
    const orderedServiceHistory = history
        .sort(
            (a, b) => (sortingByAscending ? -1 : 1) * ((new Date(a.created_at)).valueOf() - (new Date(b.created_at)).valueOf()),
        );

    const getCard = (el, key) => {
        return (
            <ServiceHistoryCard
                key={key}
                creationItem={sortingByAscending ? key === orderedServiceHistory.length - 1 : key === 0}
                historyItem={el}
                supplier={supplier}
                repairer={service?.['repair_company']?.name}
                service={service}
            />
        );
    };

    return (
        <Modal
            open={open}
            onClose={onClose}
        >
            <Stack direction='column' sx={{ width: '100%' }}>
                <Typography sx={{ color: theme.palette.grey[800], fontSize: 20, textAlign: 'center', width: '100%' }} my={1.5}>
                    Service History
                </Typography>
                {/* <Select
                    variant='outlined'
                    labelId='service-history-filter-by-select-label'
                    id='service-history-filter-by-select'
                    value={orderBy}
                    label='Sort By'
                    onChange={(event) => { setOrderBy(event.target.value); }}
                    sx={{
                        p: 0.5,
                        pl: 2,
                        textAlign: 'center',
                        '& .MuiSelect-select': {
                            width: '100%',
                            pr: '20%',
                        },
                    }}
                    inputProps={{
                        style: {
                            background: 'red',
                        },
                    }}
                >
                    <MenuItem value={sortingOptions.asc} sx={{ width: '100%', pr: '12%', textAlign: 'center' }}>
                        <ListItemIcon>
                            <SortIcon style={{ transform: 'scaleY(-1)' }} />
                        </ListItemIcon>
                        <ListItemText>
                            {sortingOptions.asc}
                        </ListItemText>
                    </MenuItem>
                    <MenuItem value={sortingOptions.desc} sx={{ width: '100%', pr: '12%', textAlign: 'center' }}>
                        <ListItemIcon>
                            <SortIcon />
                        </ListItemIcon>
                        <ListItemText>
                            {sortingOptions.desc}
                        </ListItemText>
                    </MenuItem>
                </Select> */}
                <Box sx={{ my: '0px', width: '100%' }}>
                    <Button
                        variant='outlined'
                        type='button'
                        id='service-history-filter-by-btn'
                        value={orderBy}
                        onClick={() => { setOrderBy(sortingByAscending ? sortingOptions.desc : sortingOptions.asc); }}
                        sx={{
                            width: '100%',
                            textAlign: 'left',
                            alignContent: 'left',
                            '& .MuiButton-startIcon': { marginRight: '10px' },
                        }}
                        startIcon={<SortIcon style={{ transform: `scaleY(${sortingByAscending ? '1' : '-1'})` }} />}
                    >
                        {orderBy}
                    </Button>
                </Box>
                <Grid container direction={'column'} alignItems={'center'}>
                    <Stack direction='row'>
                        <Divider orientation='vertical' flexItem sx={{ my: 4, position: 'relative', left: '22px', zIndex: 0 }} />
                        <Grid
                            item
                            sx={{
                                width: '100%',
                                maxHeight: 600,
                                zIndex: 1,
                                overflowY: 'scroll',
                                '&::-webkit-scrollbar' : {
                                    display: 'none',
                                },
                            }}>
                            {
                                orderedServiceHistory.map((el, key) => getCard(el, key))
                            }
                        </Grid>
                    </Stack>
                </Grid>
            </Stack>
        </Modal>
    );
};

ServiceHistoryModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    service: PropTypes.object,
    supplier: PropTypes.string,
};

export default ServiceHistoryModal;
