import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Accordion as MuiAccordion, AccordionSummary, Typography, AccordionDetails, styled } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

import Styles from './styles';

const DefaultAccordion = styled(MuiAccordion)(Styles.AccordionStyles.default);
const SecondaryAccordion = styled(MuiAccordion)(Styles.AccordionStyles.secondary);

const StyledTitle = styled(Typography)(Styles.AccordionTitleStyle);

const Accordion = ({
    children,
    summary,
    iconElem = null,
    titleAlign = 'center',
    style = 'Default',
    summaryProps = {},
    detailsProps = {},
    ...rest
}) => {
    const IconElem = !iconElem ? null : (
        <div>
            {iconElem}
        </div>
    );

    const StyledAccordion = style === 'Secondary' ? SecondaryAccordion : DefaultAccordion;

    return (
        <StyledAccordion variant={'outlined'} {...rest}>
            <AccordionSummary expandIcon={<ExpandMore />} sx={{ alignContent: 'center' }} {...summaryProps}>
                <Grid container sx={{ width: '100%', alignItems: 'center' }}>
                    <Grid item sm={2}>
                        {IconElem}
                    </Grid>
                    <Grid item sm={IconElem ? 10 : 12}>
                        <StyledTitle sx={{ textAlign: titleAlign, width: '100%' }}>{ summary }</StyledTitle>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails {...detailsProps}>
                { children }
            </AccordionDetails>
        </StyledAccordion>
    );
};

Accordion.propTypes = {
    summary: PropTypes.string.isRequired,
    children: PropTypes.any,
    iconElem: PropTypes.node,
    titleAlign: PropTypes.oneOf(['center', 'left', 'right']),
    style: PropTypes.oneOf(['Default', 'Secondary']),
    summaryProps: PropTypes.object,
    detailsProps: PropTypes.object,
};

export default Accordion;
