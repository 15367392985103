
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { TextField, inputLabelClasses, useTheme } from '@mui/material';

import { validateEmail } from 'services/utils/validation';

import Modal from 'components/Modal';
import MultiSelect from 'components/Inputs/MultiSelect';

const AssignLocationModal = ({ open, locations, onSubmit, onClose, inviteRepairer = false, success = false })=> {
    const theme = useTheme();
    const [email, setEmail] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [selectedLocations, setSelectedLocations] = React.useState([]);

    const handleEmailChange = (newEmail) => {
        setEmail(newEmail);
        setErrorMsg(validateEmail('Email', newEmail));
    };

    const title = inviteRepairer ? 'Invite New Repairer' : 'Assign New Locations';

    const emailFormElem = !inviteRepairer ? null : (
        <TextField
            variant='outlined'
            sx={{ width: '100%', my: 2 }}
            id='contact-email-input'
            onChange={(event) => handleEmailChange(event.target.value)}
            label='Enter email'
            value={email}
            InputLabelProps={{
                sx: {
                    backgroundColor:'white',
                    px: .5,
                    color: `${theme.palette.grey[500]} !important`,
                    fontSize: '16px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    [`&.${inputLabelClasses.shrink}`]: {
                        color: errorMsg?.length > 0 ?
                            `${theme.palette.red[700]} !important` :
                            `${theme.palette.primary[700]} !important`,
                    },
                },
            }}
            error={errorMsg?.length > 0}
            helperText={errorMsg}
        />
    );

    const formInvalid = inviteRepairer ? (!email || email.length === 0 || errorMsg?.length > 0) : errorMsg?.length > 0;

    return (
        <Modal
            open={open}
            title={title}
            subTitle={'Description/instructions will go here'}
            onClose={ () => {
                setEmail('');
                onClose();
            }}
            onSubmit={() => {
                onSubmit({ email, selectedLocations });
                setEmail('');
            }}
            showCloseButton
            showSubmitButton
            submitButtonDisabled={formInvalid}
            submitButtonTooltip={errorMsg}
            success={success}
        >
            {emailFormElem}
            <MultiSelect
                label={'Assign Locations(s)'}
                options={locations}
                selected={selectedLocations}
                onChange={setSelectedLocations}
                selectMenuTitle='Assign locations to repairer'
                getOptionLabel={(loc) => loc.nickname}
                isOptionSelected={(selected, option) => {
                    const isSelected = (selected || []).includes(option);
                    return isSelected;
                }}
            />
        </Modal>
    );
};

AssignLocationModal.propTypes = {
    open: PropTypes.bool.isRequired,
    locations: PropTypes.arrayOf(PropTypes.string).isRequired,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    inviteRepairer: PropTypes.bool,
    success: PropTypes.bool,
};

export default AssignLocationModal;
