import styles from '../../styles';

const loading = {
    transition: 'opacity .5s ease-in-out',
    backgroundColor: 'rgba(255, 255, 255, .65)',
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
    zIndex: 99999,
    display: 'grid',
    alignItems: 'center',
    pointerEvents: 'none',
};

export default {
    ...styles,
    loading,
};
