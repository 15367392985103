const DefaultAccordionStyle = ({ theme }) => ({
    width: '100%',
    backgroundColor: theme.palette.white,
    mb: 4,
    borderRadius: '16px',
    border: `1px solid ${theme.palette.grey[50]}`,
    background: theme.palette.white,
    '&:before': { display: 'none' },
    '&:first-of-type': {
        borderRadius: '16px',
    },
    '&:last-of-type': {
        borderRadius: '16px',
    },
});

const SecondaryAccordionStyle = ({ theme }) => ({
    width: '100%',
    backgroundColor: theme.palette.white,
    mb: 4,
    borderRadius: '0px',
    border: 'none',
    borderBottom: `1px solid ${theme.palette.grey[50]}`,
    background: theme.palette.white,
    '&:before': { display: 'none' },
    '&:first-of-type': {
        borderRadius: '0px',
    },
    '&:last-of-type': {
        borderRadius: '0px',
    },
});

const AccordionTitleStyle = ({ theme }) => ({
    width: '100%',
    color: theme.palette.grey[300],
    align: 'center',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '1px',
    fontWeight: 700,
});

const AccordionStyles = {
    default: DefaultAccordionStyle,
    secondary: SecondaryAccordionStyle,
};

export default {
    AccordionStyles,
    AccordionTitleStyle,
};
