import React from 'react';
import PropTypes from 'prop-types';

import { useTheme, Grid, ButtonBase, Typography, FormHelperText, Tooltip } from '@mui/material';

import MuiDeleteIcon from '@mui/icons-material/DeleteOutlined';

const TitledInput = ({
    id,
    label,
    children,
    showDeleteButton = false,
    onDelete = () => {},
    helperText = '',
    disabled = false,
    errorMsg = '',

}) => {
    const theme = useTheme();

    let deleteElem = !showDeleteButton ? null : (
        <ButtonBase
            onClick={() => { onDelete(); }}
            sx={{ borderRadius: '20px', p: 1, ml: -1 }}
            disabled={disabled}
        >
            <MuiDeleteIcon id={`${id}-delete-icon`} stroke='none' sx={{ color : theme.palette.grey[200] }} />
        </ButtonBase>
    );

    let userMessage = errorMsg?.length ? errorMsg : helperText;

    const helperTextElem = (
        <FormHelperText sx={{ backgroundColor: theme.palette.white, height: '100%', position: 'relative', top: '-12px' }}>
            {userMessage}
        </FormHelperText>
    );

    return (
        <Grid
            item
            display='flex'
            pl={1.5}
            m={0}
            sx={{ height: '70px', borderRadius: '0px', border: 'none', borderBottom: `1px solid ${theme.palette.grey[50]}` }}
        >
            <Grid container direction='column' sx={{ width: '100%' }}>
                <Grid
                    container
                    item
                    direction='row'
                    sx={{ width: '100%', height: '40px', minHeight: '40px', alignItems: 'center', pr: 0 }}
                >
                    <Grid
                        item
                        sm={showDeleteButton ? 1.7 : 0}
                        sx={{ height: '30px', position: 'relative', bottom: '-2px' }}
                    >
                        {deleteElem}
                    </Grid>
                    <Grid item sm={showDeleteButton? 6.3 : 8}>
                        <Tooltip title={label}>
                            <Typography
                                sx={{
                                    color: theme.palette.grey[500],
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    lineHeight: '40px',
                                    overflow: 'clip',
                                    position: 'relative',
                                    bottom: '-6px',
                                }}
                                noWrap
                            >
                                { label }
                            </Typography>
                        </Tooltip>
                    </Grid>
                    <Grid
                        container
                        direction='row-reverse'
                        item
                        sm={4}
                        sx={{ position: 'relative', bottom: '-4px' }}
                    >
                        {children}
                    </Grid>
                    <Grid item>
                        {helperTextElem}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

TitledInput.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    children: PropTypes.node,
    showDeleteButton: PropTypes.bool,
    onDelete: PropTypes.func,
    helperText: PropTypes.string,
    disabled: PropTypes.bool,
    errorMsg: PropTypes.string,
};

export default TitledInput;