// @ts-nocheck
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { IMaskInput } from 'react-imask';
import { useTheme, Grid, FormControl, Input, InputLabel, inputLabelClasses, FormHelperText, Tooltip } from '@mui/material';

export const MASK_TYPES = {
    PHONE: 'PHONE',
    CURRENCY: 'CURRENCY',
};

const MASKS = {
    [MASK_TYPES.PHONE]: '+1 (#00) 000-0000',
    [MASK_TYPES.CURRENCY]: '$positiveNum',
};

const MASK_DEFINITIONS = {
    '#': /[1-9]/,
};

const MASK_BLOCKS = {
    positiveNum: {
        mask: Number,
        thousandsSeparator: ',',
        radix: '.',
        mapToRadix: ['.'],
        scale: 2,
        padFractionalZeros: true,
        min: 0,
    },
};

const createMaskedInput = (maskType) => {
    const MaskedInput = forwardRef(function MaskedInput(props, ref) {
        const { onChange, name, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask={MASKS[maskType]}
                definitions={MASK_DEFINITIONS}
                inputRef={ref}
                onAccept={(value) => onChange({ target: { name: name, value } })}
                overwrite={maskType === MASK_TYPES.PHONE}
                blocks={MASK_BLOCKS}
            />
        );
    });

    MaskedInput.propTypes = {
        name: PropTypes.string,
        mask: PropTypes.string,
        onChange: PropTypes.func,
    };

    return MaskedInput;
};

const MASKED_INPUTS = Object.values(MASK_TYPES)
    .map((maskType) => ([maskType, createMaskedInput(maskType)]))
    .reduce((acc, [maskType, MaskInput]) => ({ ...acc, [maskType]: MaskInput }), {});

const TextInput = ({
    id,
    onChange,
    label,
    value,
    helperText = '',
    disabled,
    errorMsg,
    inputRef,
    maskType = MASK_TYPES.NONE,
    noBackground = false,
}) => {
    const theme = useTheme();

    let otherInputProps = {};

    if (maskType && Object.values(MASK_TYPES).includes(maskType)) {
        const MaskedInput = MASKED_INPUTS[maskType];

        otherInputProps = {
            ...otherInputProps,
            inputComponent: MaskedInput,
            name: `${id}_phone_input`,
        };
    }

    if (inputRef) {
        otherInputProps = {
            ...otherInputProps,
            inputRef,
        };
    }

    let backgroundColor = value == null || value.length == 0 ? theme.palette.white : theme.palette.blue[30];

    if (noBackground) {
        backgroundColor = 'transparent';
    }

    let userMessage = errorMsg?.length ? errorMsg : helperText;

    const helperTextElem = (
        <Tooltip title={userMessage}>
            <FormHelperText
                sx={{
                    backgroundColor: noBackground ? backgroundColor : theme.palette.white,
                    height: '20px',
                    pl: 2,
                    textWrap: 'nowrap',
                    overflowX: 'clip',
                    overflowY: 'visible',
                    position: 'relative',
                    top: '-3px',
                    minHeight: '16px',
                }}
            >
                {userMessage}
            </FormHelperText>
        </Tooltip>
    );

    return (
        <Grid item display='flex' justifyContent={'center'} p={0} m={0} minHeight='80px'>
            <FormControl
                sx={{
                    m: 0.5,
                    p: 0,
                    pt: 1,
                    width: '80%',
                    backgroundColor,
                    maxHeight: '68px',
                    // Override browser's default autofill behavior causing the input to look weird (two-colors)
                    'input:-webkit-autofill, input:-webkit-autofill:hover,  input:-webkit-autofill:focus,  input:-webkit-autofill:active': {
                        'webkitBoxShadow': `0 0 0 30px ${backgroundColor} inset !important`,
                    },
                }}
                variant={'standard'}
                error={errorMsg?.length > 0}
            >
                <InputLabel
                    sx={{
                        ml: 1,
                        pt: 1.5,
                        pl: 1,
                        width: '100%',
                        lineHeight: 1.2,
                        fontSize: '16px',
                        fontWeight: 400,
                        color: theme.palette.grey[500],
                        [`&.${inputLabelClasses.shrink}`]: {
                            fontSize: '12px',
                        },
                    }}
                    htmlFor={id}
                >
                    { label }
                </InputLabel>
                <Input
                    id={id}
                    inputProps={{
                        style: {
                            fontSize: '16px',
                            textTransform: 'none',
                            marginLeft: '12px',
                            paddingLeft: 2,
                        },
                    }}
                    sx={{
                        width: '100%',
                        border: '0',
                    }}
                    onChange={onChange}
                    disabled={disabled}
                    value={value ?? ''}
                    { ...otherInputProps }
                />
                {helperTextElem}
            </FormControl>
        </Grid>
    );
};

TextInput.propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    helperText: PropTypes.string,
    disabled: PropTypes.bool,
    errorMsg: PropTypes.string,
    inputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
    maskType: PropTypes.oneOf(Object.values(MASK_TYPES)),
    noBackground: PropTypes.bool,
};

export default TextInput;
