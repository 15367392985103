import pushManager from './pushManager';

class SWManager {
    async initialize(app, notificationCallback) {
        this.app = app;

        if ('serviceWorker' in navigator) {
            try {
                console.log('Registering Service worker...');
                const registration = await navigator.serviceWorker.register('service-worker.js');
                this.registration = registration;
                pushManager.initialize(registration, app, notificationCallback);
            } catch(err) {
                console.log('Service worker registration failed, error:', err);
            }
        } else {
            console.warn('Service workers are not supported.');
        }
    }

    // send message to service worker
    sendMessage(message) {
        return new Promise((resolve, reject) => {
            if (navigator.serviceWorker.controller) {
                navigator.serviceWorker.controller.postMessage(message);
                resolve();
            } else {
                reject('No service worker controller');
            }
        });
    }
}

export default new SWManager();
