import { parseAddress, formatAddress } from 'services/utils/addressParser';

import TYPES from './types';

const defaultFilters = {
    repairers: [
        {
            id:'repairer1',
            name: 'Repairer 1',
            selected: false,
        },
        {
            id:'repairer2',
            name: 'Repairer 2',
            selected: false,
        },
        {
            id:'repairer3',
            name: 'Repairer 3',
            selected: false,
        },
    ],
    status: [
        {
            id:'status1',
            name: 'Status 1',
            selected: false,
        },
        {
            id:'status2',
            name: 'Status 2',
            selected: false,
        },
        {
            id:'status3',
            name: 'Status 3',
            selected: false,
        },
    ],
    invoice: [
        {
            id:'invoice1',
            name: 'Invoice Type 1',
            selected: false,
        },
        {
            id:'invoice2',
            name: 'Invoice Type 2',
            selected: false,
        },
        {
            id:'invoice3',
            name: 'Invoice Type 3',
            selected: false,
        },
    ],
};

const EMPTY_ADDRESS = {
    'street_number': '',
    'street_name': '',
    city: '',
    state: '',
    country: '',
    zip: '',
};

const INITIAL_NEW_LOCATION_STATE = {
    nickname: '',
};

export const INITIAL_STATE = {
    locations: [],
    activeLocationId: null,
    newLocation: { ...INITIAL_NEW_LOCATION_STATE },
    validations: {},
    newLocationValidations: {},
    filters: defaultFilters,
    searchTerm: '',
    loaded: false,
};

export const INIT = function () {
    return { ...INITIAL_STATE };
};

const LocationReducer = function (state, action) {
    switch (action.type) {
        case TYPES.INIT: {
            return { ...INITIAL_STATE };
        }
        case TYPES.SET_LOCATIONS: {
            const newLocations = [];

            for (const loc of action.payload) {
                newLocations.push(formatLocationIntoState(loc));
            }

            return { ...state, locations: newLocations };
        }
        case TYPES.SET_LOCATION: {
            const newLocations = [...state.locations.filter((loc) => loc.id !== action.payload.id)];

            newLocations.push(formatLocationIntoState(action.payload));

            return { ...state, locations: newLocations };
        }
        case TYPES.SET_ACTIVE_LOCATION: {
            return { ...state, activeLocationId: action.payload.id };
        }
        case TYPES.EDIT_LOCATION_PROP: {
            const { propName, propValue } = action.payload;

            const loc = { ...(state.locations.find((l) => l.id === state.activeLocationId) || {}) };
            const newLocations = [...state.locations.filter((loc) => loc.id !== state.activeLocationId)];

            const oldProp = loc[propName];
            loc[propName] = propValue;
            if ((oldProp != null || propValue != null) && oldProp != propValue) {
                loc.edited = true;
            }

            newLocations.push(loc);

            return { ...state, locations: newLocations, validations: {} };
        }
        case TYPES.EDIT_LOCATION_CONTACT_PROP: {
            const { propName, propValue } = action.payload;

            const loc = { ...(state.locations.find((l) => l.id === state.activeLocationId) || {}) };
            const newLocations = [...state.locations.filter((loc) => loc.id !== state.activeLocationId)];

            loc['contact_info'] = loc['contact_info'] ? { ...loc['contact_info'] } : {};

            const oldProp = loc.contact_info[propName];
            loc.contact_info[propName] = propValue;
            if ((oldProp != null || propValue != null) && oldProp != propValue) {
                loc.edited = true;
            }

            newLocations.push(loc);

            return { ...state, locations: newLocations };
        }
        case TYPES.EDIT_LOCATION_ADDRESS: {
            const place = action.payload;

            let loc = { ...(state.locations.find((l) => l.id === state.activeLocationId) || {}) };
            const newLocations = [...state.locations.filter((loc) => loc.id !== state.activeLocationId)];

            const newAddress = place ? parseAddress(place) : EMPTY_ADDRESS;

            loc = { ...loc, ...newAddress };
            loc.edited = true;

            newLocations.push(loc);

            return { ...state, locations: newLocations };
        }
        case TYPES.CHANGE_LOCATION_VALIDATION: {
            return { ...state, validations: action.payload };
        }
        case TYPES.UPDATE_LOCATION: {
            const id = action.payload.id;
            action.payload.edited = false;

            const newLocations = [...state.locations.filter((loc) => loc.id !== id)];

            newLocations.push(formatLocationIntoState(action.payload));

            return { ...state, locations: newLocations };
        }
        case TYPES.CLEAR_NEW_LOCATION: {
            return { ...state, newLocation: {} };
        }
        case TYPES.EDIT_NEW_LOCATION_PROP: {
            const { propName, propValue } = action.payload;

            const newLocation = state.newLocation ? { ...state.newLocation } : { 'contact_info': {} };

            newLocation[propName] = propValue;

            return { ...state, newLocation };
        }
        case TYPES.EDIT_NEW_LOCATION_CONTACT_PROP: {
            const { propName, propValue } = action.payload;

            const newLocation = state.newLocation ? { ...state.newLocation } : { 'contact_info': {} };

            newLocation['contact_info'] = newLocation['contact_info'] ? { ...newLocation['contact_info'] } : {};

            newLocation.contact_info[propName] = propValue;

            return { ...state, newLocation };
        }
        case TYPES.EDIT_NEW_LOCATION_ADDRESS: {
            const place = action.payload;

            let newLocation = { ...state.newLocation };

            const newAddress = place ? parseAddress(place) : EMPTY_ADDRESS;

            newLocation = { ...newLocation, ...newAddress };

            return { ...state, newLocation };
        }
        case TYPES.CHANGE_NEW_LOCATION_VALIDATION: {

            return { ...state, newLocationValidations: action.payload };
        }
        case TYPES.CREATE_LOCATION: {
            const location = action.payload;

            const newLocations = [ ...state.locations, location ];
            const newLocation = { ...INITIAL_NEW_LOCATION_STATE };

            return { ...state, locations: newLocations, newLocation };
        }
        case TYPES.UPDATE_FILTERS: {
            const { propName, selectedOptions } = action.payload;
            const newLocationFilters = { ...state.filters };
            const newOptions = [];

            for (const option of newLocationFilters[propName]) {
                const newOption = { ...option };

                if (selectedOptions.some((selectedFilter) => selectedFilter.id === option.id)) {
                    newOption.selected = true;
                } else {
                    newOption.selected = false;
                }

                newOptions.push(newOption);
            }

            newLocationFilters[propName] = newOptions;

            return { ...state, filters: newLocationFilters };
        }
        case TYPES.UPDATE_SEARCH_TERM: {
            return { ...state, searchTerm: action.payload.searchTerm };
        }
        case TYPES.SET_LOCATIONS_LOADING: {
            return { ...state, loaded: false };
        }
        case TYPES.SET_LOCATIONS_LOADED: {
            return { ...state, loaded: true };
        }
        default: {
            return { ...state };
        }
    }
};

const formatLocationIntoState = (location) => {
    const formattedLocation = { ...location };
    if (!formattedLocation.formatted_address) {
        formattedLocation['formatted_address'] = formatAddress(formattedLocation);
    }
    return formattedLocation;
};

export default LocationReducer;
