import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Paper, Typography, useTheme, Button, Divider } from '@mui/material';

import { ReactComponent as RepairIcon } from 'assets/images/repair_icon.svg';
import { ReactComponent as ServiceIcon } from 'assets/images/service_icon.svg';

import { useJobs, TYPES as JOB_TYPES } from 'services/job';
import { cancelService } from 'services/actions/service';
import { useError } from 'contexts/ErrorContext';

import Loading from 'components/Loading';
import RepairerCards from './RepairerCards';

import ServiceBoard from './ServiceBoard';

const quickOptionsEnums = {
    'CANCELLABLE': ['OPEN'],
    'REWORKABLE': ['COMPLETED', 'CLOSED', 'INVOICED'],
    'REASSIGNABLE': ['OPEN', 'CANCELLED'],
};

const capitalize = (str) => {
    if (!str || typeof str !== 'string') {
        return '';
    }

    return str.toLowerCase()
        .replace(/\b\w/g, (match) => match.replace(/\w/, (m) => m.toUpperCase()));
};

const JobDetails = ({ activeJob }) => {
    console.log('job details:', activeJob);

    if (!activeJob) {
        return <Loading loading fullScreen={false} width={150} height={150} />;
    }

    const jobsContext = useJobs();
    const theme = useTheme();
    const err = useError();

    const handleCancelButton = async function(option) {
        try {
            await Promise.all(option.payload.map(el => cancelService(el)));
            await jobsContext.DISPATCH({ type: JOB_TYPES.SET_ACTIVE_JOB, payload: 0 }); // Reloads Page
        } catch (error) {
            console.log(error);
            // @ts-ignore
            err.DISPATCH({ type: 'API_ERROR', payload: error });
        }
    };

    const getYYMC = function() {
        let yymc = [];

        if (activeJob.vehicle) {
            if (activeJob.vehicle.year || activeJob.vehicle.make || activeJob.vehicle.model) {
                yymc.push(
                    `${activeJob.vehicle.year || ''} ${capitalize(activeJob.vehicle.make) || ''} ${capitalize(activeJob.vehicle.model) || ''}`
                );
            }
            if (activeJob.vehicle.color) {
                yymc.push(capitalize(activeJob.vehicle.color));
            }
        }
        return yymc.join(', ');
    };

    const dateCreated = `Created on: ${new Date(activeJob.created_at).toDateString()}`;

    const getStockRO = function() {
        if (activeJob.vehicle) {
            return `Stock/RO: ${activeJob.vehicle.stock_number || ''}`;
        }
    };

    const getVIN = function() {
        if (activeJob.vehicle) {
            return `VIN: ${activeJob.vehicle.vin || ''}`;
        }
    };

    const getPO = function() {
        return `PO#: ${activeJob.po_number || ''}`;
    };

    const getLocation = function() {
        return activeJob.location.nickname || 'Location nickname';
    };

    return (
        <Paper elevation={0} sx={{ pb: 4, width: '100%' }}>
            { activeJob.vehicle && activeJob.services && (
                <Grid container justifyContent={'center'} display={'flex'}>
                    <Grid item sx={{ height: '50px', width: '100%', px: 2 }} alignItems={'center'} display={'flex'} justifyContent={'space-between'}>
                        <Typography sx={{ fontSize: 12, fontWeight: 300 }} color={theme.palette.grey[500]}> {dateCreated} </Typography>
                        { activeJob.services.filter(el => quickOptionsEnums['CANCELLABLE'].includes(el.service_status.toUpperCase())).length > 0 && (
                            <Button
                                sx={{
                                    textTransform: 'uppercase',
                                    fontSize: 10,
                                    borderRadius: theme.spacing(1),
                                    padding: '2px 8px',
                                    marginRight: theme.spacing(1.5),
                                    marginLeft: theme.spacing(1.5),
                                    background: theme.palette.blue.main,
                                    color: theme.palette.white,
                                    '&: hover': {
                                        background: theme.palette.blue.main,
                                        color: theme.palette.white,
                                    },
                                }}
                                onClick={() => {
                                    handleCancelButton({ payload: activeJob.services.filter(el => quickOptionsEnums['CANCELLABLE'].includes(el.service_status.toUpperCase())).map(el => ({ serviceID: el.id })) });
                                }}
                            > Cancel Job </Button>
                        )}
                    </Grid>
                    <Divider style={{ width: '95%', marginTop: 1, marginBottom: 1 }} />
                    <Typography mt={1} display={'flex'} sx={{ width: '100%', px: 2, fontSize: 20, fontWeight: 500 }}> { getYYMC() } </Typography>
                    <Grid item display={'flex'} sx={{ width: '100%', px: 2 }} justifyContent={'space-between'} my={1}>
                        <Grid item>
                            <Typography sx={{ fontSize: 12, fontWeight: 300 }} color={theme.palette.grey[500]}> { getStockRO() } </Typography>
                            <Typography sx={{ fontSize: 12, fontWeight: 300, textTransform: 'uppercase' }} color={theme.palette.grey[500]}> { getVIN() } </Typography>
                            <Typography sx={{ fontSize: 12, fontWeight: 300 }} color={theme.palette.grey[500]}> { getLocation() } </Typography>
                        </Grid>
                        <Typography sx={{ fontSize: 12, fontWeight: 300 }} color={theme.palette.grey[500]}> { getPO() } </Typography>
                        {/* <ChatButton> <ChatBubbleOutline /> </ChatButton> */}
                    </Grid>
                    <Divider style={{ width: '95%', marginTop: 1, marginBottom: 1 }} />
                    <Grid item display={'flex'} sx={{ width: '100%', px: 2 }} justifyContent={'space-between'} alignItems={'center'} my={1}>
                        <Grid item display={'flex'} alignItems={'center'}>
                            <ServiceIcon />
                            <Typography mx={1} sx={{ fontSize: 12, fontWeight: 500 }} color={theme.palette.grey[900]}> { activeJob.services.length } </Typography>
                            <RepairIcon />
                            <Typography mx={1} sx={{ fontSize: 12, fontWeight: 500 }} color={theme.palette.grey[900]}> { [...new Set(activeJob.services.map(el => el.repair_company.name))].length } </Typography>
                        </Grid>
                        <Typography sx={{ fontSize: 14, fontWeight: 500 }} color={theme.palette.grey[900]}> { new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(activeJob.services.reduce((sum, part) => sum += part.price, 0)) } </Typography>
                        {/* <ChatButton> <ChatBubbleOutline /> </ChatButton> */}
                    </Grid>
                    <Divider style={{ width: '95%', marginTop: 2 }} />
                    <ServiceBoard services={activeJob.services} />
                    <RepairerCards activeJob={activeJob} quickOptionsEnums={quickOptionsEnums} />
                </Grid>
            )}

        </Paper>
    );
};

JobDetails.propTypes = {
    activeJob: PropTypes.object,
};

export default JobDetails;
