import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from 'components/Header';
import { Alert, Grid, Snackbar, styled } from '@mui/material';
import MiniDrawer from 'components/Drawer';
import { useError } from 'contexts/ErrorContext';

const MainContainer = styled(Grid)(({ theme }) => ({
    paddingTop: theme.spacing(6),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginTop: theme.spacing(7), // Add margin to push content below AppBar
}));

const Layout = () => {
    const location = useLocation();
    const err = useError();
    const path = location.pathname;
    const [showDrawer, setSD] = useState(true);

    const resetError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        // @ts-ignore
        err.DISPATCH({ type: 'RESET_ERROR' });
    };

    useEffect(() => {
        if ( path === '/new_job' ) setSD(false); // Offset from Drawer on certain screens
        else setSD(true);
    }, [path, err]);

    const drawerElement = showDrawer ? (<MiniDrawer />) : (<Grid item xs={2} sm={2} md={2} lg={2} />);

    return (
        <>
            <Header />
            <MainContainer container justifyContent={'space-evenly'}>
                { err.state.showSnack && (
                    <Snackbar
                        open={err.state.showSnack}
                        autoHideDuration={6000}
                        onClose={resetError}
                    >
                        <Alert onClose={resetError} severity={err.state.severity} sx={{ width: '100%' }}>
                            {err.state.alert}
                        </Alert>
                    </Snackbar>
                )}
                { drawerElement }
                <Grid item xs={12} sm={9} md={10} lg={10}>
                    {/* https://reactrouter.com/en/main/components/outlet */}
                    {/* the other routes render inside of <Outlet /> */}
                    <Outlet />
                </Grid>
            </MainContainer>
        </>
    );
};

export default Layout;