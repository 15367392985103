import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import CompanyReducer, { INITIAL_STATE, INIT } from './reducer';

const CompanyContext = createContext({
    DISPATCH: null,
    state: {},
});

export const CompanyProvider = ({ children }) => {
    const [state, dispatch] = useReducer(CompanyReducer, { ...INITIAL_STATE }, INIT);

    const value = {
        state,
        // @ts-ignore
        DISPATCH: (data) => { dispatch(data); },
    };

    return (
        <CompanyContext.Provider value={value}> { children } </CompanyContext.Provider>
    );
};

CompanyProvider.propTypes = {
    children: PropTypes.node,
};

export const useCompany = () => useContext(CompanyContext);
