import React from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

export default function DrawerItem({
    route,
    title,
    onClick,
    selected,
    iconComponent,
}) {
    const theme = useTheme();
    const elemColor = selected ? theme.palette.blue[700] : theme.palette.grey[700];

    const IconComponent = iconComponent;
    return (
        <MenuItem key={route} sx={{ p: 1 }} onClick={() => onClick(route)}>
            <ListItemIcon>
                <IconComponent sx={{ color: elemColor }} fontSize='small' />
            </ListItemIcon>
            <ListItemText sx={{ color: elemColor }}>{title}</ListItemText>
        </MenuItem>
    );
}

DrawerItem.propTypes = {
    route: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    iconComponent: PropTypes.any,
};