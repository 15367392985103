// @ts-nocheck
// React
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// Local
// import { useApp } from 'contexts/AppContext';
// import LoginAPI from 'services/amt/api/login';
import { LoadingContext } from 'contexts/LoadingContext';
import { useAuth } from 'contexts/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { Formik } from 'formik';
import * as yup from 'yup';
// Styles
import { Box, TextField, Button, Grid, Typography } from '@mui/material';

console.log('process.env.REACT_APP_ENV', process.env.REACT_APP_ENV);
const ENV = process.env.REACT_APP_ENV;
console.log('ENV', ENV);

const LoginScreen = () => {
    const browserLocation = useLocation();
    const { toggleLoading } = useContext(LoadingContext);
    const navigate = useNavigate();
    const auth = useAuth();
    const notProd = ENV !== 'prod';
    const [newFBEmail, setNewFBE] = useState();
    const [newFBpw, setNewFBpw] = useState();

    // login using email/password on form submit
    const handleSubmit = ({ email, password }) => {
        toggleLoading();
        auth
            .login(email, password)
            .then((result) => {
                console.log('Loading... auth login result:', result);
                const searchParams = new URLSearchParams(browserLocation.search);
                const target = searchParams.get('target');
                const navigateUrl = target ? target : '/admin';
                navigate(navigateUrl);
            })
            .catch((e) => {
                console.error(e);
            })
            .finally(() => toggleLoading());
    };

    const newUserDev = () => {
        createUserWithEmailAndPassword(getAuth(), newFBEmail.value, newFBpw.value)
            .then((userRecord) => {
                // See the UserRecord reference doc for the contents of userRecord.
                console.log('Successfully created new user:', userRecord.uid);
            })
            .catch((error) => {
                console.log('Error creating new user:', error);
            });
    };

    return (
        <Grid container justifyContent={'space-between'}>
            <Grid item xs={3}></Grid>
            <Grid item xs={5} container alignItems='center' justifyContent='center'>
                <Grid item>
                    <img
                        width='256'
                        alt='logo'
                        style={{
                            margin: 64,
                        }}
                        src='https://uploads-ssl.webflow.com/62f66165d6557d71f09a2225/6324973c73b5d3ad722db2c7_Repair%20OnDemand%20Logo%20-%20Black-p-500.png'
                    />
                </Grid>
                <Grid item xs={10} sx={{
                    background: 'white',
                    padding: '64px 154px',
                    borderRadius: '16px',
                }}>
                    <Typography align='center' variant='h1' sx={{ marginBottom: '40px' }}>
                    Login
                    </Typography>
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={initialValues}
                        validationSchema={checkoutSchema}
                        validateOnChange={false}
                        validateOnBlur={false}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display='flex' alignItems={'center'} justifyContent={'center'} flexDirection={'column'} gap='44px'>
                                    <TextField
                                        fullWidth
                                        type='text'
                                        label='Email'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.email}
                                        name='email'
                                        error={!!touched.email && !!errors.email}
                                        helperText={errors.email}
                                    />
                                    <TextField
                                        fullWidth
                                        type='password'
                                        label='Password'
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.password}
                                        name='password'
                                        error={!!touched.password && !!errors.password}
                                        helperText={touched.password && errors.password}
                                    />
                                    <Box>
                                        <Button
                                            type='submit'
                                            color='secondary'
                                            variant='contained'
                                            sx={{
                                                borderRadius: '64px',
                                                padding: '16px 44px',
                                                fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                                                fontWeight: '600',
                                                fontSize: '16px',
                                            }}
                                        >
                                    login
                                        </Button>
                                    </Box>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
            <Grid item container direction='column' xs={3} display={'flex'} justifyContent={'end'}>
                {notProd && (
                    <>
                        <Grid item container direction='column' mr={2} my={6} p={2} pb={4} border={'2px solid black'}>
                            <Typography align='center' variant='h1' mt={2} mb={2}> New Dev Login </Typography>
                            <TextField inputRef={setNewFBE} name='newEmail' variant='standard' label='New User Email' />
                            <TextField type='password' inputRef={setNewFBpw} name='newPW' variant='standard' label='New User Password' />
                        </Grid>
                        <Grid item ml={20}>
                            <Button
                                onClick={newUserDev}
                                color='secondary'
                                variant='contained'
                                sx={{
                                    borderRadius: '64px',
                                    fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
                                    fontWeight: '600',
                                    fontSize: '16px',
                                }}
                            >
                                        New Login
                            </Button>
                        </Grid>
                    </>
                )}
            </Grid>
        </Grid>
    );
};

const initialValues = {
    email: '',
    password: '',
};

const checkoutSchema = yup.object().shape({
    email: yup.string().email('invalid email').required('required'),
    password: yup.string().required('required'),
});

export default LoginScreen;
