import React from 'react';
import PropTypes from 'prop-types';

import { useTheme, Grid, Button } from '@mui/material';

export default function QuickFiltersPanel({ filters = [], selectedFilter, onFilterSelected }) {
    const theme = useTheme();

    return !filters?.length ? null : (
        filters.map((filter, i) => (
            <Grid item key={i}>
                <Button
                    onClick={() => onFilterSelected(filter)}
                    sx={{
                        p: 0.5,
                        m: 0.5,
                        ml: i === 0 ? 0 : 0.5,
                        pl: i === 0 ? 0 : 0.5,
                        fontSize: '16px',
                        textTransform: 'none',
                        color: (filter === selectedFilter) ? theme.palette.blue[500] : theme.palette.grey[500],
                    }}
                    className={(filter === selectedFilter) ? 'active': ''}
                >
                    { filter }
                </Button>
            </Grid>
        ))
    );
}

QuickFiltersPanel.propTypes = {
    filters: PropTypes.array,
    selectedFilter: PropTypes.string,
    onFilterSelected: PropTypes.func,
};
