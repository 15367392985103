
import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography } from '@mui/material';

import TitlePanelActionButton from './TitlePanelActionButton';

export default function TitlePanel({
    title,
    actions,
}) {
    const actionElems = actions.map(({ title, onClick, iconComponent }) => (
        <TitlePanelActionButton
            key={title}
            title={title}
            onClick={onClick}
            iconComponent={iconComponent}
        />
    ));

    return (
        <Grid item display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant={'h1'} m={0} mb={1} p={0} pb={1}>{title}</Typography>
            {actionElems}
        </Grid>
    );
}

TitlePanel.propTypes = {
    title: PropTypes.string,
    actions: PropTypes.array,
};
