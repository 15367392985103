
// @ts-nocheck
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Grid, Box, useTheme } from '@mui/material';

import MultiAccordionContainer from 'components/Containers/Accordion/MultiAccordionContainer';
import IconContainer from 'components/Icons/IconContainer';
import { ReactComponent as CarRepairIcon } from 'assets/images/car_repair.svg';
import { ReactComponent as AutoRepairIcon } from 'assets/images/auto_fix_high.svg';
import Form from 'components/Form';

const RepairerSavedRatesForm = ({ rates, handleRateChange }) => {
    const theme = useTheme();
    // MTODO: Remove and use repairer context
    const [ratesState, setRates] = useState({});

    const onRateChange = (rateName) => (event) => {
        setRates((oldRates) => ({ ...oldRates, [rateName]: event.target.value }));
    };

    const accordions = [
        {
            summary: 'Repair Type',
            content: (
                <Grid container sx={{ width: '100%' }}>
                    <Grid item sx={{ width: '100%', my: 0 }}>
                        <Form.TitledInput id='Cigarette-Burn-Title' label='Cigarette Burn Cigarette Burn Cigarette Burn' showDeleteButton>
                            <Form.TextInput
                                id='price'
                                label='price'
                                onChange={onRateChange('CigaretteBurn')}
                                value={ratesState.CigaretteBurn}
                                maskType={Form.TYPES.TEXT_MASK.CURRENCY}
                                noBackground
                            />
                        </Form.TitledInput>
                    </Grid>
                    <Grid item sx={{ width: '100%', my: 0 }}>
                        <Form.TitledInput id='Odor-Removal-Title' label='Odor Removal' showDeleteButton>
                            <Form.TextInput
                                id='price'
                                label='price'
                                onChange={onRateChange('OdorRemoval')}
                                value={ratesState.OdorRemoval}
                                maskType={Form.TYPES.TEXT_MASK.CURRENCY}
                                noBackground
                            />
                        </Form.TitledInput>
                    </Grid>
                    <Grid item sx={{ width: '100%', my: 0 }}>
                        <Form.TitledInput id='Scratch-Repair-Title' label='Scratch Repair' showDeleteButton>
                            <Form.TextInput
                                id='price'
                                label='price'
                                onChange={onRateChange('ScratchRepair')}
                                value={ratesState.ScratchRepair}
                                maskType={Form.TYPES.TEXT_MASK.CURRENCY}
                                noBackground
                            />
                        </Form.TitledInput>
                    </Grid>
                </Grid>
            ),
            props: {
                iconElem: (
                    <IconContainer icon={CarRepairIcon} backgroundCircle padding='0px' />
                ),
            },
        },
        {
            summary: 'Interior Repair',
            content: (
                <Grid container sx={{ width: '100%' }}>
                    <Grid item sx={{ width: '100%', my: 0 }}>
                        <Form.TitledInput id='service-Title-2' label='Service' showDeleteButton>
                            <Form.TextInput
                                id='price2'
                                label='price'
                                onChange={onRateChange('service2')}
                                value={ratesState.service2}
                                maskType={Form.TYPES.TEXT_MASK.CURRENCY}
                                noBackground
                            />
                        </Form.TitledInput>
                    </Grid>
                </Grid>
            ),
            props: {
                iconElem: <IconContainer icon={AutoRepairIcon} backgroundCircle padding='0px' />,
            },
        },
        {
            summary: 'Repair Type 3',
            content: (
                <Grid container sx={{ width: '100%' }}>
                    <Grid item sx={{ width: '100%', my: 0 }}>
                        <Form.TitledInput id='Other-Service-Title-3' label='Other Service' showDeleteButton>
                            <Form.TextInput
                                id='price3'
                                label='price'
                                onChange={onRateChange('OtherService3')}
                                value={ratesState.OtherService3}
                                maskType={Form.TYPES.TEXT_MASK.CURRENCY}
                                noBackground
                            />
                        </Form.TitledInput>
                    </Grid>
                    <Grid item sx={{ width: '100%', my: 0 }}>
                        <Form.TitledInput id='Last-Service-Title-3' label='Last Service' showDeleteButton>
                            <Form.TextInput
                                id='price3'
                                label='price'
                                onChange={onRateChange('LastService3')}
                                value={ratesState.LastService3}
                                maskType={Form.TYPES.TEXT_MASK.CURRENCY}
                                noBackground
                            />
                        </Form.TitledInput>
                    </Grid>
                </Grid>
            ),
            props: {
                iconElem: (
                    <IconContainer icon={CarRepairIcon} backgroundCircle padding='0px' />
                ),
            },
        },
    ];

    return (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <MultiAccordionContainer
                accordions={accordions}
                accordionProps={{ style: 'Secondary', titleAlign: 'left', detailsProps: { sx: { pt: 0 } } }}
                sx={{ width: '90%' }}
            />
        </Box>
    );
};

RepairerSavedRatesForm.propTypes = {
    rates: PropTypes.arrayOf(PropTypes.object).isRequired,
    validations: PropTypes.object,
    handleRateChange: PropTypes.func.isRequired,
};

export default RepairerSavedRatesForm;
