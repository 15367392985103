import axios from 'axios';
import { getMessaging, onMessage, getToken } from 'firebase/messaging';

import { getCompany } from 'services/company/api';

import * as IndexedDbManager from './indexedDB';

console.log('process.env.REACT_APP_PORTAL_API_HOST', process.env.REACT_APP_PORTAL_API_HOST);
const PORTAL_API_HOST = process.env.REACT_APP_PORTAL_API_HOST || 'https://ubnlpz1mp8.execute-api.us-east-2.amazonaws.com';
console.log('PORTAL_API_HOST', PORTAL_API_HOST);

console.log('process.env.REACT_APP_VAPID_KEY', process.env.REACT_APP_VAPID_KEY);
const SERVER_VAPID_KEY = process.env.REACT_APP_VAPID_KEY;
console.log('SERVER_VAPID_KEY', SERVER_VAPID_KEY);

const PERMISSION_RESULTS = {
    GRANTED: 'granted',
    DENIED: 'denied',
};

const notificationCallbacks = [];

const registerSubscription = async (token) => {
    try {
        const companyRes = await getCompany();

        if (companyRes?.status == 200) {
            const company = companyRes.data.payload;
            const topic = company.id.toLowerCase(); // FCM topics must be lowercase

            console.log('Registering subscription for device:', token, 'company topic:', topic);

            const pushRegistrationRes = await axios.post(`${PORTAL_API_HOST}/push/subscribe`, { token, topic });

            if (pushRegistrationRes?.status !== 200 && pushRegistrationRes?.status !== 201) {
                console.log('Error while registering subscription:', pushRegistrationRes);
                throw new Error(`Error while registering subscription. ${pushRegistrationRes.data.msg || ''}`);
            }

            console.log('Subscription registered successfully:', pushRegistrationRes);

            const registrationID = pushRegistrationRes.data.id;

            // save token and registration ID in local storage
            localStorage.setItem('pushToken', token);
            localStorage.setItem('pushRegistrationID', registrationID);

            // save company id to indexedDB so it is accessible from the service worker
            IndexedDbManager.save('company', company);
        }
        else {
            throw new Error('Error while retrieving company data:', companyRes);
        }
    } catch (err) {
        console.error('Error while registering subscription:', err);
    }
};

export const saveNotification = async (pushObject, companyId) => {
    try {
        let compId = companyId;

        if (!compId) {
            const companyRes = await getCompany();

            if (companyRes?.status == 200) {
                compId = companyRes.data.payload.id;
            }
            else {
                throw new Error('Error while retrieving company data:', companyRes);
            }
        }

        if (compId) {
            const notification = {
                title: pushObject.notification.title,
                body: pushObject.notification.body,
                'job_id': pushObject.data?.['job_id'] || null,
                'service_id': pushObject.data?.['service_id'] || null,
                'company_id': compId,
                data: pushObject.data,
                read: false,
                timestamp: Date.now(),
            };

            console.log('Saving notification for company:', compId, 'notification:', notification);

            await fetch(
                `${PORTAL_API_HOST}/notification/create`,
                {
                    method: 'POST',
                    mode: 'cors',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(notification),
                },
            );
        }
    } catch (err) {
        console.error('Error while registering subscription:', err);
    }
};

class PushManager {
    initialize(serviceWorkerRegistration, app, notificationCallback) {
        this.app = app;
        this.serviceWorkerRegistration = serviceWorkerRegistration;
        this.addNotificationCallback(notificationCallback);

        this.broadcastChannel = new BroadcastChannel('sw-messages');
        this.broadcastChannel.addEventListener('message', event => {
            console.log('Received broadcast message:', event);

            if (event.data?.message === 'notification') {
                notificationCallbacks.forEach((callback) => {
                    if (typeof callback !== 'function') return;
                    callback({ ...event.data.payload, timestamp: Date.now() });
                });
            }
        });

        if (!this.initialized) {
            if ('serviceWorker' in navigator && 'Notification' in window && 'PushManager' in window) {
                // MTODO: If the user does not grant permission we still want to receive notifications from within the app, without push
                // Only enable FCM notifications if the user has granted permission, otherwise Firebase will throw an error:
                // An error occurred while retrieving token. FirebaseError: Messaging: The notification permission was not granted
                // and blocked instead. (messaging/permission-blocked).
                if (Notification.permission === PERMISSION_RESULTS.DENIED) {
                    console.warn('Notification permission denied.');
                    this.disable();
                    return;
                }

                if (Notification.permission === PERMISSION_RESULTS.GRANTED) {
                    console.log('Notification permission granted.');
                    this.enable();
                    return;
                }

                console.log('Requesting Notification permission...');
                Notification.requestPermission().then((permission) => {
                    console.log('Notification permission:', permission);
                    if (permission === PERMISSION_RESULTS.GRANTED) {
                        this.enable();
                    } else {
                        this.disable();
                    }
                });
            }

            this.initialized = true;
        }
    }

    async enable() {
        console.log('Enabling push notifications...');

        await navigator.serviceWorker.ready;

        if (this.app) {
            const messaging = getMessaging(this.app);
            if (messaging) {
                getToken(
                    messaging,
                    {
                        vapidKey: SERVER_VAPID_KEY,
                        serviceWorkerRegistration: this.serviceWorkerRegistration,
                    },
                ).then((token) => {
                    if (token) {
                        // Send the subscription token to the server to use it to subscribe to FCM topics
                        registerSubscription(token);

                        // Register a callback to handle messages received while the app is in the foreground
                        onMessage(messaging, async (pushObject) => {
                            console.log('FCM Notification received:', pushObject);

                            // TOREMOVE: Send notification to portal api to be saved (temp solution until RoDx persist notifications)
                            await saveNotification(pushObject);

                            if(pushObject?.notification?.title) {
                                notificationCallbacks.forEach((callback) => {
                                    if (typeof callback !== 'function') return;
                                    callback({ ...pushObject, timestamp: Date.now() });
                                });
                            }
                        });
                    } else {
                        console.log('No registration token available.');
                    }
                }).catch((err) => {
                    console.log('An error occurred while retrieving token. ', err);
                });
            }
        }
    }

    async disable() {
        // MTODO: We should probably cleanup server registrations when the notification permission is denied
    }

    addNotificationCallback(callback) {
        if (typeof callback !== 'function') return;
        notificationCallbacks.push(callback);
    }
}

export default new PushManager();
