
import React from 'react';
import PropTypes from 'prop-types';

import { Card as MuiCard, Grid, Typography, useTheme } from '@mui/material';

import { ReactComponent as LocationIcon } from 'assets/images/location_symbol.svg';
import IconContainer from 'components/Icons/IconContainer';
import StatusIndicator from 'components/Icons/StatusIndicator';

const getStyle = (theme) => ({
    selectedCard: {
        cursor: 'pointer',
        backgroundColor: theme.palette.blue[30],
        border: '1px solid',
        borderColor: theme.palette.blue[200],
        m: 1,
        p: 2,
        borderRadius: '8px',
    },
    defaultCard: {
        cursor: 'pointer',
        backgroundColor: theme.palette.white,
        border: '1px solid',
        borderColor: theme.palette.grey[50],
        m: 1,
        p: 2,
        borderRadius: '8px',
    },
    disabledCard: {
        cursor: 'pointer',
        backgroundColor: theme.palette.grey[30],
        border: '1px solid',
        borderColor: theme.palette.grey[50],
        m: 1,
        p: 2,
        borderRadius: '8px',
        color: theme.palette.grey[200],
    },
});

const Card = ({ content, onClick, selected = false, active = true }) => {
    const theme = useTheme();
    const { id, title, subTitle, text, statusActive, statusText } = content;
    const styles = getStyle(theme);
    const IconBG = selected ? theme.palette.white : (active ? theme.palette.grey[30] : theme.palette.grey[40]);

    return (
        <MuiCard
            raised={true}
            onClick={() => { onClick(id); }}
            sx={ selected ? styles.selectedCard : (active ? styles.defaultCard : styles.disabledCard) }
        >
            <Grid display={'flex'} alignItems={'center'}>
                <IconContainer
                    icon={LocationIcon}
                    width='50px'
                    height='50px'
                    iconWidth='50px'
                    iconHeight='50px'
                    padding='0px'
                    backgroundCircle
                    sx={{ background: IconBG }}
                />
                <Grid item mx={1}>
                    <Typography
                        variant={'h5'}
                        my={.5}
                        sx={{
                            color: selected || active ? theme.palette.grey[900] : theme.palette.grey[200]
                        }}
                    >
                        {title}
                    </Typography>
                    {
                        !subTitle ? null : (
                            <Typography
                                my={.5}
                                sx={{
                                    fontSize: 12,
                                    color: selected ||active ? theme.palette.grey[500] : theme.palette.grey[200],
                                }}
                            >
                                {subTitle}
                            </Typography>
                        )
                    }
                    {
                        !text ? null : (
                            <Typography
                                my={.5}
                                sx={{
                                    fontSize: 12,
                                    color: selected ||active ? theme.palette.grey[500] : theme.palette.grey[200],
                                }}
                            >
                                {text}
                            </Typography>
                        )
                    }
                    {
                        !statusText && statusActive == null ? null : (
                            <StatusIndicator active={statusActive} status={statusText} />
                        )
                    }
                </Grid>
            </Grid>
        </MuiCard>
    );
};

Card.propTypes = {
    content: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        subTitle: PropTypes.string,
        text: PropTypes.string,
        statusActive: PropTypes.bool,
        statusText: PropTypes.string,
    }).isRequired,
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.bool,
    active: PropTypes.bool,
};

export default Card;
