import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography, Switch as MuiSwitch, styled } from '@mui/material';

import Styles from './styles';

const PrimarySwitch = styled(MuiSwitch)(Styles.PrimarySwitchStyle);

const Switch = ({ value, onChange, style = 'primary', activeText = 'Active', inactiveText = 'Inactive', disabled = false }) => {
    const SwitchComponent = style === 'primary' ? PrimarySwitch : Switch;
    return (
        <Box display={'flex'} alignItems={'center'}>
            <Typography sx={{ mx: 1 }}>{ value === true ? activeText : inactiveText }</Typography>
            <SwitchComponent
                disabled={disabled}
                checked={value}
                onChange={onChange}
                inputProps={{ 'aria-label': 'controlled' }}
            />
        </Box>
    );
};

Switch.propTypes = {
    value: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    style: PropTypes.oneOf(['primary']),
    activeText: PropTypes.string,
    inactiveText: PropTypes.string,
    disabled: PropTypes.bool,
};

export default Switch;