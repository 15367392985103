
import React from 'react';
import PropTypes from 'prop-types';

import { Card, Box, ButtonBase, Grid, Typography, useTheme } from '@mui/material';

import { ReactComponent as RightArrowIcon } from 'assets/images/chevron_right_icon.svg';
import { ReactComponent as ToolsIcon } from 'assets/images/tools_icon.svg';

import IconContainer from 'components/Icons/IconContainer';
import StatusIndicator from 'components/Icons/StatusIndicator';

const getStyle = (theme, showBorder, compact) => ({
    activeCard: {
        width: '100%',
        mx: 0,
        p: compact ? 0.5 : 2,
        px: compact ? 1 : 2,
        background: theme.palette.white,
        borderRadius: '8px',
        border: showBorder ? `1px solid ${theme.palette.grey[50]}` : 'none',
        ':hover': {
            cursor: 'pointer',
            background: theme.palette.grey[50],
        },
    },
    inactiveCard: {
        width: '100%',
        mx: 0,
        p: compact ? 0.5 : 2,
        px: compact ? 1 : 2,
        color: theme.palette.grey[200],
        background: theme.palette.grey[30],
        borderRadius: '8px',
        border: showBorder ? `1px solid ${theme.palette.grey[50]}`: 'none',
        ':hover': {
            cursor: 'pointer',
            background: theme.palette.grey[50],
        },
    },
});

export default function ButtonCard({
    content,
    onClick,
    iconElement,
    active = true,
    showStatus = true,
    showBorder = true,
    compact = false,
    iconVerticalAlign = 'center',
}) {
    const theme = useTheme();
    const styles = getStyle(theme, showBorder, compact);

    return (
        <ButtonBase
            onClick={() => { onClick(content.id); }}
            sx={{ width: '100%', minWidth: '100%', borderRadius: '8px' }}
        >
            <Card
                raised={true}
                sx={ active ? styles.activeCard : styles.inactiveCard }
            >
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: iconVerticalAlign }}>
                        { iconElement ?? null }
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                        <Grid item mx={1} sx={{ textAlign: 'left' }}>
                            <Typography variant={'h5'} my={.5} sx={{ marginTop: 0 }}> {content.name} </Typography>
                            <Typography my={.5} sx={{ fontSize: 12, color: theme.palette.grey[500] }}>{content.description}</Typography>
                            <Typography my={.5} sx={{ fontSize: 12, color: theme.palette.grey[500] }}>{content.text}</Typography>
                            { showStatus ? <StatusIndicator active={active} status={content.status} /> : null }
                        </Grid>
                    </Box>
                    <IconContainer icon={RightArrowIcon} style={{ alignSelf: 'center' }} />
                </Box>
            </Card>
        </ButtonBase>
    );
}

ButtonCard.propTypes = {
    content: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    iconElement: PropTypes.element,
    active: PropTypes.bool,
    showStatus: PropTypes.bool,
    showBorder: PropTypes.bool,
    compact: PropTypes.bool,
    iconVerticalAlign: PropTypes.oneOf(['top', 'center', 'bottom']),
};
