import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import RepairerReducer, { INITIAL_STATE, INIT } from './reducer';

export const RepairerContext = createContext({
    DISPATCH: null,
    state: {},
});

export const RepairerProvider = ({ children }) => {
    const [state, dispatch] = useReducer(RepairerReducer, { ...INITIAL_STATE }, INIT);

    const value = {
        state,
        // @ts-ignore
        DISPATCH: (data) => { dispatch(data); },
    };

    return (
        <RepairerContext.Provider value={value}> { children } </RepairerContext.Provider>
    );
};

RepairerProvider.propTypes = {
    children: PropTypes.node,
};

export const useRepairers = () => useContext(RepairerContext);
