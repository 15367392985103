
import * as React from 'react';
import PropTypes from 'prop-types';

import { Autocomplete, TextField, useTheme } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';

export default function SearchButton({ value, onChange, options = [], allowFreeText = true }) {
    const theme = useTheme();
    const [searchTerm, setSearchTerm] = React.useState(null);

    return (
        <Autocomplete
            id='quick-find'
            freeSolo={allowFreeText}
            options={options}
            value={searchTerm || value}
            sx={{
                p: 0,
                border: 'none',
            }}
            onChange={(event, newVal) => setSearchTerm(newVal)}
            onBlur={() => {
                onChange(searchTerm);
                setSearchTerm(null);
            }}
            onInputChange={(event, newValue) => allowFreeText ? setSearchTerm(newValue) : ({})}
            ListboxProps={{ sx: { fontSize: 14, padding: 1 } }}
            renderInput={(params) => {
                return (<TextField
                    {...params}
                    InputLabelProps={{
                        ...params.InputLabelProps,
                        style: {
                            height: '32px',
                            alignItems: 'center',
                        },
                    }}
                    InputProps={{
                        ...params.InputProps,
                        style: {
                            width: '200px',
                            height: '32px',
                            padding: '10px',
                            alignContent: 'center',
                            borderRadius: '8px',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: 'normal',
                            color: theme.palette.grey[800],
                        },
                        startAdornment: (<SearchIcon style={{ color: theme.palette.blue.main }} />),
                        endAdornment: null,
                    }}
                    variant='outlined'
                    placeholder='Quick find'
                />);
            }}
        />
    );
}

SearchButton.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.string),
    allowFreeText: PropTypes.bool,
};
