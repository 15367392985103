import React, { useEffect, useState, useCallback } from 'react';

import debounce from 'lodash.debounce';

import { Divider, Box, useTheme } from '@mui/material';

import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CarRepairOutlinedIcon from '@mui/icons-material/CarRepairOutlined';

import { useError } from 'contexts/ErrorContext';
import { useRepairers, actions } from 'services/repairer';

import SearchAndFilterPanel from 'components/Panels/SearchAndFilterPanel';
import ListAndDetailsPanel from 'components/Panels/ListAndDetailsPanel';

import RepairerList from './Content/RepairerList';
import RepairerDetails from './Content/RepairerDetails';

const filtersDefinitions = {
    status: {
        title:'Status',
        options: [{ id: 'active', name: 'Active', selected: false }, { id: 'inactive', name: 'InActive', selected: false }],
        IconComponent: BuildCircleOutlinedIcon,
    },
    repairers: {
        title:'Locations',
        options: [{ id: 'loc1', name: 'Loc 1', selected: false }, { id: 'loc2', name: 'Loc 2', selected: false }],
        IconComponent: LocationOnIcon,
    },
    availability: {
        title: 'Availability',
        options: [{ id: 'av1', name: 'Av 1', selected: false }, { id: 'av2', name: 'Av 2', selected: false }],
        IconComponent: AccessTimeIcon,
    },
    invoice: {
        title: 'Invoice Type',
        options: [ { id:'invoice1', name: 'Invoice Type 1', selected: false }, { id:'invoice2', name: 'Invoice Type 2', selected: false }],
        IconComponent: InfoOutlinedIcon,
    },
    services: {
        title:'Services',
        options: [{ id: 'serv1', name: 'Service 1', selected: false }, { id: 'serv2', name: 'Service 2', selected: false }],
        IconComponent: CarRepairOutlinedIcon,
    },
};

const Repairers = () => {
    const theme = useTheme();
    const repairersContext = useRepairers();
    const errorContext = useError();

    const [repairersLoaded, setRepairersLoaded] = useState(false);

    const onRepairersLoaded = () => {
        setRepairersLoaded(true);
    };

    const reloadRepairers = (repContext, errContext, onRepairersLoaded) => {
        if (!repairersLoaded) {
            actions.refreshRepairers(
                { filter: repContext.state.filters, textQuery: repContext.state.searchTerm },
                repContext,
                errContext,
                onRepairersLoaded,
            );
        }
    };

    const debouncedReloadRepairers = useCallback(debounce(reloadRepairers, 500), []);

    useEffect(
        () => debouncedReloadRepairers(repairersContext, errorContext, onRepairersLoaded),
        [repairersLoaded],
    );

    const [filters, setFilters] = useState(filtersDefinitions);

    return (
        <Box>
            <SearchAndFilterPanel
                filters={filters}
                onFilterChange={(id, selected) => {
                    // @ts-ignore
                    setFilters((oldFilters) => {
                        const newFilters = { ...Object.entries(oldFilters).reduce((acc, [k, v]) => ({ ...acc, [k] : { ...v } }), {}) };

                        newFilters[id].options = oldFilters[id].options
                            .map((op) => ({ ...op, selected: selected.filter((o) => o.id === op.id).length > 0 }));

                        return newFilters;
                    });
                }}
                searchTerm={''}
                onSearchTermChange={() => {}}
                searchOptions={['Auto Repair of Austin', 'Texas Dent Works']}
            />
            <Divider sx={{ color: theme.palette.grey[50], width: '100%' }} />
            <ListAndDetailsPanel
                list={repairersContext?.state?.repairers || []}
                singularTitle='Repairer'
                pluralTitle='Repairers'
                listElement={RepairerList}
                detailElement={RepairerDetails}
                loaded={repairersLoaded}
                listWidth={3.5}
            />
        </Box>
    );
};

export default Repairers;
