import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const UnauthenticatedRoute = ({ user }) => {
    // After login in, redirect to the target page if it exists
    const browserLocation = useLocation();

    if (user) {
        const searchParams = new URLSearchParams(browserLocation.search);
        const target = searchParams.get('target');
        const navigateUrl = target ? target : '/admin';
        return (<Navigate to={navigateUrl} />);
    }

    return (<Outlet />);
};

UnauthenticatedRoute.propTypes = {
    user: PropTypes.object,
};

export default UnauthenticatedRoute;
