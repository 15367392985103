import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Stack, Typography, Paper, Box, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Loading from 'components/Loading';

export default function ListAndDetailsPanel({
    list,
    singularTitle,
    pluralTitle,
    listElement,
    detailElement,
    loaded = false,
    listWidth = 3,
    showCount = true,
}) {
    const theme = useTheme();
    const ListElem = listElement;
    const DetailElem = detailElement;

    const loadingElem = <Loading loading fullScreen={false} width={150} height={150} />;

    const countElem = !showCount ? null : (
        <Box sx={{ height: '50px', width: '100%' }} alignItems={'center'} display={'flex'}>
            {
                !loaded ? null :
                    (
                        <Typography p={1} m={1} sx={{ fontWeight:500 }}>
                            {`${list.length} ${list.length === 1 ? singularTitle : (pluralTitle || `${singularTitle}s`)}`}
                        </Typography>
                    )
            }
        </Box>
    );

    return (
        <Grid display={'flex'} sx={{ p: 0, mx: 0 }} minHeight={'800px'} maxHeight={'800px'}>
            <Grid
                item
                container
                direction='column'
                xs={listWidth}
                display={'flex'}
                sx={{ borderRight: '1px solid', borderColor: theme.palette.grey[50], pl: 0 }}
                minHeight={'800px'}
                maxHeight={'800px'}
            >
                <Stack spacing={0} sx={{ maxWidth: '100%' }}>
                    { countElem }
                    <Divider sx={{ width: '100%', mb: 0 }} />
                    <Paper
                        className={'list-and-details-list-paper'}
                        elevation={0}
                        sx={{
                            width: '100%',
                            height: '748px',
                            maxHeight: '100%',
                            overflow: 'clip',
                        }}
                    >
                        { (loaded && list.length > 0) ? (<ListElem />) : loadingElem }
                    </Paper>
                </Stack>
            </Grid>
            <Grid item xs={12 - listWidth} display={'flex'} justifyContent={'space-between'} overflow={'auto'}>
                {
                    loaded ? (<DetailElem />) : null
                }
            </Grid>
        </Grid>
    );
}

ListAndDetailsPanel.propTypes = {
    list: PropTypes.array,
    singularTitle: PropTypes.string,
    pluralTitle: PropTypes.string,
    listElement: PropTypes.elementType,
    detailElement: PropTypes.elementType,
    loaded: PropTypes.bool,
    listWidth: PropTypes.number,
    showCount: PropTypes.bool,
};
