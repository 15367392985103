const PrimarySwitchStyle = ({ theme }) => ({
    padding: 6,
    '& .MuiSwitch-track': {
        borderRadius: 50,
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 20,
        height: 20,
        margin: 0,
    },
    '& .MuiSwitch-switchBase': {
        color: theme.palette.white,
    },
    '& .MuiSwitch-switchBase + .MuiSwitch-track': {
        backgroundColor: theme.palette.red.main,
        opacity: 1,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: theme.palette.white,
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: theme.palette.blue.main,
        opacity: 1,
    },
});

export default {
    PrimarySwitchStyle,
};
