import { parseAddress, formatAddress } from 'services/utils/addressParser';

import TYPES from './types';

const EMPTY_ADDRESS = {
    'street_number': '',
    'street_name': '',
    city: '',
    state: '',
    country: '',
    zip: '',
};

export const INITIAL_STATE = {
    company: {},
    validations: {},
};

export const INIT = function () {
    return { ...INITIAL_STATE };
};

const CompanyReducer = function (state, action) {
    switch (action.type) {
        case TYPES.INIT: {
            return { ...INITIAL_STATE };
        }
        case TYPES.SET_COMPANY: {
            return { ...state, company: formatCompanyIntoState(action.payload) };
        }
        case TYPES.UPDATE_COMPANY: {
            action.payload.edited = false;

            return { ...state, company: formatCompanyIntoState(action.payload) };
        }
        case TYPES.EDIT_COMPANY_PROP: {
            const { propName, propValue } = action.payload;

            const newCompany = state.company ? { ...state.company } : { };

            newCompany[propName] = propValue;
            newCompany.edited = true;

            return { ...state, company: newCompany };
        }
        case TYPES.EDIT_SUB_PROP: {
            const { mainProp, propName, propValue } = action.payload;

            const newCompany = state.company ? { ...state.company } : { [mainProp]: {} };

            newCompany[mainProp] = newCompany[mainProp] ? { ...newCompany[mainProp] } : {};

            newCompany[mainProp][propName] = propValue;
            newCompany.edited = true;

            return { ...state, company: newCompany };
        }
        case TYPES.EDIT_ADDRESS: {
            // type: mailing_address or billing_address
            const { type, place } = action.payload;

            const newAddress = { ...(state?.company?.[type] ?? {}), ...(place ? parseAddress(place) : EMPTY_ADDRESS) };

            const newCompany = { ...state.company, [type]: { ...newAddress } };
            newCompany.edited = true;

            return { ...state, company: newCompany };
        }
        case TYPES.CHANGE_VALIDATION: {
            return { ...state, validations: action.payload };
        }
        default: {
            return { ...state };
        }
    }
};

const formatCompanyIntoState = (location) => {
    const formattedCompany = { ...location };
    if (formattedCompany.mailing_address && !formattedCompany.mailing_address.formatted_address) {
        formattedCompany.mailing_address['formatted_address'] = formatAddress(formattedCompany.mailing_address);
    }
    if (formattedCompany.billing_address && !formattedCompany.billing_address.formatted_address) {
        formattedCompany.billing_address['formatted_address'] = formatAddress(formattedCompany.billing_address);
    }
    return formattedCompany;
};

export default CompanyReducer;
