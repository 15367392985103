import React from 'react';
import PropTypes from 'prop-types';

import { Box, Grid, Paper, Typography, useTheme, Divider, Card} from '@mui/material';
import { STATUS_BAR_CLASS } from 'services/utils/enums';

const getBoardColumns = (theme) => [
    { status: ['OPEN'],
        display: 'Open',
        bgColor: theme.palette.blue[50],
        altColor: theme.palette.blue[600],
    },
    { status: ['PENDING', 'PENDING APPROVAL'],
        display: 'Pending',
        bgColor: theme.palette.yellow[40],
        altColor: theme.palette.yellow[700],
    },
    { status: ['ACCEPTED'],
        display: 'Accepted',
        bgColor: theme.palette.green[30],
        altColor: theme.palette.green[600],
    },
    { status: ['IN PROGRESS'],
        display: 'In Progress',
        bgColor: theme.palette.blue[50],
        altColor: theme.palette.blue[600],
    },
    { status: ['COMPLETED', 'INVOICED', 'CLOSED'],
        display: 'Complete',
        bgColor: theme.palette.green[40],
        altColor: theme.palette.green[600],
    },
    { status: ['DECLINED'],
        display: 'Declined',
        bgColor: theme.palette.red[30],
        altColor: theme.palette.red[600],
    },
    { status: ['CANCELLED'],
        display: 'Cancelled',
        bgColor: theme.palette.grey[100],
        altColor: theme.palette.black,
    },
];

const ServiceBoard = ({ services }) => {
    const theme = useTheme();

    let data = [];

    let j = 1;

    for (let column of getBoardColumns(theme)) {
        const filteredList = services.filter(el => column.status.includes(el.service_status.toUpperCase()));
        if (filteredList.length) {
            const repList = Array.from(new Set(filteredList.map(el => el.repair_company.name))).sort();

            let details = [];

            repList.forEach((repairer, i) => {
                // All services with this Status for this Repairer
                const repairerServices = filteredList.filter(el => el.repair_company.name === repairer);

                const servSet = Array.from(new Set(filteredList.map(el => el.service_details.service_type))).sort();

                const serviceDetails = servSet.map((sev, k) => {
                    const sev_ = repairerServices.filter(el => el.service_details.service_type === sev).map(el => {
                        const pos = el.service_details.position && el.service_details.position != 'N/A' ? el.service_details.position : '';
                        const part = el.service_details.part && el.service_details.part != 'N/A' ? `${el.service_details.part}` : '';

                        return `${pos} ${part}`;
                    });

                    return (
                        <Grid key={k} display={'flex'} alignItems={'center'}>
                            <Typography sx={{ fontSize: 12, fontWeight: 500 }}> { sev } </Typography>
                            <Typography sx={{ fontSize: 12, fontWeight: 300, ml: 1 }}>
                              &nbsp;{ `${(sev_ && sev_.length && sev_[0] != '') ? `${sev_.join(', ')}` : ''}` }
                            </Typography>
                        </Grid>
                    );
                });

                const supplementFlag = Array.from(new Set([...servSet.reduce((total, sev) => {
                    total.push(...repairerServices.filter(el => el.service_details.service_type === sev).map(el => {
                        if (el.supplement && el.supplement.status) {
                            return el.supplement.status;
                        }
                    }));
                    return total;
                }, [])]))
                    .filter(el => el)
                    .map((el, k) => <Card key={k} variant={STATUS_BAR_CLASS[el]}> { `Supplement ${el}` } </Card>);

                const reworkFlag = Array.from(new Set(
                    [...servSet.reduce((total, sev) => {
                        total.push(...repairerServices.filter(el => el.service_details.service_type === sev).map(el => {
                            if (el.rework) {
                                return 'Rework';
                            }
                        }));
                        return total;
                    }, [])]))
                    .filter(el => el)
                    .map((el, k) => (
                        // @ts-ignore
                        <Card key={k} variant={'REWORK'}> { el } </Card>
                    ));

                details.push(
                    <Paper key={i} elevation={0} variant={'outlined'} sx={{ m: 1, p: 1, fontSize: 11 }}>
                        <Grid display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography sx={{ fontSize: 12, fontWeight: 500 }}> { repairer } </Typography>
                            <Typography sx={{ fontSize: 12, fontWeight: 500 }}>
                                {
                                    new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' })
                                        .format(repairerServices.reduce((sum, part) => sum += part.price, 0))
                                }
                            </Typography>
                        </Grid>
                        <Divider />
                        { serviceDetails }
                        { supplementFlag }
                        { reworkFlag }
                    </Paper>,
                );
            });
            data.push(
                <Grid key={j} sx={{ minWidth: '33%', width: '100%', backgroundColor: theme.palette.grey[30], mx: .5 }}>
                    <Box
                        sx={{
                            borderBottom: '1px solid',
                            borderBottomColor: column.altColor,
                            borderRadius: 0,
                            backgroundColor: column.bgColor,
                        }}
                        py={.5}
                        display={'flex'}
                        justifyContent={'center'}
                    >
                        <Typography> { column.display } &nbsp; </Typography>
                        <Typography color={column.altColor}> { `${filteredList.length}` } </Typography>
                    </Box>
                    <Box height={205} overflow={'auto'}> { details.map((el) => {
                        return el;
                    })} </Box>
                </Grid>,
            );
            j++;
        }
    }

    if (data.length > 1) {
        return (
            <Grid container display={'flex'} justifyContent={'center'} alignContent={'center'} px={2} my={1} gap={1}>
                <Typography my={1} sx={{ fontSize: 14, fontWeight: 500 }} color={theme.palette.grey[900]}>
                    { 'Services by Status' }
                </Typography>
                <Grid display={'flex'} sx={{ width: '100%' }} overflow={'auto'}>
                    { data }
                </Grid>
            </Grid>
        );
    }
    return <></>;
};

ServiceBoard.propTypes = {
    services: PropTypes.array.isRequired,
};

export default ServiceBoard;
