export const parseAddress = (gPlace) => {
    const componensts = gPlace.address_components;

    // Map google place properties to RoD Address properties
    const propMapping = {
        'street_number': 'street_number',
        'route': 'street_name',
        'locality': 'city',
        'administrative_area_level_1': 'state',
        'country': 'country',
        'postal_code': 'zip',
    };

    const newAddress = {};
    for (const comp of componensts) {
        const gPlacePropName = comp.types[0];
        const rodAddPropName = propMapping[gPlacePropName];
        if (rodAddPropName) {
            newAddress[rodAddPropName] = comp.long_name;
        }
    }

    newAddress['formatted_address'] = gPlace.formatted_address;

    return newAddress;
};

export const formatAddress = (place) => {
    const FORMATTED_ADDRESS_PARTS = [
        ['street_number', 'street_name', 'subpremise'],
        ['city'],
        ['state', 'zip'],
        ['country'],
    ];

    let formattedAddress = '';

    for (const props of FORMATTED_ADDRESS_PARTS) {
        let addressPart = '';

        for (const prop of props) {
            const val = place[prop];
            if (val) {
                addressPart += addressPart.length > 0 ? ` ${val}` : val;
            }
        }

        if (addressPart.length > 0) {
            const separator = formattedAddress.length > 0 ? ', ' : '';
            formattedAddress = `${formattedAddress}${separator}${addressPart}`;
        }
    }

    return formattedAddress;
};
