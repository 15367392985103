import { ROUTES } from 'services/utils/enums';
import { sendRequest } from '../utils/api';

export const getCompany = async function() {
    return sendRequest(ROUTES.GET_COMPANY);
};

export const updateCompany = async function(params) {
    return sendRequest(ROUTES.UPDATE_COMPANY, '', params.data);
};

export default {
    getCompany,
    updateCompany,
};
