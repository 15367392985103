import { apiRodx } from '../../config';

export const getJobs = async function({ quickFilters = {}, filters = {}, searchTerm = '', sort = 'desc' }) {
    // MTODO: handle filters and search
    const sortParam = `ordering=${sort}`;
    const searchParam = searchTerm?.length ? `search=${searchTerm.split(' ').join(',')}` : null;
    const filtersParam = Object.keys(quickFilters).map((key) => {
        return quickFilters[key] != null && quickFilters[key]?.length ? `${key}=${quickFilters[key]}` : '';
    }).join('&');

    const queryParams = [sortParam, searchParam, filtersParam].filter((p) => p != null && p.length).join('&');
    const result = await apiRodx['get'](`jobs?${queryParams}`);

    return result;
};

export const getJobCount = async function() {
    const result = await apiRodx['get']('job/count');
    return result;
};

export const getJobById = async function(id) {
    const result = await apiRodx['get'](`job/${id}`);
    return result;
};

export const createJob = async function(params) {
    const result = await apiRodx['post']('job', params.data);
    return result;
};

export const updateJob = async function(params) {
    const result = await apiRodx['post'](`job/${params.id}`, params.data);
    return result;
};

export default {
    getJobs,
    getJobCount,
    getJobById,
    createJob,
    updateJob,
};
