
// @ts-nocheck
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@mui/material';

import Form from 'components/Form';

const LocationForm = ({
    location,
    validations,
    handlePropChange,
    handleContactChange,
    handleAddressChange,
    showCancelBtn = false,
    showSubmitBtn = false,
    cancelBtnDisabled = true,
    submitBtnDisabled = true,
    onSubmit = () => {},
    onCancel = () => {},
    submitBtnTooltip = '',
}) => {
    const [addressText, setAddressText] = useState('');

    const onAddressTextChange = (event) => {
        setAddressText(event.target.value);
        // Remove selected place when address input is edited
        handleAddressChange();
    };

    const onAddressSelect = (place) => {
        handleAddressChange(place);
        setAddressText(place?.formatted_address || '');
    };

    useEffect(() => {
        setAddressText(location?.formatted_address);
    }, [location?.formatted_address]);

    return (
        <Form.FormContainer
            showCancelBtn={showCancelBtn}
            showSubmitBtn={showSubmitBtn}
            cancelBtnDisabled={cancelBtnDisabled}
            submitBtnDisabled={submitBtnDisabled}
            onSubmit={onSubmit}
            onCancel={onCancel}
            submitBtnTooltip={submitBtnTooltip}
        >
            <Form.TextInput
                id='nickname-input'
                onChange={(event) => handlePropChange('nickname', event.target.value)}
                label='Location Name'
                value={location?.nickname ?? ''}
                errorMsg={validations?.nickname}
            />
            <Form.AddressInput
                id='address-input'
                onChange={onAddressTextChange}
                onSelect={onAddressSelect}
                label='Address'
                value={addressText ?? ''}
                errorMsg={validations?.address}
            />
            <Form.TextInput
                id='address2-input'
                onChange={(event) => handlePropChange('subpremise', event.target.value)}
                label='Address 2'
                value={location?.subpremise}
                errorMsg={validations?.subpremise}
            />
            <Form.TextInput
                id='contact-name-input'
                onChange={(event) => handleContactChange('name', event.target.value)}
                label='Name'
                value={location?.contact_info?.name}
                errorMsg={validations?.contact_info?.name}
            />
            <Form.TextInput
                id='contact-email-input'
                onChange={(event) => handleContactChange('email', event.target.value)}
                label='Email'
                value={location?.contact_info?.email}
                errorMsg={validations?.contact_info?.email}
            />
            <Form.TextInput
                id='contact-phone-input'
                onChange={(event) => handleContactChange('phone_number', event.target.value)}
                label='Phone'
                value={location?.contact_info?.phone_number}
                maskType={Form.TYPES.TEXT_MASK.PHONE}
                errorMsg={validations?.contact_info?.phone_number}
            />
            <Form.DropDownInput
                id='billing-input'
                label='Payment Options'
                onChange={(event, value) => handleContactChange('payment_method', value)}
                value={location?.contact_info?.payment_method}
                options={['Credit Card 1', 'Bank #12345', 'Credit Card 4']}
                getOptionLabel={(option) => option}
                errorMsg={validations?.contact_info?.payment_method}
                allowTyping={false}
            />
            <Form.TextInput
                id='notes-input'
                onChange={(event) => handlePropChange('notes', event.target.value)}
                label='Notes'
                value={location?.notes}
                helperText='Notes will be visible to repairers'
                errorMsg={validations?.notes}
            />
        </Form.FormContainer>
    );
};

LocationForm.propTypes = {
    location: PropTypes.shape({
        nickname: PropTypes.string,
        'formatted_address': PropTypes.string,
        subpremise: PropTypes.string,
        'contact_info': PropTypes.shape({
            name: PropTypes.string,
            email: PropTypes.string,
            'phone_number': PropTypes.string,
            'payment_method': PropTypes.string,
        }),
        notes: PropTypes.string,
    }).isRequired,
    validations: PropTypes.object,
    handlePropChange: PropTypes.func.isRequired,
    handleContactChange: PropTypes.func.isRequired,
    handleAddressChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    showCancelBtn: PropTypes.bool,
    showSubmitBtn: PropTypes.bool,
    cancelBtnDisabled: PropTypes.bool,
    submitBtnDisabled: PropTypes.bool,
    submitBtnTooltip: PropTypes.string,
};

export default LocationForm;
