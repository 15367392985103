import { Typography, styled } from '@mui/material';

import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export const StyledTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[800],
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: 'normal',
}));

export const NotificationCardStyles = (theme) => ({
    'New job': { icon: WorkOutlineIcon, color: theme.palette.blue[600] },
    'New Supplement':  { icon: IndeterminateCheckBoxIcon, color: theme.palette.yellow[500] },
    'Service Supplemented':  { icon: IndeterminateCheckBoxIcon, color: theme.palette.yellow[500] },
    'Supplement Approved':  { icon: CheckCircleOutlineIcon, color: theme.palette.green[500] },
    'Supplement Accepted':  { icon: CheckCircleOutlineIcon, color: theme.palette.green[500] },
    'Supplement Declined':  { icon: ErrorOutlineIcon, color: theme.palette.red[700] },
});