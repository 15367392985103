/* eslint-disable no-constant-condition */
// React
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// Local
import { useNewJob } from 'services/reducers/newJob';
import ModalForm from './ModalForm';
import { useError } from 'contexts/ErrorContext';
// Styles
import {
    Backdrop,
    Box,
    Button,
    Fade,
    Grid,
    Modal,
    Typography,
    useTheme,
} from '@mui/material';

const EditServiceModal = ({ resetEditing }) => {
    const theme = useTheme();
    const newJob = useNewJob();
    const err = useError();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        backgroundColor: theme.palette.grey[30],
        border: '0',
        borderRadius: '16px',
        boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
        p: 1,
    };

    const submitService = async function() {
        // const validIds = await validateData();
        // if (validIds && validIds.length) {
        await newJob.DISPATCH({ type: 'EDIT_SERVICE', payload: { ...newJob.state.service } });
        // err.DISPATCH({ type: 'NO_ERROR', payload: { alert: 'Valid Input!' } });
        await handleClose();
        // } else err.DISPATCH({ type: 'INVALID_INPUT', payload: { alert: 'invalid input' } });
    };

    const handleClose = async function() {
        resetEditing();
        // @ts-ignore
        await newJob.DISPATCH({ type: 'SET_EDIT_ID', payload: 0 });
        // @ts-ignore
        await newJob.DISPATCH({ type: 'RESET_SERVICE' });
        // TODO Display Error
    };

    useEffect(() => {
        if (!newJob.state.service.type && newJob.state.editID) {
            // @ts-ignore
            newJob.DISPATCH({ type: 'SET_SERVICE', payload: newJob.state.services.filter(el => el.id === newJob.state.editID).map(el => el)[0] });
        }
    }, [newJob.state]);

    return (
        <Modal
            open={Boolean(newJob.state.editID)}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 300,
                },
            }}
        >
            <Fade in={Boolean(newJob.state.editID)}>
                <Box sx={style}>
                    <Grid item>
                        <Grid item display={'flex'} justifyContent={'center'} my={3}>
                            <Typography sx={{ color: theme.palette.grey[900], fontSize: 20 }}>
                    Edit Service
                            </Typography>
                        </Grid>
                        <ModalForm />
                        <Grid item display='flex' justifyContent={'center'} p={1} m={1}>
                            <Button
                                onClick={submitService}
                                variant='contained'
                                disabled={(!newJob.state.service.repairer.name)}
                                sx={{ backgroundColor: theme.palette.blue[700], color: theme.palette.white, p: 2, width: '85%', textTransform: 'none' }}
                            >
                Done
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </Modal>
    );
};

EditServiceModal.propTypes = {
    resetEditing: PropTypes.func.isRequired,
};

export default EditServiceModal;
