import repairerAPI from './api';
import TYPES from './types';

export const getRepairers = async function(params, successCB, errorCB) {
    // console.log('Refreshing repairers with params:', params);

    const handleError = (error) => {
        if (errorCB && typeof errorCB === 'function') {
            errorCB(error);
        } else {
            throw new Error(`Error loading repairers: ${JSON.stringify(error)}`);
        }
    };

    try {
        const response = await repairerAPI.getRepairers();
        if (response && response.data.status === 200 && response.data.payload) {
            if (successCB && typeof successCB === 'function') {
                successCB(response.data.payload);
            }
            return response.data.payload;
        }
        handleError({ status: response.data.status, type: 'API_ERROR', msg: response?.message ?? response });
    } catch (error) {
        handleError({ type: 'API_ERROR', msg: error });
    }
};

export const refreshRepairers = async (params, repairerContext, errContext, successCB, errorCB) => {
    const onRepairersLoadError = ({ status, type, msg }) => {
        errContext.DISPATCH({
            type,
            payload: { status, alert: msg },
        });
        if (errorCB && typeof errorCB === 'function') {
            errorCB({ status, type, msg });
        }
    };

    const onRepairersLoaded = (repairers) => {
        // MTODO: why dispatch error on success, maybe should be called something else??
        onRepairersLoadError({ status: 200, type: 'NO_ERROR', msg: 'Repairers loaded!' });

        repairerContext.DISPATCH({
            type: TYPES.SET_REPAIRERS,
            payload: repairers.filter(el => el),
        });

        if (successCB && typeof successCB === 'function') {
            successCB(repairers);
        }
    };
    await getRepairers(params, onRepairersLoaded, onRepairersLoadError);
};

export const setActiveRepairer = async (id, repairerContext) => {
    const curId = repairerContext.state.activeRepairerId;

    if (id === curId) {
        return;
    }

    repairerContext.DISPATCH({ type: TYPES.SET_ACTIVE_REPAIRER, payload: { id } });
};

export const changeRepairerLocationActiveState = async (name, active, repairerContext) => {
    repairerContext.DISPATCH({
        type: TYPES.EDIT_REPAIRER_LOCATION_ACTIVE,
        payload: { name, active },
    });
};
