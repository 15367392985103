import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTheme, styled, Box, Grid, Typography, Button, Divider, Card } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';

import { STATUS_BAR_CLASS, STATUS_BAR_DISPLAY } from 'services/utils/enums';

import { useError } from 'contexts/ErrorContext';
import { useJobs, TYPES as JOB_TYPES } from 'services/job';
import { approveSupplementService, declineSupplementService } from 'services/actions/service';

import ServiceHistory from '../Service/History/ServiceHistoryModal';
import MediaWithPopup, { SUPPORTED_MEDIA_TYPES } from 'components/MediaWithPopup';

const ApproveButton = styled(Button)(({ theme }) => ({
    textTransform: 'uppercase',
    borderRadius: theme.spacing(1),
    padding: '2px 8px',
    marginRight: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5),
    background: theme.palette.blue[40],
    color: theme.palette.blue.main,
    '&: hover': {
        background: theme.palette.blue[40],
        color: theme.palette.blue.main,
    },
}));

const DeclineButton = styled(Button)(({ theme }) => ({
    textTransform: 'uppercase',
    borderRadius: theme.spacing(1),
    padding: '2px 8px',
    marginRight: theme.spacing(1.5),
    marginLeft: theme.spacing(1.5),
    background: theme.palette.grey[40],
    color: theme.palette.red.main,
    '&: hover': {
        background: theme.palette.grey[40],
        color: theme.palette.red.main,
    },
}));

const ServiceDetails = ({ service, i, activeJob, rep }) => {
    const jobsContext = useJobs();
    const theme = useTheme();
    const err = useError();

    const handleHistoryView = async function (service, i) {
        let t = viewingHistory;
        t[i] = true;
        setViewing(service.id);
        await setViewingHistory(t);
    };

    const handleHistoryViewClose = async function() {
        resetViewing();
    };

    const handleSupplement = async function(decision, service, price) {
        try {
            if (decision == 'approve') {
                await approveSupplementService({ serviceID: service });
                await setTimeout(() => {
                    jobsContext.DISPATCH({ type: JOB_TYPES.SET_ACTIVE_JOB, payload: 0 });
                }, 250);
            } else {
                await declineSupplementService({ serviceID: service, price: price });
                await setTimeout(() => {
                    jobsContext.DISPATCH({ type: JOB_TYPES.SET_ACTIVE_JOB, payload: 0 });
                }, 250);
            }
        } catch (error) {
            console.log(error);
            // @ts-ignore
            err.DISPATCH({ type: 'API_ERROR', payload: error });
        }
    };

    const [viewingHistory, setViewingHistory] = useState([]);
    const [viewing, setViewing] = useState(0);

    const resetViewing = async function () {
        const v = activeJob.services.map(() => false);
        await setViewingHistory(v);
        await setViewing(0);
    };

    useEffect(() => {
        if (activeJob.services && activeJob.services.length && !viewingHistory.length) resetViewing();
    }, [viewing, viewingHistory]);

    const supplementControls = STATUS_BAR_DISPLAY[service.service_status] === 'Pending' && service?.supplement?.status ? (
        <Card variant={'outlined'} elevation={0} sx={{ width:'100%', p: 1, borderRadius: 2 }}>
            <Grid display={'flex'} justifyContent={'center'}>
                {/* @ts-ignore */}
                <Card variant={'SUPPLEMENT_PENDING'}> Supplement Pending </Card>
            </Grid>
            <Grid display={'flex'} justifyContent={'center'} mb={1}>
                <Typography variant={'h6'}> { `Service has pending supplemental work from ${rep}` } </Typography>
            </Grid>
            <Grid display={'flex'} justifyContent={'center'}>
                <ApproveButton variant='contained' onClick={() => {handleSupplement('approve', service.id);}}>
                    Approve
                </ApproveButton>
                <DeclineButton
                    variant='contained'
                    onClick={() => {
                        handleSupplement(
                            'decline',
                            service.id,
                            service.service_history ?
                                // @ts-ignore
                                service.service_history.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))[0].price :
                                service.price,
                        );
                    }}>
                        Decline
                </DeclineButton>
            </Grid>
        </Card>
    ) : null;

    return (
        <div key={i}>
            <Card variant='outlined' sx={{ borderRadius: 2, my: 2, p: 2 }}>
                <Grid display={'flex'} justifyContent={'space-between'}p={0} m={0} mb={1.5} alignItems={'center'}>
                    <Grid display={'flex'} alignItems={'center'}>
                        <Typography sx={{ fontSize: 14, fontWeight: 500 }} color={theme.palette.grey[900]}>
                            { service.service_details.repair_type || service.service_details.service_type }
                        </Typography>
                        &nbsp;
                        <Typography sx={{ fontSize: 12, fontWeight: 400 }} color={theme.palette.grey[500]}>
                            { `${(service.service_details.position) ? service.service_details.position.replace('N/A', '') : ''}
                        ${(service.service_details.part) ? service.service_details.part.replace('N/A', '') : ''}` }
                        </Typography>
                    </Grid>
                    <Grid display={'flex'} gap={1}>
                        { !service?.service_history?.length ?
                            null :
                            service.service_history
                                .map(el => el.price)
                                .filter(el => el && el != service.price)
                                .map((el, ind) => (
                                    <Typography
                                        key={ind}
                                        color={theme.palette.grey[500]}
                                        sx={{ fontSize: 12, textDecoration: 'line-through', fontWeight: 300 }}
                                    >
                                        { new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(el) }
                                    </Typography>
                                ))
                        }
                        <Typography sx={{ fontSize: 12, fontWeight: 500 }} color={theme.palette.grey[900]} display={'flex'}>
                            { new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(service.price) }
                        </Typography>
                    </Grid>
                </Grid>
                <Grid
                    display={'flex'}
                    justifyContent={'start'}
                    alignItems={'center'} p={0} m={0} my={0.5}
                >
                    <Card
                        variant={STATUS_BAR_CLASS[service.service_status]}
                    >
                        { STATUS_BAR_DISPLAY[service.service_status] }
                    </Card>
                    { (service.supplement && service.supplement.status) && (
                        <Card
                            variant={STATUS_BAR_CLASS[service.supplement.status]}
                        >
                            { `Supplement ${service.supplement.status}` }
                        </Card>
                    )}
                    { service.rework && (
                        <Card
                            // @ts-ignore
                            variant={'REWORK'}
                        >Rework</Card>
                    )}
                </Grid>
                <Divider />
                {
                    !service?.media?.length ? null : (
                        <Grid
                            justifyContent={'start'}
                            alignItems={'center'} p={0} m={0} my={1.5} gap={1}
                        >
                            { service.media.map((serv) => {
                                return serv.submission_content
                                    .filter(m => m.media_type == 'Text').map((el, i) => {
                                        return (
                                            <Typography
                                                key={i}
                                                sx={{ fontSize: 14, fontWeight: 400 }}
                                                color={theme.palette.grey[900]}
                                            >
                                                { el.text }
                                            </Typography>
                                        );
                                    });
                            })}
                        </Grid>
                    )
                }
                {
                    !service?.media?.length ? null : (
                        <Grid display={'flex'} justifyContent={'start'} alignItems={'center'} p={0} m={0} my={1.5} gap={1}>
                            { service.media.map((serv) => {
                                return serv.submission_content
                                    .filter(m => m.media_type != 'Text').map((media, i) => {
                                        if (SUPPORTED_MEDIA_TYPES.includes(media.media_type)) {
                                            return (
                                                <MediaWithPopup
                                                    key={i}
                                                    url={media.url}
                                                    title={media.text || ''}
                                                    type={media.media_type}
                                                    width='65px'
                                                    height='65px'
                                                />
                                            );
                                        }

                                        return (
                                            <Box key={i} style={{ position: 'relative' }}>
                                                <iframe width='70' height='70' src={media.url}></iframe>;
                                                <a href={media.url} target='_blank' rel='noreferrer' style={{
                                                    display: 'flex',
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: '100%',
                                                    height: '100%',
                                                    zIndex: 1,
                                                }}></a>
                                            </Box>
                                        );
                                    });
                            })}
                        </Grid>
                    )
                }
                {
                    ((service.notes && service.notes.length) || (service.media && service.media.length))
                        ? <Divider />
                        : null
                }
                <Button fullWidth={true} title={`btn-edt-${i}`} onClick={() => handleHistoryView(service, i)} disabled={viewingHistory[i]}>
                    <Grid sx={{ width:'100%' }} display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={0} m={0} my={.5}>
                        <Typography sx={{ fontSize: 12, fontWeight: 500 }} color={theme.palette.blue[500]}> History </Typography>
                        <ChevronRight />
                    </Grid>
                </Button>
                {
                    viewing != service.id ? null : (
                        <ServiceHistory
                            open={viewing == service.id}
                            onClose={() => handleHistoryViewClose()}
                            service={service}
                            supplier={activeJob['supplier_company_name']}
                        />
                    )
                }
                {supplementControls}
            </Card>
        </div>
    );
};

ServiceDetails.propTypes = {
    service: PropTypes.object,
    i: PropTypes.number,
    activeJob: PropTypes.object,
    rep: PropTypes.string,
};

export default ServiceDetails;
