
import React from 'react';
import PropTypes from 'prop-types';

import { styled, Button } from '@mui/material';

const StyledActionButton = styled(Button)(({ theme }) => ({
    textTransform: 'none',
    borderRadius: 8,
    background: theme.palette.blue.main,
    color: theme.palette.white,
    '& .MuiButton-startIcon': {
        color: theme.palette.blue[300],
    },
    '&: hover': { backgroundColor: theme.palette.blue.main },
    variant: 'contained',
}));

export default function TitlePanelActionButton({
    title,
    onClick,
    iconComponent,
}) {
    const IconComponent = iconComponent;

    return (
        <StyledActionButton
            onClick={onClick}
            startIcon={<IconComponent />}
        >
            {title}
        </StyledActionButton>
    );
}

TitlePanelActionButton.propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func,
    iconComponent: PropTypes.any,
};
