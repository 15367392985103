import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, useTheme } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

const StatusIndicator = ({ active, status, color }) => {
    const theme = useTheme();

    const indicatorColor = color ?? (active ? theme.palette.success.main : theme.palette.grey[100]);

    return (
        <Grid container direction='row' sx={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
            <CircleIcon sx={{ fontSize: 8, color: indicatorColor }} />
            <Typography my={.5} sx={{ fontSize: 12, color: theme.palette.grey[500], marginLeft: '4px' }}>
                {status}
            </Typography>
        </Grid>
    );
};

StatusIndicator.propTypes = {
    status: PropTypes.string,
    active: PropTypes.bool,
    color: PropTypes.string,
};

export default StatusIndicator;
