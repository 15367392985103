import { ROUTES } from 'services/utils/enums';
import { sendRequest } from '../utils/api';

const getLocations = async function() {
    return sendRequest(ROUTES.GET_ALL_LOCATIONS);
};

const getLocationById = async function(id) {
    return sendRequest(ROUTES.GET_LOCATION, id);
};

const createLocation = async function(params) {
    return sendRequest(ROUTES.CREATE_LOCATION, '', params.data, params.config);
};

const updateLocation = async function(params) {
    return sendRequest(ROUTES.UPDATE_LOCATION, params.data.id, params.data, params.config);
};

export default {
    getLocations,
    getLocationById,
    createLocation,
    updateLocation,
};
