import React from 'react';

import { useNotifications } from 'services/notification';

import NotificationList from './NotificationList';
import NotificationDetails from './NotificationDetails';

const NotificationPopup = () => {
    const NotificationContext = useNotifications();
    const { notifications } = NotificationContext.state;

    const [selectedNotif, setSelectedNotif] = React.useState(null);

    return selectedNotif == null ? (
        <NotificationList
            notifications={notifications}
            onClick={(notification) => {
                NotificationContext.actions.markNotificationAsRead(notification.id);
                setSelectedNotif(notification);
            } }
        />
    ) : (
        <NotificationDetails
            notification={selectedNotif}
            onClose={() => setSelectedNotif(null)}
        />
    );
};

export default NotificationPopup;
