import { useState, useMemo } from 'react';
import { createTheme } from '@mui/material/styles';
import { color } from 'framer-motion';

// color design tokens export
export const COLORS = (mode) => ({
    ...(mode === 'dark'
        ? {
            primary: { // Use 'Blue' as a more descriptive variable option rather than 'primary'
                main: '#307FE2',
                30: '#F0F7FF',
                40: '#E9F5FF',
                50: '#E3F1FD',
                100: '#D6E8FC',
                200: '#BBDBFB',
                300: '#91C6F8',
                400: '#66AFF5',
                500: '#499EF3',
                600: '#318EF0',
                700: '#307FE2',
                800: '#295DBC',
                900: '#233F9D',
            },
            green: {
                main: '#0A891B',
                30: '#F3F9F4',
                40: '#E6F3E8',
                50: '#CEE7D1',
                100: '#B6DCBB',
                200: '#9DD0A4',
                300: '#84C48D',
                400: '#6CB876',
                500: '#53AC5F',
                600: '#3BA049',
                700: '#0A891B',
                800: '#097B18',
                900: '#076013',
            },
            yellow: {
                main: '#F1BE0C',
                30: '#FEF9E7',
                40: '#FCF2CE',
                50: '#FBECB6',
                100: '#F9E59E',
                200: '#F8DF85',
                300: '#F7D86D',
                400: '#F5D255',
                500: '#F4CB3D',
                600: '#F2C424',
                700: '#F1BE0C',
                800: '#C0970B',
                900: '#A98508',
            },
            red: {
                main: '#E80604',
                30: '#FDE6E6',
                40: '#FACDCD',
                50: '#F8B4B4',
                100: '#F69B9B',
                200: '#F48281',
                300: '#F16A68',
                400: '#EF514F',
                500: '#ED3836',
                600: '#EA1F1D',
                700: '#E80604',
                800: '#D10504',
                900: '#A20403',
            },
            blue: {
                main: '#307FE2',
                30: '#F0F7FF',
                40: '#E9F5FF',
                50: '#E3F1FD',
                100: '#D6E8FC',
                200: '#BBDBFB',
                300: '#91C6F8',
                400: '#66AFF5',
                500: '#499EF3',
                600: '#318EF0',
                700: '#307FE2',
                800: '#295DBC',
                900: '#233F9D',
            },
            twilightBlue: {
                main: '#233F9D',
                30: '#E9ECF5',
                40: '#D3D9EB',
                50: '#BDC5E2',
                100: '#A7B2D8',
                200: '#919FCE',
                300: '#7B8CC4',
                400: '#6579BA',
                500: '#4F65B1',
                600: '#3952A7',
                700: '#233F9D',
                800: '#1F398D',
                900: '#182C6E',
            },
            grey: {
                main: '#4D5559',
                30: '#F6F7FA',
                40: '#ECF0F5',
                50: '#E2E8EE',
                100: '#CAD2D9',
                200: '#ADB8BE',
                300: '#939DA5',
                400: '#808B90',
                500: '#69787F',
                600: '#5D696F',
                700: '#4D5559',
                800: '#343F46',
                900: '#141E25',
            },
            white: '#FFF',
            black: '#000',
        }
        : {
            primary: { // Use 'Blue' as a more descriptive variable option rather than 'primary'
                main: '#307FE2',
                30: '#F0F7FF',
                40: '#E9F5FF',
                50: '#E3F1FD',
                100: '#D6E8FC',
                200: '#BBDBFB',
                300: '#91C6F8',
                400: '#66AFF5',
                500: '#499EF3',
                600: '#318EF0',
                700: '#307FE2',
                800: '#295DBC',
                900: '#233F9D',
            },
            green: {
                main: '#0A891B',
                30: '#F3F9F4',
                40: '#E6F3E8',
                50: '#CEE7D1',
                100: '#B6DCBB',
                200: '#9DD0A4',
                300: '#84C48D',
                400: '#6CB876',
                500: '#53AC5F',
                600: '#3BA049',
                700: '#0A891B',
                800: '#097B18',
                900: '#076013',
            },
            yellow: {
                main: '#F1BE0C',
                30: '#FEF9E7',
                40: '#FCF2CE',
                50: '#FBECB6',
                100: '#F9E59E',
                200: '#F8DF85',
                300: '#F7D86D',
                400: '#F5D255',
                500: '#F4CB3D',
                600: '#F2C424',
                700: '#F1BE0C',
                800: '#C0970B',
                900: '#A98508',
            },
            red: {
                main: '#E80604',
                30: '#FDE6E6',
                40: '#FACDCD',
                50: '#F8B4B4',
                100: '#F69B9B',
                200: '#F48281',
                300: '#F16A68',
                400: '#EF514F',
                500: '#ED3836',
                600: '#EA1F1D',
                700: '#E80604',
                800: '#D10504',
                900: '#A20403',
            },
            blue: {
                main: '#307FE2',
                30: '#F0F7FF',
                40: '#E9F5FF',
                50: '#E3F1FD',
                100: '#D6E8FC',
                200: '#BBDBFB',
                300: '#91C6F8',
                400: '#66AFF5',
                500: '#499EF3',
                600: '#318EF0',
                700: '#307FE2',
                800: '#295DBC',
                900: '#233F9D',
            },
            twilightBlue: {
                main: '#233F9D',
                30: '#E9ECF5',
                40: '#D3D9EB',
                50: '#BDC5E2',
                100: '#A7B2D8',
                200: '#919FCE',
                300: '#7B8CC4',
                400: '#6579BA',
                500: '#4F65B1',
                600: '#3952A7',
                700: '#233F9D',
                800: '#1F398D',
                900: '#182C6E',
            },
            grey: {
                main: '#4D5559',
                30: '#F6F7FA',
                40: '#ECF0F5',
                50: '#E2E8EE',
                100: '#CAD2D9',
                200: '#ADB8BE',
                300: '#939DA5',
                400: '#808B90',
                500: '#69787F',
                600: '#5D696F',
                700: '#4D5559',
                800: '#343F46',
                900: '#141E25',
            },
            white: '#FFF',
            black: '#000',
        }),
});

// mui theme settings
export const themeSettings = (mode) => {
    const colors = COLORS(mode);
    return {
        shadows: [
            'none', // shadow 0
            '0px 1px 4px 0px rgba(0, 0, 0, 0.15), 0px 2px 6px 1px rgba(0, 0, 0, 0.1)', // shadow 1
            '0px 2px 6px 0px rgba(0, 0, 0, 0.15), 0px 4px 12px 2px rgba(0, 0, 0, 0.1)', // shadow 2
            '0px 6px 12px 3px rgba(0, 0, 0, 0.1), 0px 2px 6px 1px rgba(0, 0, 0, 0.15)', // shadow 3
            '0px 8px 16px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px 2px rgba(0, 0, 0, 0.15)', // shadow 4
            '0px 10px 20px 5px rgba(0, 0, 0, 0.1), 0px 6px 12px 3px rgba(0, 0, 0, 0.15)', // shadow 5
            '0px 12px 24px 6px rgba(0, 0, 0, 0.1), 0px 8px 16px 4px rgba(0, 0, 0, 0.15)', // shadow 6
            '0px 14px 28px 7px rgba(0, 0, 0, 0.1), 0px 10px 20px 5px rgba(0, 0, 0, 0.15)', // shadow 7
            '0px 16px 32px 8px rgba(0, 0, 0, 0.1), 0px 12px 24px 6px rgba(0, 0, 0, 0.15)', // shadow 8
        ],
        palette: {
            mode: mode,
            ...(mode === 'dark'
                ? {
                    // palette values for dark mode
                    common: {
                        white: 'white',
                    },
                    secondary: {
                        light: colors.blue[300],
                        main: colors.blue[700],
                    },
                    neutral: {
                        dark: colors.grey[700],
                        main: colors.grey[500],
                        light: colors.grey[100],
                    },
                    background: {
                        default: '#010319',
                    },
                    success: {
                        main: '#2FE047',
                    },
                    primary: colors.primary,
                    green: colors.green,
                    yellow: colors.yellow,
                    red: colors.red,
                    blue: colors.blue,
                    twilightBlue: colors.twilightBlue,
                    grey: colors.grey,
                    white: colors.white,
                    black: colors.black,
                }
                : {
                    // palette values for light mode
                    common: {
                        white: 'white',
                    },
                    secondary: {
                        light: colors.blue[300],
                        main: colors.blue[700],
                    },
                    neutral: {
                        dark: colors.grey[700],
                        main: colors.grey[500],
                        light: colors.grey[100],
                    },
                    background: {
                        default: '#F1F3F9',
                        paper: '#FCFCFD',
                    },
                    success: {
                        main: '#2FE047',
                    },
                    primary: colors.primary,
                    green: colors.green,
                    yellow: colors.yellow,
                    red: colors.red,
                    blue: colors.blue,
                    twilightBlue: colors.twilightBlue,
                    grey: colors.grey,
                    white: colors.white,
                    black: colors.black,
                }),
        },
        typography: {
            fontFamily: ['Roboto', 'sans-serif'].join(','),
            fontSize: 12,
            subtitle2: {
                fontSize: 14,
                fontFamily: ['Roboto', 'sans-serif'].join(','),
                fontWeight: 500,
            },
            subtitle1: {
                fontSize: 16,
                fontFamily: ['Roboto', 'sans-serif'].join(','),
                fontWeight: 400,
            },
            h1: {
                fontFamily: ['Roboto', 'sans-serif'].join(','),
                fontSize: 28,
                fontWeight: 500,
            },
            h2: {
                fontFamily: ['Roboto', 'sans-serif'].join(','),
                fontSize: 32,
            },
            h3: {
                fontFamily: ['Roboto', 'sans-serif'].join(','),
                fontSize: 24,
            },
            h4: {
                fontFamily: ['Roboto', 'sans-serif'].join(','),
                fontSize: 20,
            },
            h5: {
                fontFamily: ['Roboto', 'sans-serif'].join(','),
                fontSize: 16,
            },
            h6: {
                fontFamily: ['Roboto', 'sans-serif'].join(','),
                fontSize: 14,
            },
        },
        components: {
            MuiSvgIcon: {
                styleOverrides: {
                    root: {
                        color: colors.blue[500],
                    },
                },
            },
            MuiButton: {
                defaultProps: {
                    disableElevation: true,
                },
            },
            MuiAppBar: {
                styleOverrides: {
                    colorPrimary: {
                        background: colors.white,
                        borderBottom: '1px solid',
                        borderBottomColor: colors.grey[40],
                    },
                },
                defaultProps: {
                    position: 'static',
                    elevation: 0,
                },
            },
            MuiCard: {
                defaultProps: {
                    elevation: 0,
                },
                styleOverrides: {
                    root: {
                        borderRadius: '16px',
                        padding: '20px',
                    },
                },
                variants: [
                    {
                        props: { variant: 'OPEN' },
                        style: {
                            'background': colors.blue[50],
                            'color': colors.blue.main,
                            'display': 'block',
                            'justifyContent': 'center',
                            'alignItems': 'center',
                            'padding': '4px 8px',
                            'margin': '4px',
                            'borderRadius': '4px',
                            'maxWidth': 'fit-content',
                            'fontSize': '12px',
                        },
                    },
                    {
                        props: { variant: 'IN PROGRESS' },
                        style: {
                            'background': colors.blue[50],
                            'color': colors.blue.main,
                            'display': 'block',
                            'justifyContent': 'center',
                            'alignItems': 'center',
                            'padding': '4px 8px',
                            'margin': '4px',
                            'borderRadius': '4px',
                            'maxWidth': 'fit-content',
                            'fontSize': '12px',
                        },
                    },
                    {
                        props: { variant: 'PENDING' },
                        style: {
                            'background': colors.grey[40],
                            'color': colors.grey.main,
                            'display': 'block',
                            'justifyContent': 'center',
                            'alignItems': 'center',
                            'padding': '4px 8px',
                            'margin': '4px',
                            'borderRadius': '4px',
                            'maxWidth': 'fit-content',
                            'fontSize': '12px',
                        },
                    },
                    {
                        props: { variant: 'IN_PROGRESS' },
                        style: {
                            'background': colors.blue[50],
                            'color': colors.blue.main,
                            'display': 'block',
                            'justifyContent': 'center',
                            'alignItems': 'center',
                            'padding': '4px 8px',
                            'margin': '4px',
                            'borderRadius': '4px',
                            'maxWidth': 'fit-content',
                            'fontSize': '12px',
                        },
                    },
                    {
                        props: { variant: 'ACCEPTED' },
                        style: {
                            'background': colors.green[40],
                            'color': colors.green[600],
                            'display': 'block',
                            'justifyContent': 'center',
                            'alignItems': 'center',
                            'padding': '4px 8px',
                            'margin': '4px',
                            'borderRadius': '4px',
                            'maxWidth': 'fit-content',
                            'fontSize': '12px',
                        },
                    },
                    {
                        props: { variant: 'DECLINED' },
                        style: {
                            'background': colors.red[40],
                            'color': colors.red[600],
                            'display': 'block',
                            'justifyContent': 'center',
                            'alignItems': 'center',
                            'padding': '4px 8px',
                            'margin': '4px',
                            'borderRadius': '4px',
                            'maxWidth': 'fit-content',
                            'fontSize': '12px',
                        },
                    },
                    {
                        props: { variant: 'CANCELLED' },
                        style: {
                            'background': colors.grey[40],
                            'color': colors.grey[400],
                            'display': 'block',
                            'justifyContent': 'center',
                            'alignItems': 'center',
                            'padding': '4px 8px',
                            'margin': '4px',
                            'borderRadius': '4px',
                            'maxWidth': 'fit-content',
                            'fontSize': '12px',
                        },
                    },
                    {
                        props: { variant: 'COMPLETED' },
                        style: {
                            'background': colors.green[500],
                            'color': colors.green[30],
                            'display': 'block',
                            'justifyContent': 'center',
                            'alignItems': 'center',
                            'padding': '4px 8px',
                            'margin': '4px',
                            'borderRadius': '4px',
                            'maxWidth': 'fit-content',
                            'fontSize': '12px',
                        },
                    },
                    {
                        props: { variant: 'INVOICED' },
                        style: {
                            'background': colors.blue[500],
                            'color': colors.blue[30],
                            'display': 'block',
                            'justifyContent': 'center',
                            'alignItems': 'center',
                            'padding': '4px 8px',
                            'margin': '4px',
                            'borderRadius': '4px',
                            'maxWidth': 'fit-content',
                            'fontSize': '12px',
                        },
                    },
                    {
                        props: { variant: 'CLOSED' },
                        style: {
                            'background': colors.green[500],
                            'color': colors.green[30],
                            'display': 'block',
                            'justifyContent': 'center',
                            'alignItems': 'center',
                            'padding': '4px 8px',
                            'margin': '4px',
                            'borderRadius': '4px',
                            'maxWidth': 'fit-content',
                            'fontSize': '12px',
                        },
                    },
                    {
                        props: { variant: 'SUPPLEMENT_PENDING' },
                        style: {
                            'background': colors.yellow[30],
                            'color': colors.yellow[900],
                            'display': 'block',
                            'justifyContent': 'center',
                            'alignItems': 'center',
                            'padding': '4px 8px',
                            'margin': '4px',
                            'borderRadius': '4px',
                            'maxWidth': 'fit-content',
                            'fontSize': '12px',
                        },
                    },
                    {
                        props: { variant: 'REWORK' },
                        style: {
                            'background': colors.grey[30],
                            'color': colors.blue[900],
                            'display': 'block',
                            'justifyContent': 'center',
                            'alignItems': 'center',
                            'padding': '4px 8px',
                            'margin': '4px',
                            'borderRadius': '4px',
                            'maxWidth': 'fit-content',
                            'fontSize': '12px',
                        },
                    },
                ],
            },
            MuiPaper: {
                styleOverrides: {
                    elevation1: {
                        boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.1)',
                    },
                },
            },
            MuiTextField: {
                defaultProps: {
                    color: 'secondary',
                    variant: 'standard',
                },
                styleOverrides: {
                    root: {
                        '& .MuiFormHelperText-root': {
                            position: 'absolute',
                            bottom: -20,
                        },
                        '& .MuiInputLabel-root': {
                            color: colors.blue[500],
                            fontSize: 16,
                            paddingLeft: '0px',
                        },
                        '& .MuiInput-root': {
                            fontSize: '16px',
                        },
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                        '&.nav-menu-icon': {
                            color: colors.grey[500],
                        },
                    },
                },
                defaultProps: {
                    disableRipple: true,
                },
            },
            MuiListItemIcon: {
                styleOverrides: {

                },
            },
            MuiPickersDay: {
                styleOverrides: {
                    root: {
                        '&.selected': {
                            background: `${colors.blue[700]}!important`,
                            color: 'white',
                        },
                        '&.between': {
                            background: `${colors.blue[700]}!important`,
                            borderRadius: 0,
                            margin: 0,
                            boxSizing: 'initial',
                            borderLeft: `solid 2px ${colors.blue[700]}`,
                            borderRight: `solid 2px ${colors.blue[700]}`,
                            color: 'white',
                        },
                        '&.begin': {
                            background: `${colors.blue[700]}!important`,
                            marginRight: 0,
                            boxSizing: 'initial',
                            borderRight: `solid 2px ${colors.blue[700]}`,
                            color: 'white',
                            borderRadius: '50% 0% 0% 50%',
                        },
                        '&.end': {
                            border: 'none',
                            background: `${colors.blue[700]}!important`,
                            marginLeft: 0,
                            boxSizing: 'initial',
                            borderLeft: `solid 2px ${colors.blue[700]}`,
                            borderRight: 'none!important',
                            color: 'white',
                            borderRadius: '0% 50% 50% 0%',
                        },
                        '&:hover': {
                            background: colors.grey[500],
                        },
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    popper: {
                        minWidth: '150px',
                        '& li.option:hover': {
                            background: colors.grey[500],
                        },
                    },
                    tag: {
                        backgroundColor: colors.grey[30],
                        color: colors.blue.main,
                        paddingLeft: 1,
                        paddingRight: 1,
                        border: '1px solid',
                        borderRadius: 6,
                        borderColor: colors.grey[100],
                        fontSize: 14,
                    },
                    root: {
                        border: '1px solid',
                        borderColor: colors.grey[100],
                        borderRadius: 6,
                        width: 'auto',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        '& .MuiAutocomplete-endAdornment': {
                            top: 0,
                        },
                        '& .MuiSvgIcon-root[data-testid=ArrowDropDownIcon], &  .MuiSvgIcon-root[data-testid=CloseIcon]': {
                            color: colors.grey[500],
                        },
                        '& .renderTag': {
                            fontSize: '12px',
                            color: colors.grey[700],
                        },
                        '& .label': {
                            marginRight: '16px',
                            fontSize: '12px',
                        },
                        '& .MuiInput-underline:hover::before': {
                            border: 'none!important',
                        },
                        '& .MuiAutocomplete-input': {
                        },
                        '& .MuiInput-root::before': {
                            border: 'none',
                        },
                        '& .MuiInput-root::before,.MuiInput-root::after': {
                            border: 'none',
                        },
                    },
                },
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        '& ::placeholder': {
                            color: colors.grey[800],
                            opacity: 1,
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                // styleOverrides: {
                //     root: {
                //         overflow: 'hidden',
                //         justifyContent: 'center',
                //         alignItems: 'center',
                //         '& > .MuiOutlinedInput-notchedOutline': {
                //             border: 'none',
                //         },
                //     },
                // },
            },
            MuiSelect: {
                defaultProps: {
                    variant: 'outlined',
                },
                styleOverrides: {
                    root: {
                        cursor: 'pointer',
                        '& > .MuiTypography-root': {
                            marginRight: '8px',
                            pointEvents: 'none',
                            cursor: 'pointer',
                        },
                        '& > .MuiOutlinedInput-notchedOutline': { border: 'none' },
                        border: '1px solid',
                        borderColor: colors.grey[100],
                        borderRadius: '8px',
                        overflow: 'hidden',
                    },
                    select: {
                        width: 'auto',
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '0px',
                        height: '32px',
                        background: colors.white,
                        flex: 'none',
                        order: '1',
                        flexGrow: '0',
                    },
                    chip: {
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '6px 8px',
                        gap: '8px',
                        width: '138px',
                        height: '32px',
                        flex: 'none',
                        order: '0',
                        flexGrow: '0',
                        border: 'none',
                    },
                    leadingIcon: {
                        width: '18px',
                        height: '18px',
                        flex: 'none',
                        order: '0',
                        flexGrow: '0',
                    },
                    leadingIconVector: {
                        position: 'absolute',
                        left: '0%',
                        right: '0%',
                        top: '0%',
                        bottom: '0%',
                        background: colors.blue.main,
                    },
                    trailingIcon: {
                        width: '18px',
                        height: '18px',
                        flex: 'none',
                        order: '2',
                        flexGrow: '0',
                    },
                    trailingIconArrow: {
                        position: 'absolute',
                        left: '29.17%',
                        right: '29.17%',
                        top: '41.67%',
                        bottom: '37.5%',
                        background: colors.grey[900],
                    },
                    label: {
                        width: '70px',
                        height: '14px',
                        fontFamily: 'Roboto',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        fontSize: '12px',
                        lineHeight: '14px',
                        display: 'flex',
                        alignItems: 'center',
                        color: colors.grey[800],
                        flex: 'none',
                        order: '1',
                        flexGrow: '0',
                    },
                },
            },
        },
    };
};

export const useMode = () => {
    const [mode, setMode] = useState('light');

    const colorMode = useMemo(
        () => ({
            toggleColorMode: () =>
                setMode((prev) => (prev === 'light' ? 'dark' : 'light')),
        }),
        [],
    );

    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
    return [theme, colorMode];
};
