import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Box, Grid, Paper, Typography, useTheme, IconButton, MenuItem, Menu } from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';

import { useJobs, TYPES as JOB_TYPES } from 'services/job';
import { cancelService } from 'services/actions/service';
import { useError } from 'contexts/ErrorContext';

import ServiceDetails from './ServiceDetails';

import { ServiceReworkModal } from '../ServiceReworkModal';

const RepairerCards = ({ activeJob, quickOptionsEnums }) => {
    const jobsContext = useJobs();
    const theme = useTheme();
    const err = useError();

    const [anchorElMenu, setAnchorElMenu] = useState(null);
    const [currentOpenMenu, setCurrentOpenMenu] = useState(0);
    const [reworkModalOpen, setRWModalOpen] = useState(false);

    const handleClickMenu = ((current) => {
        setAnchorElMenu(current);
    });

    const handleCloseMenu = (() => {
        setAnchorElMenu(null);
        setCurrentOpenMenu(0);
    });

    const handleCancelMenuItem = async function(option) {
        try {
            await Promise.all(option.payload.map(el => cancelService(el)));
            await jobsContext.DISPATCH({ type: JOB_TYPES.SET_ACTIVE_JOB, payload: 0 });
            handleCloseMenu();
        } catch (error) {
            console.log(error);
            // @ts-ignore
            err.DISPATCH({ type: 'API_ERROR', payload: error });
        }
    };

    const handleReworkMenuItem = async function(option) {
        setRWModalOpen(true);
        // handleCloseMenu();
    };

    const services = activeJob.services;

    const repairerList = Array.from(new Set(services.map(el => el.repair_company.name))).sort();

    const repairerElements = repairerList.map((rep, key) => {
        let serviceList = services.filter(el => el.repair_company.name === rep);
        let total = serviceList.reduce((sum, part) => sum += part.price, 0);
        let closeOpts = [];
        let reworkOpts = [];

        closeOpts.push({
            label: `Cancel all possible ${rep} services`,
            payload: serviceList.filter(el => quickOptionsEnums['CANCELLABLE'].includes(el.service_status.toUpperCase())).map(el => ({ serviceID: el.id })),
        });

        closeOpts.push(...serviceList.filter(el => quickOptionsEnums['CANCELLABLE'].includes(el.service_status.toUpperCase())).map(el => {
            return {
                label: `Cancel ${el.service_details.service_type} ${(el.service_details.position) ? el.service_details.position.replace('N/A', '') : ''} ${(el.service_details.part) ? el.service_details.part.replace('N/A', '') : ''}`,
                payload: [{ serviceID: el.id }],
            };
        }));

        reworkOpts.push(...serviceList.filter(el => quickOptionsEnums['REWORKABLE'].includes(el.service_status.toUpperCase())).map(el => {
            return {
                label: `Request rework on ${el.service_details.service_type} ${(el.service_details.position) ? el.service_details.position.replace('N/A', '') : ''} ${(el.service_details.part) ? el.service_details.part.replace('N/A', '') : ''}`,
                serviceID: el.id,
                serviceDetailIDs: el.service_details.id,
                serviceDetails: `${el.service_details.service_type} ${(el.service_details.position) ? el.service_details.position.replace('N/A', '') : ''} ${(el.service_details.part) ? el.service_details.part.replace('N/A', '') : ''}`,
                payload: [{ serviceID: el.id }],
            };
        }));

        return (
            <Paper key={key} elevation={0} sx={{ backgroundColor: theme.palette.grey[50], width: '100%', p:1, my:2 }}>
                <Grid display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography sx={{ fontSize: 16, fontWeight: 400 }} color={theme.palette.grey[900]}> { rep } </Typography>
                    <Box display={'flex'} alignItems={'center'}>
                        <Typography sx={{ fontSize: 14, fontWeight: 400 }} color={theme.palette.grey[900]}> { new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total) } </Typography>
                        <IconButton
                            onClick={(event) => {setCurrentOpenMenu(key); handleClickMenu(event.currentTarget);}}
                        >
                            <MoreVertIcon sx={{ color: theme.palette.grey[500] }} />
                        </IconButton>
                        <Menu
                            id={`long-menu-${rep}`}
                            anchorEl={anchorElMenu}
                            open={Boolean(anchorElMenu) && currentOpenMenu === key}
                            onClose={handleCloseMenu}
                        >
                            {closeOpts.map((option, j) => (
                                <MenuItem key={j} onClick={() => handleCancelMenuItem(option)} sx={{ '&:hover': { backgroundColor: theme.palette.blue[30] } }}>
                                    { option.label }
                                </MenuItem>
                            ))}
                            {reworkOpts.map((option, j) => (
                                <div key={j}>
                                    <MenuItem key={j} onClick={() => handleReworkMenuItem(option)} sx={{ '&:hover': { backgroundColor: theme.palette.blue[30] } }}>
                                        {/* Set Up ReWork Modal */}
                                        {option.label}
                                    </MenuItem>
                                    { reworkModalOpen && (
                                        <ServiceReworkModal
                                            open={reworkModalOpen}
                                            setOpen={setRWModalOpen}
                                            serviceID={option.serviceID}
                                            serviceDetailIDs={`${option.serviceDetailIDs}`}
                                            serviceDetails={option.serviceDetails}
                                            serviceMedia={['serviceMedia']}
                                            serviceNotes={['serviceNotes']}
                                            repairCatalog={['repairCatalog']}
                                            DISPATCH={() => jobsContext.DISPATCH({ type: JOB_TYPES.SET_ACTIVE_JOB, payload: 0 })}
                                        />
                                    )}
                                </div>
                            ))}
                        </Menu>
                    </Box>
                </Grid>
                <Grid justifyContent={'space-evenly'} alignItems={'center'}>
                    {(() => {
                        return serviceList.map((service, i) => {
                            return <ServiceDetails
                                service={service}
                                key={i}
                                activeJob={activeJob}
                                rep={rep}
                            />;
                        });

                    })() }
                </Grid>
            </Paper>
        );
    });

    return (
        <Grid container display={'flex'} flexDirection={'column'} justifyContent={'center'} alignContent={'center'} px={2} my={1}>
            <Typography display={'flex'} alignSelf={'center'} my={1} sx={{ fontSize: 14, fontWeight: 500 }} color={theme.palette.grey[900]}> Services by Repairer </Typography>
            <Grid container display={'flex'} flexDirection={'row'} justifyContent={'center'} alignContent={'center'}>
                { repairerElements }
            </Grid>
        </Grid>
    );
};

RepairerCards.propTypes = {
    activeJob: PropTypes.object,
    quickOptionsEnums: PropTypes.object,
};

export default RepairerCards;
