import { ROUTES } from '../utils/enums';
import { sendRequest } from '../utils/api';

// MTODO: This is here to fill expected values not currently supported by API, remove when API support is added
const generateRepairer = (repairer) => {
    if (!repairer.id) {
        repairer.id = `${Math.random()}${Math.random()}${Math.random()}`;
    }

    // invited repairer
    // if (Math.random() > 0.8) {
    //     repairer.email = `invitedRep${Math.random().toPrecision(2).toString().substring(2, 4)}@rod.com`;
    //     repairer.registrationCode = Math.random().toPrecision(6).toString().substring(2, 10);
    //     return repairer;
    // }

    if (!repairer.name) {
        repairer.name = `Repairer shop ${Math.random().toPrecision(2).toString().substring(2, 4)}`;
    }

    if (!repairer.contact_info) {
        repairer['contact_info'] = {
            email: 'bartlett_auctions@hotmail.com',
            'phone_number': '17092823432',
        };
    }
    if (!repairer.services) {
        repairer.services = [
            'Mechanical',
            'Paint',
            'PDR',
            'Interior Repair',
        ];
    }
    if (!repairer.status) {
        // const valIndx = Math.floor(Math.random() * 4);
        const valIndx = 0;
        const statuses = ['Available', 'Unavailable', 'Pending', 'Inactive'];
        repairer.status = statuses[valIndx];
    }

    if (!repairer.locations) {
        // const valIndx = Math.floor(Math.random() * 4);
        // const locVals = [
        //     [{ name: 'Austin', active: Math.random() > 0.5 }],
        //     [{ name: 'Dallas', active: Math.random() > 0.5 }],
        //     [
        //         { name: 'Loc 1', active: Math.random() > 0.5 },
        //         { name: 'Loc 2', active: Math.random() > 0.5 },
        //     ],
        //     [
        //         { name: 'Loc 1', active: Math.random() > 0.5 },
        //         { name: 'Loc 2', active: Math.random() > 0.5 },
        //         { name: 'Loc 3', active: Math.random() > 0.5 },
        //         { name: 'Loc 4', active: Math.random() > 0.5 },
        //     ],
        // ];
        // repairer.locations = locVals[valIndx];
    }

    if (!repairer.notes) {
        // repairer.notes = Math.random() > 0.5 ? `Note ${Math.random().toPrecision(2).toString().substring(2, 4)}` : '';
    }

    return repairer;
};

const getRepairers = async function() {
    const response = await sendRequest(ROUTES.GET_CONNECTED_REPAIRERS);

    // MTODO: Remove when API fully supports repairers
    if (response && response.data.status === 200 && response.data.payload) {
        for (let i = 0; i < response.data.payload.length; i++) {
            const rep = response.data.payload[i];
            const formattedRep = generateRepairer(rep || {});
            response.data.payload[i] = formattedRep;
        }
    }

    return response;
};

export default {
    getRepairers,
};
