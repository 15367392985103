// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';

import { useTheme, Grid, Typography, FormHelperText } from '@mui/material';
import Switch from 'components/Inputs/Switch';

const SwitchInput = ({
    id,
    onChange,
    label,
    value,
    helperText = '',
    disabled = false,
    errorMsg = '',
}) => {
    const theme = useTheme();

    let userMessage = errorMsg?.length ? errorMsg : helperText;

    const helperTextElem = (
        <FormHelperText sx={{ backgroundColor: theme.palette.white, height: '100%', position: 'relative', top: '-3px' }}>
            {userMessage}
        </FormHelperText>
    );

    return (
        <Grid item display='flex' justifyContent={'center'} p={0} m={0} mt={1} minHeight='60px'>
            <Grid container direction='column' sx={{ width: '80%' }}>
                <Grid
                    container
                    item
                    direction='row'
                    sx={{ width: '100%', height: '40px', minHeight: '40px', pl: 2, borderBottom: `1px solid ${theme.palette.grey[100]}` }}
                >
                    <Grid item sm={6}>
                        <Typography
                            sx={{
                                color: theme.palette.grey[500],
                                fontSize: '16px',
                                fontWeight: 400,
                                lineHeight: '40px',
                                overflow: 'visible',
                            }}
                            htmlFor={id}
                        >
                            { label }
                        </Typography>
                    </Grid>
                    <Grid container direction='row-reverse' item sm={6}>
                        <Switch value={value} onChange={onChange} disabled={disabled}></Switch>
                    </Grid>
                    <Grid item>
                        {helperTextElem}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

SwitchInput.propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.bool,
    helperText: PropTypes.string,
    disabled: PropTypes.bool,
    errorMsg: PropTypes.string,
};

export default SwitchInput;