import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import LocationReducer, { INITIAL_STATE, INIT } from './reducer';
import * as actions from './actions';

const LocationContext = createContext({
    DISPATCH: null,
    state: {},
    actions: {},
});

export const LocationProvider = ({ children }) => {
    const [state, dispatch] = useReducer(LocationReducer, { ...INITIAL_STATE }, INIT);

    const value = {
        state,
        // @ts-ignore
        DISPATCH: (data) => { dispatch(data); },
        actions: {
            getLocations: actions.getLocations,
            refreshLocations: actions.refreshLocations(dispatch),
            refreshLocation: actions.refreshLocation(dispatch),
            setActiveLocation: actions.setActiveLocation(dispatch),
            createLocation: actions.createLocation(dispatch),
            updateLocation: actions.updateLocation(dispatch),
            validateActiveLocation: actions.validateActiveLocation(dispatch),
            validateNewLocation: actions.validateNewLocation(dispatch),
        },
    };

    return (
        <LocationContext.Provider value={value}> { children } </LocationContext.Provider>
    );
};

LocationProvider.propTypes = {
    children: PropTypes.node,
};

export const useLocations = () => useContext(LocationContext);
