import React, { useState } from 'react';

import { Box, styled, IconButton, ClickAwayListener, Popper, Button, Badge } from '@mui/material';
import { NotificationsNone as NotificationsIcon, ChatBubbleOutline as ChatIcon, PersonOutline as PersonIcon } from '@mui/icons-material';

import { useNotifications } from 'services/notification';

import { useAuth } from 'contexts/AuthContext';
import { ReactComponent as ChatWIP } from 'assets/images/placeholders/ChatWIP.svg';

import NotificationPopup from './Notifications';

const IconsContainer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: 0,
    gap: '16px',
    width: '182px',
    height: '56px',
    flex: 'none',
    flexGrow: 0,
}));

const HeaderButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: theme.palette.grey[100],
    '& .MuiSvgIcon-root': {
        color: theme.palette.grey[800],
    },
}));

const StyledPopper = styled(Popper)(({ theme }) => ({
    boxShadow: theme.shadows[3],
    borderRadius: '8px',
    padding: '8px',
    zIndex: theme.zIndex.modal,
    fontSize: '12px',
    backgroundColor: 'white',
    marginTop: '10px !important',
}));

const HeaderIcons = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openedPopper, setOpenedPopper] = useState(null);
    const auth = useAuth();
    const notifContext = useNotifications();

    const unreadNotifsCount = (notifContext.state?.notifications || []).filter((notif) => !notif.read).length;
    const unreadNotifsBadgeContent = unreadNotifsCount < 1 ? null : (unreadNotifsCount > 9 ? '9+' : unreadNotifsCount);

    const notifIconElem = unreadNotifsBadgeContent ? (
        <Badge color='secondary' badgeContent={unreadNotifsBadgeContent} showZero={false} sx={{
            '& .MuiBadge-badge': {
                right: -3,
                top: -3,
            },
        }}>
            <NotificationsIcon />
        </Badge>
    ) : (
        <NotificationsIcon />
    );

    const headerPoppers = [
        {
            id: 'notifications',
            element: <NotificationPopup />,
            iconElement: notifIconElem,
        },
        {
            id: 'chat',
            element: <ChatWIP width={'100%'} height={'100%'} style={{ padding: 0, margin: 0 }} />,
            iconElement: <ChatIcon />,
        },
        {
            id: 'user',
            element: (
                <Button onClick={((e) => {
                    console.log('auth:', auth);
                    auth.logout();
                })}>
                    Logout
                </Button>
            ),
            iconElement: <PersonIcon />,
        },
    ];

    return (
        <IconsContainer>
            {headerPoppers.map((popper) => (
                <React.Fragment key={popper.id}>
                    <HeaderButton onClick={(e) => {
                        if (openedPopper === popper.id) {
                            setAnchorEl(null);
                            setOpenedPopper(null);
                            return;
                        }

                        setAnchorEl(e.currentTarget);
                        setOpenedPopper(popper.id);
                    }}>
                        {popper.iconElement}
                    </HeaderButton>
                    <StyledPopper
                        open={openedPopper === popper.id}
                        anchorEl={anchorEl}
                        placement='bottom-end'
                    >
                        <ClickAwayListener onClickAway={() => {
                            setAnchorEl(null);
                            setOpenedPopper(null);
                        }}>
                            <div>
                                {popper.element}
                            </div>
                        </ClickAwayListener>
                    </StyledPopper>
                </React.Fragment>
            ))}
        </IconsContainer>
    );
};

export default HeaderIcons;
