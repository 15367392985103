
import React from 'react';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

export default function DrawerItem({
    route,
    title,
    onClick,
    iconComponent,
}) {
    const theme = useTheme();
    const IconComponent = iconComponent;

    return (
        <MenuItem sx={{ backgroundColor: 'white', p: 1, borderRadius: '8px', border: '1px solid #E2E8EE' }} onClick={() => onClick(route)}>
            <ListItemIcon>
                <IconComponent sx={{ color: theme.palette.blue[700] }} fontSize='small' />
            </ListItemIcon>
            <ListItemText>{title}</ListItemText>
        </MenuItem>
    );
}

DrawerItem.propTypes = {
    route: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func,
    iconComponent: PropTypes.any,
};