import { apiRodx } from '../../config';

export const ROUTES = {
    GET_ALL_JOBS: {
        path: '/jobs',
        method: 'get',
    },
    GET_JOB: {
        path: '/job',
        method: 'get',
    },
    CREATE_JOB: {
        path: '/job',
        method: 'post',
    },
    UPDATE_JOB: {
        path: '/job',
        method: 'patch',
    },
    DELETE_JOB: {
        path: '/job',
        method: 'delete',
    },
    GET_ALL_LOCATIONS: {
        path: '/locations',
        method: 'get',
    },
    GET_LOCATION: {
        path: '/location',
        method: 'get',
    },
    CREATE_LOCATION: {
        path: '/location',
        method: 'post',
    },
    UPDATE_LOCATION: {
        path: '/location',
        method: 'patch',
    },
    GET_CONNECTED_REPAIRERS: {
        path: '/company/connections',
        method: 'get',
    },
    GET_COMPANY: {
        path: '/company',
        method: 'get',
    },
    UPDATE_COMPANY: {
        path: '/company',
        method: 'patch',
    },
    GET_NOTIFICATIONS: {
        path: '/notification/getAll',
        method: 'get',
    },
    MARK_NOTIFICATION_AS_READ: {
        path: '/notification/markAsRead',
        method: 'get',
    },
};

export const SERVICES = [
    {
        id: 1,
        name: 'Bodywork',
    },
    {
        id: 2,
        name: 'Calibration',
    },
    {
        id: 3,
        name: 'De-Identification',
    },
    {
        id: 4,
        name: 'Detail',
    },
    {
        id: 5,
        name: 'Diagnostic',
    },
    {
        id: 6,
        name: 'Glass Repair',
    },
    {
        id: 7,
        name: 'Glass Replacement',
    },
    {
        id: 8,
        name: 'Inspection',
    },
    {
        id: 9,
        name: 'Interior Repair',
    },
    {
        id: 10,
        name: 'Key Replacement',
    },
    {
        id: 11,
        name: 'Mechanical',
    },
    {
        id: 12,
        name: 'Paint',
    },
    {
        id: 13,
        name: 'PDR',
    },
    {
        id: 14,
        name: 'PII Removal',
    },
    {
        id: 15,
        name: 'PPF',
    },
    {
        id: 16,
        name: 'Remove and Install',
    },
    {
        id: 17,
        name: 'Remove and Replace',
    },
    {
        id: 18,
        name: 'Rim Repair',
    },
    {
        id: 19,
        name: 'Tint',
    },
    {
        id: 20,
        name: 'Vinyl Wrap',
    },
];

export const STATUSES = [
    {
        id: 0,
        name: 'OPEN',
        status: 'open',
    },
    {
        id: 1,
        name: 'ACCEPTED',
        status: 'accepted',
    },
    {
        id: 2,
        name: 'PENDING_APPROVAL',
        status: 'pending approval',
    },
    {
        id: 3,
        name: 'DECLINED',
        status: 'declined',
    },
    {
        id: 4,
        name: 'IN_PROGRESS',
        status: 'in progress',
    },
    {
        id: 5,
        name: 'COMPLETED',
        status: 'completed',
    },
    {
        id: 6,
        name: 'INVOICED',
        status: 'invoiced',
    },
    {
        id: 7,
        name: 'CLOSED',
        status: 'closed',
    },
    {
        id: 8,
        name: 'CANCELLED',
        status: 'cancelled',
    },
];

export const STATUS_BAR_CLASS = {
    'Open': 'OPEN',
    'Pending': 'PENDING',
    'Declined': 'DECLINED',
    'Pending Approval': 'PENDING',
    'In Progress': 'IN_PROGRESS',
    'Accepted': 'ACCEPTED',
    'Cancelled': 'CANCELLED',
    'Completed': 'COMPLETED',
    'Closed': 'CLOSED',
    'Invoiced': 'INVOICED',
};
export const STATUS_BAR_DISPLAY = {
    'Open': 'Open',
    'Pending': 'Pending',
    'Declined': 'Declined',
    'Pending Approval': 'Pending',
    'In Progress': 'In Progress',
    'Accepted': 'Accepted',
    'Cancelled': 'Cancelled',
    'Completed': 'Completed',
    'Closed': 'Closed',
    'Invoiced': 'Invoiced',
};

export const getColors = async function(params) {
    const result = [
        'Beige',
        'Black',
        'Blue',
        'Brown',
        'Gold',
        'Gray',
        'Green',
        'Orange',
        'Purple',
        'Red',
        'Silver',
        'White',
        'Yellow',
    ];
    return result;
};

export const gatherStaticData = async function(params) {
    let config = params;
    const result = await apiRodx['get']('enums');
    return result;
};

export const gatherCatalog = async function(params) {
    let config = params;
    const result = await apiRodx['get']('catalog');
    return result;
};

export const getCatalogVersion = async function(params) {
    let config = params;
    const result = await apiRodx['get']('catalog/version');
    return result;
};
