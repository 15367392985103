// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';

import { useTheme, Tooltip, Grid, TextField, FormControl, Autocomplete, inputLabelClasses, FormHelperText } from '@mui/material';

// import TextField from '../Inputs/TextField';

const DropDownInput = ({
    id, onChange, label, value = '', helperText = '', disabled, errorMsg, options, getOptionLabel, allowTyping = true,
}) => {
    const theme = useTheme();
    const [inputValue, setInputValue] = React.useState('');
    const backgroundColor = value ? theme.palette.blue[30] : theme.palette.white;

    let userMessage = errorMsg?.length ? errorMsg : helperText;

    const helperTextElem = (
        <Tooltip title={userMessage}>
            <FormHelperText
                sx={{
                    backgroundColor: theme.palette.white,
                    height: '20px',
                    pl: 2,
                    textWrap: 'nowrap',
                    overflowX: 'clip',
                    overflowY: 'visible',
                    position: 'relative',
                    top: '-3px',
                    minHeight: '16px',
                }}
            >
                {userMessage}
            </FormHelperText>
        </Tooltip>
    );

    return (
        <Grid item display='flex' justifyContent={'center'} p={0} m={0} sx={{ minHeight: '80px' }}>
            <FormControl
                sx={{
                    m: 0.5,
                    p: 0,
                    pt: .9,
                    width: '80%',
                    backgroundColor,
                    maxHeight: '68px',
                }}
                variant={'standard'}
                error={errorMsg?.length > 0}
            >
                <Autocomplete
                    id={id}
                    options={options}
                    getOptionLabel={getOptionLabel}
                    sx={{
                        borderRadius: 0,
                        border: 0,
                        borderBottom: `1px solid ${theme.palette.grey[400]}`,
                    }}
                    ListboxProps={{ sx: { fontSize: 14, padding: 1 } }}
                    freeSolo={false}
                    disabled={disabled}
                    autoComplete={true}
                    onChange={onChange}
                    value={value}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            type='text'
                            value={inputValue}
                            readOnly
                            onChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            variant={'standard'}
                            label={label}
                            sx={{
                                pl: 1.7,
                                caretColor: allowTyping ? 'auto' : 'transparent',
                                [`& .${inputLabelClasses.root}`]: {
                                    pl: 2.3,
                                    pt: 0.6,
                                },
                                [`& .${inputLabelClasses.shrink}`]: {
                                    fontSize: '12px !important',
                                },
                            }}
                            InputProps = {{
                                ...params.InputProps,
                                style: {
                                    fontSize: '16px',
                                    textTransform: 'none',
                                },
                            }}
                            inputProps={{
                                ...params.inputProps,
                                readOnly: true,
                                sx: { cursor: allowTyping ? 'auto' : 'pointer' },
                            }}
                            InputLabelProps={{
                                ...params.InputLabelProps,
                                style: {
                                    width: '100%',
                                    lineHeight: 1,
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    color: theme.palette.grey[500],
                                },
                            }}
                        />
                    )}
                />
                {helperTextElem}
            </FormControl>
        </Grid>
    );
};

DropDownInput.propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    helperText: PropTypes.string,
    disabled: PropTypes.bool,
    errorMsg: PropTypes.string,
    options: PropTypes.array,
    getOptionLabel: PropTypes.func,
    allowTyping: PropTypes.bool,
};

export default DropDownInput;
