// React
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
// Local
import { ReactComponent as Dash1WIP } from '../../assets/images/placeholders/Dash1WIP.svg';
import Dash2WIP from '../../assets/images/placeholders/DashWIP.png';
import { useAuth } from 'contexts/AuthContext';
// Styles
import { Box, Grid, Typography, styled, useTheme } from '@mui/material';

const ContentContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    boxShadow: theme.shadows[1],
    padding: theme.spacing(2),
    width: '100%',
}));

const DashboardScreen = () => {
    console.log('DashboardScreen');

    // app context
    const theme = useTheme();

    return (
        <Grid>
            <Typography
                display={'flex'}
                justifyContent={'center'}
                sx={{ fontSize: 24, fontWeight: 500, color: theme.palette.grey[800] }}
                m={0}
                mb={1}
                p={0}
                pb={1}>
                Repair Exchange Dashboard </Typography>
            <ContentContainer>
                <Grid container direction={'column'} display={'flex'} alignItems={'center'}>
                    <Dash1WIP width={'70%'} height={'70%'} />
                    <Typography variant={'h3'} color={theme.palette.blue[500]} p={1} my={1}>
                        These are just placeholders and do not represent finished Dashboard designs. </Typography>
                    <img width={'80%'} height={'80%'} src={Dash2WIP} />
                </Grid>
            </ContentContainer>
        </Grid>
    );
};

export default DashboardScreen;