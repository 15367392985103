
import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/Modal';
import StatusIndicator from 'components/Icons/StatusIndicator';
import MultiAccordionContainer from 'components/Containers/Accordion/MultiAccordionContainer';

import RepairerDetailsForm from './RepairerDetailsForm';
import RepairerSavedRatesForm from './RepairerSavedRatesForm';

const RepairerModal = ({ open, repairer, handlePropChange, onClose })=> {
    const accordions = [
        {
            summary: 'DETAILS',
            content: (<RepairerDetailsForm repairer={repairer} handlePropChange={handlePropChange} />),
        },
        {
            summary: 'SAVED RATES',
            content: (<RepairerSavedRatesForm rates={[]} handleRateChange={() => {}} />), // MTODO: pass correct params
        },
    ];

    return (
        <Modal
            open={open}
            title={repairer.name}
            subTitle={<StatusIndicator active={repairer.status === 'Available'} status={repairer.status} />}
            onClose={ () => {
                onClose();
            }}
            onSubmit={() => {}}
            showCloseButton
            showSubmitButton={false}
            submitButtonDisabled={true}
            submitButtonTooltip={null}
        >
            <MultiAccordionContainer
                accordions={accordions}
                multiExpand={false}
                defaultState={{ 'DETAILS': true }}
            />
        </Modal>
    );
};

RepairerModal.propTypes = {
    open: PropTypes.bool.isRequired,
    repairer: PropTypes.object.isRequired,
    handlePropChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
};

export default RepairerModal;
