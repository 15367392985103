import TYPES from './types';

export const INITIAL_STATE = {
    notifications: [],
};

export const INIT = function () {
    return { ...INITIAL_STATE };
};

const NotificationReducer = function (state, action) {
    switch (action.type) {
        case TYPES.INIT: {
            return { ...INITIAL_STATE };
        }
        case TYPES.SET_NOTIFICATIONS: {
            return { ...state, notifications: action.payload.map((notif) => formatNotificationIntoState(notif)) };
        }
        case TYPES.UPDATE_NOTIFICATION: {
            const { id, read } = action.payload;

            const newNotifs = state.notifications.map((notif) => {
                if (notif.id === id) {
                    return { ...notif, read };
                }
                return { ...notif };
            });

            return { ...state, notifications: newNotifs };
        }
        default: {
            return { ...state };
        }
    }
};

const formatNotificationIntoState = (notification) => {
    const formattedNotification = { ...notification };
    return formattedNotification;
};

export default NotificationReducer;
