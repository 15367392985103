
// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Button, Tooltip } from '@mui/material';

const FormContainer = ({
    children,
    showCancelBtn = false,
    showSubmitBtn = false,
    cancelBtnDisabled = false,
    submitBtnDisabled = false,
    onCancel = () => {},
    onSubmit = () => {},
    submitBtnTooltip = '',
    submitButtonText = 'Save',
    SubmitButtonIcon = null,
    submitButtonType = 'contained',
    cancelButtonText = 'Cancel',
    CancelButtonIcon = null,
    cancelButtonType = 'outlined',
}) => {
    const btnsElem = (showCancelBtn || showSubmitBtn) ? (
        <Grid container direction='row' sx={{ width: '80%', alignSelf: 'center', py: 1 }}>
            { showCancelBtn ? (
                <Grid item sm={6} sx={{ pr: 1 }}>
                    <Button
                        variant={cancelButtonType}
                        onClick={onCancel}
                        disabled={cancelBtnDisabled}
                        sx={{ width: '100%', height: '40px', borderRadius: '8px' }}
                        startIcon={CancelButtonIcon ? (<CancelButtonIcon />) : null}
                    >{cancelButtonText}</Button>
                </Grid>
            ) : null}
            { showSubmitBtn ? (
                <Grid item sm={6} sx={{ pl: 1 }}>
                    <Tooltip title={submitBtnTooltip}>
                        {/* Do Not Remove: This div is here to trigger the tooltip when the button is disabled */}
                        <div style={{ width: '100%' }}>
                            <Button
                                variant={submitButtonType}
                                onClick={onSubmit}
                                disabled={submitBtnDisabled}
                                sx={{ width: '100%', height: '40px', borderRadius: '8px' }}
                                startIcon={SubmitButtonIcon ? (<SubmitButtonIcon />) : null}
                            >{submitButtonText}</Button>
                        </div>
                    </Tooltip>
                </Grid>
            ) : null}
        </Grid>
    ) : null;

    return (
        <Grid
            container
            flexDirection={'column'}
            display={'flex'}
            sx={{ width: '100%', px: 2 }}
            justifyContent={'space-between'}
            my={1}
            pb={2}
        >
            {children}
            {btnsElem}
        </Grid>
    );
};

FormContainer.propTypes = {
    children: PropTypes.any,
    showCancelBtn: PropTypes.bool,
    showSubmitBtn: PropTypes.bool,
    cancelBtnDisabled: PropTypes.bool,
    submitBtnDisabled: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    submitBtnTooltip: PropTypes.string,
    submitButtonText: PropTypes.string,
    SubmitButtonIcon: PropTypes.any,
    submitButtonType: PropTypes.string,
    cancelButtonText: PropTypes.string,
    CancelButtonIcon: PropTypes.any,
    cancelButtonType: PropTypes.string,
};

export default FormContainer;
