export default {
    SET_SEARCH_TERM: 'set_search',
    RESET_SEARCH: 'reset_search',
    SET_START_DATE: 'set_start_date',
    SET_END_DATE: 'set_end_date',
    LOAD_LOCATIONS: 'load_locations',
    LOAD_REPAIRERS: 'load_repairers',
    SELECT_LOCATIONS: 'select_locations',
    GET_ROS: 'get_repairOrders',
    GET_JOBS: 'get_jobs',
    SET_ACTIVE_JOB: 'SET_ACTIVE_JOB',
    SET_COUNT: 'set_count',
    RESET: 'reset',
    LOADING: 'LOADING',
    REFRESH_JOB: 'refresh_job',
    UPDATE_FILTERS: 'UPDATE_FILTERS',
    UPDATE_QUICK_FILTERS: 'UPDATE_QUICK_FILTERS',
    UPDATE_SORT: 'UPDATE_SORT',
    CLEAR_FILTERS_SEARCH_SORT: 'CLEAR_FILTERS_SEARCH_SORT',
};