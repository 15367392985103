import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import * as actions from './actions';
import NotificationReducer, { INITIAL_STATE, INIT } from './reducer';

const NotificationContext = createContext({
    DISPATCH: null,
    state: {},
    actions: {},
});

export const NotificationsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(NotificationReducer, { ...INITIAL_STATE }, INIT);

    const value = {
        state,
        // @ts-ignore
        DISPATCH: (data) => { dispatch(data); },
        actions: {
            getNotifications: actions.getNotifications,
            refreshNotifications: actions.refreshNotifications(dispatch),
            markNotificationAsRead: actions.markNotificationAsRead(dispatch),
        },
    };

    return (
        <NotificationContext.Provider value={value}> { children } </NotificationContext.Provider>
    );
};

NotificationsProvider.propTypes = {
    children: PropTypes.node,
};

export const useNotifications = () => useContext(NotificationContext);
