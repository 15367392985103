const DB_NAME = 'supplier-portal';

const stores = [
    {
        name: 'company',
        keyPath: 'id',
    },
];

export const connect = async function() {
    return new Promise((resolve, reject) => {
        const request = typeof window === 'undefined' ? indexedDB.open(DB_NAME) : window.indexedDB.open(DB_NAME);

        request.onerror = function(event) {
            console.error('IndexedDB: Error while opening indexedDB:', event);
            reject(event);
        };

        request.onsuccess = function(event) {
            resolve(request.result);
        };

        request.onupgradeneeded = function(event) {
            const db = request.result;

            switch(event.oldVersion) {
                case 0:
                    // version 0 means that the client had no database
                    // perform initialization
                    for (const store of stores) {
                        db.createObjectStore(store.name, { keyPath: store.keyPath });
                    }
                    break;
                case 1:
                    // client had version 1
                    // update
                    break;
            }
        };
    });
};

export const save = async function(storeName, data) {
    console.log('IndexedDB: Saving data to store:', storeName, 'data:', data);
    const db = await connect();

    return new Promise((resolve, reject) => {
        const transaction = db.transaction(storeName, 'readwrite');
        const objectStore = transaction.objectStore(storeName);
        const request = objectStore.put(data);

        request.onerror = function(event) {
            console.error('IndexedDB: Error while saving data:', event);
            reject(event);
        };

        request.onsuccess = function(event) {
            console.log('IndexedDB: Data saved successfully, store:', storeName, 'data:', data);
            resolve(request.result);
        };
    });
};

export const get = async function(storeName, id) {
    console.log('IndexedDB: Getting data from store:', storeName, 'id:', id);
    const db = await connect();

    return new Promise((resolve, reject) => {
        const transaction = db.transaction(storeName, 'readonly');
        const objectStore = transaction.objectStore(storeName);
        const request = objectStore.get(id);

        request.onerror = function(event) {
            console.error('IndexedDB: Error while getting data:', event);
            reject(event);
        };

        request.onsuccess = function(event) {
            console.log('IndexedDB: Data retrieved successfully, store:', storeName, 'data:', request.result);
            resolve(request.result);
        };
    });
};

export const getAll = async function(storeName) {
    console.log('IndexedDB: Getting all data from store:', storeName);
    const db = await connect();

    return new Promise((resolve, reject) => {
        const transaction = db.transaction(storeName, 'readonly');
        const objectStore = transaction.objectStore(storeName);
        const request = objectStore.getAll();

        request.onerror = function(event) {
            console.error('IndexedDB: Error while getting data:', event);
            reject(event);
        };

        request.onsuccess = function(event) {
            console.log('IndexedDB: Data retrieved successfully, store:', storeName, 'data:', request.result);
            resolve(request.result);
        };
    });
};

export const remove = async function(storeName, id) {
    console.log('IndexedDB: Removing data from store:', storeName, 'id:', id);
    const db = await connect();

    return new Promise((resolve, reject) => {
        const transaction = db.transaction(storeName, 'readwrite');
        const objectStore = transaction.objectStore(storeName);
        const request = objectStore.delete(id);

        request.onerror = function(event) {
            console.error('IndexedDB: Error while removing data:', event);
            reject(event);
        };

        request.onsuccess = function(event) {
            console.log('IndexedDB: Data removed successfully, store:', storeName, 'data:', request.result);
            resolve(request.result);
        };
    });
};

export const clear = async function(storeName) {
    console.log('IndexedDB: Clearing store:', storeName);
    const db = await connect();

    return new Promise((resolve, reject) => {
        const transaction = db.transaction(storeName, 'readwrite');
        const objectStore = transaction.objectStore(storeName);
        const request = objectStore.clear();

        request.onerror = function(event) {
            console.error('IndexedDB: Error while clearing store:', event);
            reject(event);
        };

        request.onsuccess = function(event) {
            console.log('IndexedDB: Store cleared successfully, store:', storeName);
            resolve(request.result);
        };
    });
};

export default {
    connect,
    save,
    get,
    getAll,
    remove,
    clear,
};