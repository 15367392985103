
import React from 'react';
import PropTypes from 'prop-types';

import { Box, Button, Grid, Stack, Typography, Modal as MuiModal, Fade, Backdrop, useTheme, IconButton, Tooltip } from '@mui/material';

import { ReactComponent as CheckCircleIcon } from 'assets/images/check_circle_outline.svg';
import { ReactComponent as CloseIcon } from 'assets/images/navigationClose.svg';

const Modal = ({
    open,
    children,
    title,
    subTitle,
    onClose,
    onSubmit,
    showCloseButton= true,
    showSubmitButton = false,
    submitButtonDisabled = false,
    submitButtonTooltip = '',
    success = false,
    showYesNoButtons = false,
    yesButtonText = 'Yes',
    noButtonText = 'No',
    width= '40%',
    fullScreen = false,
})=> {
    const theme = useTheme();

    const successElem = success ? (
        <Grid container direction='column' sx={{ px: 15.5, py: 5.5 }} alignItems='center' textAlign='center'>
            <CheckCircleIcon />
            <Typography sx={{ color: theme.palette.grey[900], fontSize: 24, fontWeight: 400, lineHeight: 'normal', mt: 2 }}>
                Success!
            </Typography>
        </Grid>
    ) : null;

    const closeBtnElem = showCloseButton ? (
        <IconButton
            onClick={onClose}
            sx={{
                display: 'flex',
                padding: '10px',
                alignItems: 'flex-start',
                gap: '10px',
                position: 'absolute',
                left: '16px',
                top: '16px',
                borderRadius: '100px',
                background: theme.palette.grey[30],
            }}
        >
            <CloseIcon />
        </IconButton>
    ) : null;

    let modalButtonsElem = showSubmitButton ? (
        <Grid item display='flex' justifyContent={'center'} p={1} m={1} sx={{ width: '100%', mx: 0, px: 0 }}>
            <Tooltip title={submitButtonTooltip}>
                {/* Do Not Remove: This div is here to trigger the tooltip when the button is disabled */}
                <div style={{ width: '100%' }}>
                    <Button
                        variant='contained'
                        onClick={onSubmit}
                        disabled={submitButtonDisabled}
                        sx={{
                            backgroundColor: theme.palette.blue[700],
                            color: theme.palette.white,
                            p: 2,
                            width: '100%',
                            textTransform: 'none',
                        }}
                    >
                            Submit
                    </Button>
                </div>
            </Tooltip>
        </Grid>
    ) : null;

    if (showYesNoButtons) {
        modalButtonsElem = (
            <Stack direction='row' display='flex' justifyContent={'center'} spacing={1} p={1} m={1} sx={{ width: '100%', mx: 0, px: 0 }}>
                <Tooltip title={noButtonText}>
                    {/* Do Not Remove: This div is here to trigger the tooltip when the button is disabled */}
                    <div style={{ width: '100%' }}>
                        <Button
                            variant='outlined'
                            onClick={onClose}
                            sx={{
                                p: 1,
                                width: '100%',
                                textTransform: 'none',
                            }}
                        >
                            {noButtonText}
                        </Button>
                    </div>
                </Tooltip>
                <Tooltip title={submitButtonTooltip}>
                    {/* Do Not Remove: This div is here to trigger the tooltip when the button is disabled */}
                    <div style={{ width: '100%' }}>
                        <Button
                            variant='outlined'
                            onClick={onSubmit}
                            disabled={submitButtonDisabled}
                            sx={{
                                p: 1,
                                width: '100%',
                                textTransform: 'none',
                            }}
                        >
                            {yesButtonText}
                        </Button>
                    </div>
                </Tooltip>
            </Stack>
        );
    }

    const titleElem = !title ? null : (
        typeof title === 'string' ? (
            <Typography sx={{ color: theme.palette.grey[900], fontSize: 24, fontWeight: 400, lineHeight: 'normal' }}>
                {title}
            </Typography>
        ) : title
    );

    const subTitleElem = !subTitle ? null : (
        typeof subTitle === 'string' ? (
            <Typography sx={{ color: theme.palette.grey[400], fontSize: 16, fontWeight: 400, lineHeight: 'normal', mt: 1 }}>
                {subTitle}
            </Typography>
        ) : subTitle
    );

    const headerElem = title ? (
        <Grid item container direction='column' justifyContent='center' alignItems='center' textAlign='center' my={3}>
            <Grid item>
                {titleElem}
            </Grid>
            <Grid item sx={{ mt: 1 }}>
                {subTitleElem}
            </Grid>
        </Grid>
    ) : null;

    const content = success ? successElem : (
        <>
            {headerElem}
            {children}
            {modalButtonsElem}
        </>
    );

    return (
        <MuiModal
            open={open}
            onClose={onClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 300,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    maxWidth: fullScreen ? '99%' : width,
                    height: fullScreen ? '99%' : null,
                    maxHeight: '100% !important',
                    overflow: 'hidden',
                    backgroundColor: theme.palette.white,
                    border: '0',
                    borderRadius: '16px',
                    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
                    display: 'flex',
                    padding: '24px 100px 44px 100px',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '44px',
                }}>
                    {closeBtnElem}
                    <Grid item width='100%'>
                        {content}
                    </Grid>
                </Box>
            </Fade>
        </MuiModal>
    );
};

Modal.propTypes = {
    open: PropTypes.bool.isRequired,
    children: PropTypes.node,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    showCloseButton: PropTypes.bool,
    showSubmitButton: PropTypes.bool,
    submitButtonDisabled: PropTypes.bool,
    submitButtonTooltip: PropTypes.string,
    success: PropTypes.bool,
    showYesNoButtons: PropTypes.bool,
    yesButtonText: PropTypes.string,
    noButtonText: PropTypes.string,
    width: PropTypes.string,
    fullScreen: PropTypes.bool,
};

export default Modal;
