import React from 'react';
import PropTypes from 'prop-types';

import { useTheme, Grid, Typography } from '@mui/material';

const FormLabel = ({
    label,
    value,
    fullBorder = true,
}) => {
    const theme = useTheme();

    return (
        <Grid item display='flex' justifyContent={'center'} p={0} m={0} my={1} minHeight='60px' maxHeight='60px'>
            <Grid container direction='column' sx={{ width: '80%' }}>
                <Grid
                    container
                    item
                    direction='row'
                    sx={{
                        width: '100%',
                        minHeight: '40px',
                        pl: 2,
                        borderBottom: `1px solid ${theme.palette.grey[100]}`,
                        border: fullBorder ? `1px solid ${theme.palette.grey[100]}` : null,
                        borderRadius: fullBorder ? '4px' : '0px',
                    }}
                >
                    <Grid item sm={6}>
                        <Typography
                            sx={{
                                color: theme.palette.grey[500],
                                fontSize: '16px',
                                fontWeight: 400,
                                lineHeight: '60px',
                                overflow: 'visible',
                            }}
                        >
                            { label }
                        </Typography>
                    </Grid>
                    <Grid container direction='row-reverse' item sm={6}>
                        <Typography
                            sx={{
                                color: theme.palette.grey[500],
                                fontSize: '16px',
                                fontWeight: 400,
                                lineHeight: '60px',
                                overflow: 'visible',
                                pr: 2,
                            }}
                        >
                            { value }
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

FormLabel.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.string,
    fullBorder: PropTypes.bool,
};

export default FormLabel;
