import { STATUSES } from 'services/utils/enums';
import TYPES from './types';

export const INIT = function () {
    return INITIAL_STATE;
};

const defaultFilters = {
    location: [
        {
            id:'location1',
            name: 'location 1',
            selected: false,
        },
        {
            id:'location2',
            name: 'location 2',
            selected: false,
        },
        {
            id:'location3',
            name: 'location 3',
            selected: false,
        },
    ],
    repairers: [
        {
            id:'repairer1',
            name: 'Repairer 1',
            selected: false,
        },
        {
            id:'repairer2',
            name: 'Repairer 2',
            selected: false,
        },
        {
            id:'repairer3',
            name: 'Repairer 3',
            selected: false,
        },
    ],
    services: [
        {
            id:'service1',
            name: 'service Type 1',
            selected: false,
        },
        {
            id:'service2',
            name: 'service Type 2',
            selected: false,
        },
        {
            id:'service3',
            name: 'service Type 3',
            selected: false,
        },
    ],
};

export const INITIAL_STATE = {
    startDate: new Date().getTime(),
    endDate: new Date().getTime(),
    locations: [],
    selectedLocations: [],
    count: {
        'num_jobs': null,
        'num_jobs_containing_status': {
            'Open': 0,
            'Rework': 0,
            'Accepted': 0,
            'Declined': 0,
            'Invoiced': 0,
            'Cancelled': 0,
            'Completed': 0,
            'In Progress': 0,
            'Pending Approval': 0,
        },
    },
    repairers: [],
    selectedRepairers: [],
    statuses: STATUSES,
    selectedStatuses: [],
    supplements: [
        {
            id: 1,
            name: 'Supplement Approved',
        },
        {
            id: 2,
            name: 'Supplement Pending',
        },
        {
            id: 3,
            name: 'Rework Approved',
        },
        {
            id: 4,
            name: 'Rework Pending',
        },
    ],
    selectedServices: [],
    jobs:[],
    activeJob: 0,
    repairOrders:[],
    activeRepairOrder: 0,
    repairOrder: {},
    loaded: false,
    searchTerm: '',
    filters: defaultFilters,
    quickFilters: {
        'service_statuses': null,
    },
    sort: 'desc',
};

export const JobReducer = function (state, action) {
    switch (action.type) {
        case TYPES.SET_SEARCH_TERM: {
            return { ...state, searchTerm: action.payload };
        }
        case TYPES.SET_START_DATE: {
            return { ...state, startDate: action.payload };
        }
        case TYPES.SET_END_DATE: {
            return { ...state, startDate: action.payload };
        }
        case TYPES.LOAD_LOCATIONS: {
            return { ...state, locations: action.payload };
        }
        case TYPES.LOAD_REPAIRERS: {
            return { ...state, repairers: action.payload };
        }
        case TYPES.SELECT_LOCATIONS: {
            return { ...state, selectedLocations: action.payload };
        }
        case TYPES.GET_JOBS: {
            return {
                ...state,
                job: {},
                jobs: action.payload,
                loaded: true,
            };
        }
        case TYPES.REFRESH_JOB: {
            let found = false;
            const newJobs = state.jobs.map(job => {
                if (job.id === action.payload.id) {
                    found = true;
                    return action.payload;
                }
                return { ...job };
            });

            if (!found) {
                newJobs.push(action.payload);
            }

            return {
                ...state,
                jobs: newJobs,
            };
        }
        case TYPES.GET_ROS: {
            return { ...state, repairOrders: action.payload };
        }
        case TYPES.SET_ACTIVE_JOB: {
            return { ...state, activeJob: action.payload };
        }
        case TYPES.SET_COUNT: {
            return { ...state, count: action.payload };
        }
        case TYPES.LOADING: {
            return { ...state, loaded: !action.payload };
        }
        case TYPES.UPDATE_FILTERS: {
            const { propName, selectedOptions } = action.payload;
            const newFilters = { ...state.filters };
            const newOptions = [];

            for (const option of newFilters[propName]) {
                const newOption = { ...option };

                if (selectedOptions.some((selectedFilter) => selectedFilter.id === option.id)) {
                    newOption.selected = true;
                } else {
                    newOption.selected = false;
                }

                newOptions.push(newOption);
            }

            newFilters[propName] = newOptions;

            return { ...state, filters: newFilters };
        }
        case TYPES.UPDATE_QUICK_FILTERS: {
            const { propName, selectedValues } = action.payload;
            const newFilters = { ...state.quickFilters, [propName]: selectedValues };

            return { ...state, quickFilters: newFilters };
        }
        case TYPES.UPDATE_SORT: {
            return { ...state, sort: action.payload };
        }
        case TYPES.UPDATE_SEARCH_TERM: {
            return { ...state, searchTerm: action.payload.searchTerm };
        }
        case TYPES.CLEAR_FILTERS_SEARCH_SORT: {
            return {
                ...state,
                searchTerm: '',
                filters: defaultFilters,
                sort: 'desc',
            };
        }
        case TYPES.RESET: {
            return INIT();
        }
        default: {
            return { ...state };
        }
    }
};

export default JobReducer;
