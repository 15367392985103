import React, { createContext, useContext, useMemo, useState } from 'react';
import Loading from '../components/Loading';
import PropTypes from 'prop-types';

export const LoadingContext = createContext({ toggleLoading: null, on: null, off: null });

export const LoadingProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const value = useMemo(() => {
        const toggleLoading = async () => {
            setLoading((prev) => !prev);
        };

        const on = () => setLoading(true);
        const off = () => setLoading(false);

        return { toggleLoading, on };
    }, []);
    return (
        <LoadingContext.Provider value={value}>
            <Loading loading={loading} fullScreen />
            {children}
        </LoadingContext.Provider>
    );
};

LoadingProvider.propTypes = {
    children: PropTypes.node,
};

export const useLoading = () => useContext(LoadingContext);
