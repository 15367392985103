import { apiRodx, apiPortal } from '../../config';

export const sendRequest = async (route, urlPathParam = '', body = null, config = {}, urlQueryParams = {}) => {
    const { method, path } = route;
    const fullPath = `${path}${urlPathParam?.length ? '/' : ''}${urlPathParam}`;

    // MTODO: implement adding query params into url when needed

    const res = await apiRodx[method](fullPath, body, config);

    return res;
};

export const sendRequestToPortalAPI = async (route, urlPathParam = '', body = null, config = {}, urlQueryParams = {}) => {
    const { method, path } = route;
    const fullPath = `${path}${urlPathParam?.length ? '/' : ''}${urlPathParam}`;

    // MTODO: implement adding query params into url when needed

    const res = await apiPortal[method](fullPath, body, config);

    return res;
};
