
// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';

import Form from 'components/Form';

const RepairerDetailsForm = ({
    repairer,
    validations,
    handlePropChange,
    showStatus = true,
    disabledFields = [],
    cancelBtnDisabled = false,
    submitBtnDisabled = false,
}) => {
    return (
        <Form.FormContainer showCancelBtn showSubmitBtn cancelBtnDisabled={cancelBtnDisabled} submitBtnDisabled={submitBtnDisabled}>
            <Form.TextInput
                id='email-input'
                onChange={(event) => handlePropChange('email', event.target.value)}
                label='Email'
                value={repairer?.email}
                errorMsg={validations?.email}
                disabled={disabledFields.includes('email')}
            />
            <Form.TextInput
                id='phone-input'
                onChange={(event) => handlePropChange('phone_number', event.target.value)}
                label='Phone'
                value={repairer?.phone_number}
                maskType={Form.TYPES.TEXT_MASK.PHONE}
                errorMsg={validations?.phone_number}
                disabled={disabledFields.includes('phone_number')}
            />
            <Form.TextInput
                id='notes-input'
                onChange={(event) => handlePropChange('notes', event.target.value)}
                label='Notes'
                value={repairer?.notes}
                helperText='Notes will be visible to repairers'
                errorMsg={validations?.notes}
                disabled={disabledFields.includes('notes')}
            />
            { !showStatus ? null : (
                <Form.SwitchInput
                    id='status-input'
                    onChange={(event) => handlePropChange('active', event.target.checked)}
                    label='Status'
                    value={repairer.active}
                    disabled={disabledFields.includes('active')}
                />
            )}
        </Form.FormContainer>
    );
};

RepairerDetailsForm.propTypes = {
    repairer: PropTypes.shape({
        email: PropTypes.string,
        'phone_number': PropTypes.string,
        notes: PropTypes.string,
        active: PropTypes.bool,
    }).isRequired,
    validations: PropTypes.object,
    handlePropChange: PropTypes.func.isRequired,
    showStatus: PropTypes.bool,
    disabledFields: PropTypes.array,
    cancelBtnDisabled: PropTypes.bool,
    submitBtnDisabled: PropTypes.bool,
};

export default RepairerDetailsForm;
