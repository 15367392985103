import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

export const INIT = function () {
    return INITIAL_STATE;
};

export const INITIAL_STATE = {
    location: '',
    vin: '',
    color: '',
    odometer: '',
    stockRO: '',
    poNumber: '',
    services: [],
    service: {
        id: 0,
        ids: '',
        type: '',
        currency: 'USD',
        repair: '',
        parts: [],
        numberOfServices: 1,
        note: [],
        media: [],
        repairer: {},
        priceArray: [],
        price: '0.00',
        priceEach: false,
        totalDP: '$0.00',
    },
    newJob: {},
    editID: 0,
};

export const NewJobReducer = function (state, action) {
    // console.log(JSON.stringify(action, null, 2));
    switch (action.type) {
        case 'SET_LOCATION':
            return { ...state, location: action.payload };
        case 'SET_VIN':
            return { ...state, vin: action.payload };
        case 'SET_COLOR':
            return { ...state, color: action.payload };
        case 'SET_STOCK':
            return { ...state, stockRO: action.payload };
        case 'SET_ODO':
            return { ...state, odometer: action.payload };
        case 'SET_PO':
            return { ...state, poNumber: action.payload };
        case 'REMOVE_SERVICE':
            return { ...state, services: [...state.services.filter((el) => el.id !== action.payload).map(el => el).sort((a,b) => a.id - b.id)] };
        case 'NEW_SERVICE':
            let j = state.services.sort((a,b) => a.id - b.id).map(el => el);
            j.push(action.payload);
            return { ...state, services: [...j] };
        case 'EDIT_SERVICE':
            let i = action.payload.id;
            let t = state.services.filter((el) => el.id !== i).map(el => el);
            t.push(action.payload);
            return { ...state, services: [...t.sort((a,b) => a.id - b.id).map(el => el)] };
        case 'SET_EDIT_ID':
            return { ...state, editID: action.payload };
        case 'SET_SERVICE':
            return { ...state, service: { ...action.payload } };
        case 'SET_TYPE':
            return { ...state, service: { ...state.service, type: action.payload } };
        case 'SET_REPAIR':
            return { ...state, service: { ...state.service, repair: action.payload } };
        case 'SET_PRICE_EACH':
            return { ...state, service: { ...state.service, priceEach: action.payload } };
        case 'SET_PRICE_ARRAY':
            return { ...state,
                service: {
                    ...state.service,
                    priceArray: action.payload,
                    totalDP: new Intl.NumberFormat('en-US', { style: 'currency', currency: action.payload.currency })
                        .format(action.payload.data.reduce((total, part) => { return total += Number(part.price); } , 0)),
                },
            };
        case 'SET_PRICE': // price, displayPrice, totalDP
            return { ...state,
                service: { ...state.service,
                    price: action.payload.price,
                    priceArray: {
                        data: state.service.priceArray.data.map(el => {
                            return {
                                ...el,
                                price: action.payload.price,
                            };
                        }),
                        currency: state.service.priceArray.currency,
                    },
                    totalDP: new Intl.NumberFormat('en-US', { style: 'currency', currency: state.service.currency })
                        .format(action.payload.price*state.service.numberOfServices),
                } };
        case 'SET_PARTS': // numberOfServices
            return { ...state,
                service: { ...state.service,
                    parts: action.payload.parts,
                    numberOfServices: action.payload.numberOfServices,
                    totalDP: new Intl.NumberFormat('en-US', { style: 'currency', currency: state.service.currency })
                        .format(state.service.price*action.payload.numberOfServices),
                } };
        case 'SET_NOTE':
            return { ...state, service: { ...state.service, note: action.payload } };
        case 'SET_IDS':
            return { ...state, service: { ...state.service, ids: action.payload } };
        case 'SET_MEDIA':
            return { ...state, service: { ...state.service, media: action.payload } };
        case 'SET_REPAIRER':
            return { ...state, service: { ...state.service, repairer: action.payload } };
        case 'RESET_SERVICE':
            return { ...state,
                service: {
                    type: '',
                    currency: 'USD',
                    repair: '',
                    ids: [],
                    parts: [],
                    numberOfServices: 1,
                    note: [],
                    media: [],
                    repairer: {},
                    price: '0.00',
                    totalDP: '$0.00',
                } };
        case 'RESET_SERVICES':
            return { ...state, services: [...state.services.filter(el => false).map(el => el)] };
        case 'NEW_JOB':
            return { ...state, newJob: { ...action.payload } };
        case 'RESET_JOB':
            return { ...INITIAL_STATE };
        case 'INIT':
            return { ...INITIAL_STATE };
        default:
            return { ...state };
    }
};

export const NewJobContext = createContext({});

export const NewJobProvider = ({ children }) => {
    const [state, dispatch] = useReducer(NewJobReducer, INITIAL_STATE, INIT);

    const value = {
        state,
        // @ts-ignore
        DISPATCH: (data) => { dispatch(data); },
    };

    return (
        <NewJobContext.Provider value={value}> { children } </NewJobContext.Provider>
    );
};

NewJobProvider.propTypes = {
    children: PropTypes.node,
};

export const useNewJob = () => useContext(NewJobContext);
