import { CssBaseline, ThemeProvider } from '@mui/material';
import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import { useMode } from '../hooks/useTheme';

export const ColorModeContext = createContext({
    toggleColorMode: () => {},
});

export const ColorModeProvider = ({ children }) => {
    const [theme, colorMode] = useMode();
    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
};

ColorModeProvider.propTypes = {
    children: PropTypes.node,
};

export const useColorMode = () => useContext(ColorModeContext);
