/* eslint-disable no-constant-condition */
// React
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
// Local
import { ReactComponent as MediaRemove } from '../../../assets/images/remove_circle.svg';
import { ReactComponent as MediaUploadPDF } from '../../../assets/images/media_pdf.svg';
import { ReactComponent as MediaUploadPic } from '../../../assets/images/media_pic.svg';
import { actions as repairerActions } from 'services/repairer';
import { gatherCatalog } from 'services/utils/enums';
import { useNewJob } from 'services/reducers/newJob';
import { useError } from 'contexts/ErrorContext';
// Styles
import {
    Autocomplete,
    Box,
    Button,
    Card,
    Divider,
    FormControl,
    FormControlLabel,
    InputAdornment,
    Grid,
    Input,
    InputLabel,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Typography,
    useTheme,
    Checkbox,
    IconButton,
    Switch,
    Popper,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { CollectionsBookmarkOutlined } from '@mui/icons-material';

const ModalForm = () => {
    const theme = useTheme();
    const newJob = useNewJob();
    const err = useError();

    const [view, setView] = useState({ service: true, media: false, repairer: false });
    const currencySymbol = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).formatToParts(0).map(el => el.value)[0];

    const [mediaAnchor, setMediaAnchor] = useState([]);
    const [repairerOptions, setRepairerOptions] = useState([]);
    const [repairCatalog, setRepairCatalog] = useState([]);
    const [serviceOptions, setServiceOptions] = useState([]);
    const [repairOptions, setRepairOptions] = useState([]);
    const [partsOptions, setPartsOptions] = useState([]);

    const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
    const checkedIcon = <CheckBoxIcon fontSize='small' />;

    const gatherOptions = async function() {
        const repairers = (await repairerActions.getRepairers()).filter((r) => r.name);
        const catalog = await gatherCatalog({});
        if (catalog && catalog.data.status === 200 && catalog.data.payload) {
            setRepairerOptions(repairers);
            setRepairCatalog(catalog.data.payload);
            // @ts-ignore
            const services = [...new Set(catalog.data.payload.map((el) => el.service_type))];
            setServiceOptions(services);
        }
    };

    const clickMediaAnchor = async function (e, i) {
        mediaAnchor[i].current.click();
    };

    const resetMediaAnchor = async function () {
        if (!newJob.state.service.ids || !newJob.state.service.ids.length) return;
        const t = newJob.state.service.ids.split(',').map(i=> React.createRef());
        setMediaAnchor(t);
    };

    const MediaInputButton = function({ i, sID }) {
        return (
            <div>
                <Button
                    color='primary'
                    title={`btn-media-${i}`}
                    onClick={(e) => { clickMediaAnchor(e, i); }}
                    // disabled={mediaAnchor[i]}
                >
                    <MediaUploadPDF />
                    <MediaUploadPic />
                </Button>

                <input
                    ref={mediaAnchor[i]}
                    type='file'
                    style={{ display: 'none' }}
                    multiple={true}
                    accept='application/pdf,video/*,image/*'
                    onChange={e => {
                        renderMedia(e.target.files, sID);
                    }}
                />
            </div>
        );
    };

    MediaInputButton.propTypes = {
        i: PropTypes.number.isRequired,
        sID: PropTypes.string.isRequired,
    };

    const renderMedia = async function(files, id) {
        let data = [];
        // Build Promises of each File
        Array.from(files).forEach(async (element) => {
            let payload = new Promise((res, rej) => {
                const rendReader = new FileReader();
                rendReader.onload = () => {
                    const MEDIA_TYPES = { 'image': 'Image', 'video': 'Video', 'application':'PDF' };
                    // Final data shape of Media object
                    res({
                        'text': element.name || 'no caption',
                        'contentHeader': element.type,
                        'media_type': MEDIA_TYPES[element.type.split('/')[0]],
                        'localPath': rendReader.result,
                        'data': element,
                        'serviceID': id,
                        // 'author_company': str,
                        // 'is_supplement': bool,
                        // 'created_at': str,
                        // 'text': Or(None, str),
                        // 'url': Or(None, str),
                        // 'marketplace_side': Or('Job Supplier', 'Repairer'),
                    });
                };
                rendReader.readAsDataURL(element);
            });
            data.push(payload);
        });
        // Trigger Promises
        Promise.all(data).then((values) => {
            newJob.DISPATCH({ type: 'SET_MEDIA', payload: [...newJob.state.service.media, ...values] });
        });
    };

    const showThumbnails = function(sID) {
        return newJob.state.service.media.filter((el) => el.serviceID === sID).map((tn, i) => {
            // TODO check if iframe, video, or img tag is needed for display
            const formatThumbnail = function(med) {
                if (med.media_type === 'PDF') {
                    return <iframe width='70' height='70' src={med.localPath}></iframe>;
                } else if (med.media_type === 'Video') {
                    return <video width='70' height='70' controls>
                        <source src={med.localPath} type='video/mp4' />
                    </video>;
                }
                return <img
                    src={med.localPath}
                    width='70' height='70'
                    style={{}}
                />;
            };

            return (
                <div key={i} style={{ position: 'relative' }}>
                    <Card variant='outlined' sx={{ p: 0, m: .25 }}>
                        { formatThumbnail(tn) }
                    </Card>
                    <IconButton
                        onClick={() => {
                            const values = [...newJob.state.service.media.filter(el => el.text != tn.text)];
                            newJob.DISPATCH({ type: 'SET_MEDIA', payload: [...values] });
                        }}
                        sx={{ '&:hover': {
                            backgroundColor: theme.palette.blue.main,
                        },
                        p: 0,
                        m: 0,
                        border: 0,
                        position: 'absolute',
                        bottom: '-4px',
                        right: '-4px',
                        backgroundColor: theme.palette.white }}>
                        <MediaRemove />
                    </IconButton>
                </div>
            );});
    };

    const renderMediaSubmission = function() {
        if (!newJob.state.service.ids || !newJob.state.service.ids.length) return;
        return newJob.state.service.ids.split(',').map((el, i) => {
            let repairDetails = repairCatalog.filter(c => c.id == el)?.[0] || {};
            return (
                <Card variant={'outlined'} key={i} sx={{ my: 1.5 }}>
                    <Grid container direction={'column'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <Grid item>
                            <Typography sx={{ color: theme.palette.grey[900], fontSize: 16 }}>
                                {/* { el } */}
                                {/* eslint-disable-next-line max-len */}
                                {`${(repairDetails.position == 'N/A' || !repairDetails.position)? '' : repairDetails.position} ${(repairDetails.part == 'N/A' || !repairDetails.part) ? '' : repairDetails.part}`}
                            </Typography>
                        </Grid>
                        <Grid item sx={{ borderBottom:'1px solid', borderBottomColor: theme.palette.grey[700], mb: 1 }}
                            display='flex' alignItems={'center'}
                            justifyContent={'space-between'} width={'88%'}>
                            <TextField
                                value={(newJob.state.service.note && newJob.state.service.note.length)
                                    ? newJob.state.service.note.filter(n => n.serviceID == el).map(el => el.text || '')[0] : ''}
                                placeholder={'Type note here...'}
                                variant='standard'
                                id='set-note'
                                multiline={true}
                                inputProps={{ style: { fontSize: '16px', textTransform: 'none', marginLeft: '8px' } }}
                                sx={{ display: 'flex', width: '75%', fontSize: '16px', backgroundColor: 'rgba(255,255,255,.9)' }}
                                InputProps={{ disableUnderline: true }}
                                // @ts-ignore
                                onChange={(event) => { newJob.DISPATCH({ type: 'SET_NOTE',
                                    payload: [...newJob.state.service.note.filter(n => n.serviceID != el), {
                                        'text': event.target.value,
                                        'media_type': 'Text',
                                        'serviceID': el,
                                        // 'author_company': str,
                                        // 'is_supplement': bool,
                                        // 'created_at': str,
                                        // 'text': Or(None, str),
                                        // 'url': Or(None, str),
                                        // 'marketplace_side': Or('Job Supplier', 'Repairer'),
                                    }] }); } }
                            />
                            <MediaInputButton i={i} sID={el} />

                        </Grid>
                        <Grid item display='flex' width={'88%'} sx={{ overflow: 'auto' }}>
                            { (newJob.state.service.media && newJob.state.service.media.length > 0) && (
                                showThumbnails(el)
                            )}
                        </Grid>
                    </Grid>
                </Card>
            );
        });
    };

    const validateData = async function() {
        const catalog = repairCatalog;
        let serviceIDs = [];
        let types, repairs, parts;

        types = catalog.filter(el => el.service_type === newJob.state.service.type);
        if (types.length) {
            if (types.length > 1) {
                repairs = types.filter(el => el.repair_type === newJob.state.service.repair);
                if (repairs.length && repairs.length > 1) {
                    if (newJob.state.service.parts.length) {
                        newJob.state.service.parts.forEach(sEl => {
                            parts = repairs.filter(el => el.part === sEl.part && el.position === sEl.position );
                            serviceIDs.push(...parts.map(el => el.id));
                        });
                    }
                } else if (repairs.length && repairs.length === 1) {
                    serviceIDs.push(...repairs.map(el => el.id));
                }
            } else serviceIDs.push(...types.map(el => el.id));
        }
        const serviceInCatalog = Boolean(serviceIDs.length);
        if (serviceInCatalog) {
            return serviceIDs.join(',');
        } else {
            err.DISPATCH({ type: 'NO_ERROR', payload: { alert: 'Nothing input' } });
            return false;
        }
    };

    const submitService = async function() {
        const validIds = await validateData();
        if (validIds) {
            let priceArray = (newJob.state.service.priceArray && newJob.state.service.priceArray.data && newJob.state.service.priceArray.data.length)
                ? newJob.state.service.priceArray : { data: validIds.split(',').map(el => {
                    return {
                        price: '0',
                        id: el,
                    };
                }) };
            await newJob.DISPATCH({ type: 'SET_IDS', payload: validIds });
            await newJob.DISPATCH({
                type: 'SET_PRICE_ARRAY',
                payload: { data: [...priceArray.data], currency: 'USD' },
            });
            err.DISPATCH({ type: 'NO_ERROR', payload: { alert: 'Valid input' } });
        } else err.DISPATCH({ type: 'INVALID_INPUT', payload: { alert: 'Invalid input' } });
    };

    const renderPricingInputs = function() {
        if (newJob?.state?.service?.priceEach && newJob?.state?.service?.ids?.split(',')) {
            return newJob.state.service.ids.split(',').map((el, i) => {
                let repairDetails = repairCatalog.filter(c => c.id == el)?.[0] || {};
                return (
                    <Grid item key={i} sx={{ width: '90%' }} my={.5} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                        <Typography sx={{ m: 1, p:1, color: theme.palette.red[900], fontSize: 16 }}>
                            {`Price${(repairDetails.position == 'N/A' || !repairDetails.position)? '' : ` - ${repairDetails.position}`} ${(repairDetails.part == 'N/A' || !repairDetails.part) ? '' : repairDetails.part}`}
                        </Typography>
                        <FormControl sx={{ m: 1, p: 1 }} variant={'standard'}>
                            <InputLabel sx={{ ml: 1, pt: 0.5, width: '100%', fontSize: 13 }} htmlFor={'set-price'}>
                                Price
                            </InputLabel>
                            <Input
                                id={`set-price-${i}`}
                                value={(newJob.state?.service?.priceArray?.data || []).filter(p => p.id == el)?.[0]?.price}
                                // @ts-ignore
                                startAdornment={
                                    <InputAdornment position='start'>
                                        {currencySymbol}
                                    </InputAdornment>
                                }
                                error={/^\d*$/.test((newJob.state?.service?.priceArray?.data || []).filter(p => p.id == el)?.[0]?.price)}
                                inputProps={{ style: { border: 0, fontSize: '13px', textTransform: 'none', marginLeft: '12px', paddingBottom: '2px' } }}
                                sx={{ border: 0, borderBottomColor: theme.palette.grey[900] }}
                                onChange={(event) => {
                                    let keep = (newJob.state?.service?.priceArray?.data || []).filter(p => p.id != el);
                                    // @ts-ignore
                                    newJob.DISPATCH({
                                        type: 'SET_PRICE_ARRAY',
                                        payload: {
                                            data: [...keep, { id: el, price: event.target.value.replace(/[^0-9.]/, '') }],
                                            currency: 'USD',
                                        },
                                    });
                                } }
                                onBlur={() => {
                                    let keep = (newJob.state?.service?.priceArray?.data || []).filter(p => p.id != el);
                                    let t = (newJob.state?.service?.priceArray?.data || []).filter(p => p.id == el)?.[0]?.price;
                                    if (!t.includes('.')) t = `${t}.00`;
                                    // @ts-ignore
                                    newJob.DISPATCH({
                                        type: 'SET_PRICE_ARRAY',
                                        payload: {
                                            data: [...keep, { id: el, price: t.replace(/[^0-9.]/, '') }],
                                            currency: 'USD',
                                        },
                                    });
                                }}
                            />
                        </FormControl>
                    </Grid>
                );
            });
        }
        return (
            <Grid item sx={{ width: '90%' }} my={2} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography sx={{ m: 1, p:1, color: theme.palette.grey[900], fontSize: 16 }}>
                    {`Price x ${newJob.state.service.numberOfServices}`}
                </Typography>
                <FormControl sx={{ m: 1, p: 1 }} variant={'standard'}>
                    <InputLabel sx={{ ml: 1, pt: 0.5, width: '100%', fontSize: 13 }} htmlFor={'set-price'}>
            Price
                    </InputLabel>
                    <Input
                        id='set-price'
                        value={newJob.state.service.price}
                        // @ts-ignore
                        startAdornment={
                            <InputAdornment position='start'>
                                {currencySymbol}
                            </InputAdornment>
                        }
                        error={/^\d*$/.test(newJob.state.service.price)}
                        inputProps={{ style: { border: 0, fontSize: '13px', textTransform: 'none', marginLeft: '12px', paddingBottom: '2px' } }}
                        sx={{ border: 0, borderBottomColor: theme.palette.grey[900] }}
                        onChange={(event) => {
                            // @ts-ignore
                            newJob.DISPATCH({ type: 'SET_PRICE', payload: { price: event.target.value.replace(/[^0-9.]/, ''), currency: 'USD' } });
                        } }
                        onBlur={() => {
                            let t = newJob.state.service.price;
                            if (!t.includes('.')) t = `${t}.00`;
                            // @ts-ignore
                            newJob.DISPATCH({ type: 'SET_PRICE', payload: { price: t.replace(/[^0-9.]/, ''), currency: 'USD' } });
                        }}
                    />
                </FormControl>
            </Grid>
        );
    };

    useEffect(() => {
        if (!serviceOptions.length || !repairCatalog.length) {
            gatherOptions().catch(console.error);
        }
        if (newJob.state.service.type) {
            setRepairOptions([]);
            setPartsOptions([]);
            const repairs = [
                // @ts-ignore
                ...new Set(repairCatalog
                    .filter((el) => el.service_type == newJob.state.service.type)
                    .map((el) => el.repair_type)),
            ];
            setRepairOptions(repairs[0] == null ? [] : repairs);
        }
        if (newJob.state.service.type || newJob.state.service.repair) {
            setPartsOptions([]);
            const parts = [
                // @ts-ignore
                ...new Set(repairCatalog
                    .filter((el) => {
                        if (newJob.state.service.repair) {
                            return ( el.service_type == newJob.state.service.type
                                && el.repair_type == newJob.state.service.repair
                            );
                        } else {
                            return ( el.service_type == newJob.state.service.type );
                        }

                    })
                    .map((el) => { return { part: el.part, position: el.position }; })
                    .filter(el => el.part || el.position)),
            ];
            setPartsOptions(parts[0] == null ? [] : parts);
        }
        if (newJob.state.service.ids && (newJob.state.service.ids.length != mediaAnchor.length)) {
            resetMediaAnchor();
        }
    }, [view, newJob.state]);

    const disableRepairer = function() {
        if (repairOptions && repairOptions.length) return false;
        return true;
    };

    const disableParts = function() {
        if (partsOptions && partsOptions.length) return false;
        return true;
    };

    return (
        <Box sx={{ overflow: 'auto', height: 600, maxHeight: 600 }}>
            <Grid item>
                { view.service && (
                    <Paper sx={{ p:2, mx: 8, boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)' }}>
                        <Grid item display={'flex'} justifyContent={'end'} alignItems={'center'} my={1}>
                            <Typography sx={{ color: theme.palette.grey[900], fontSize: 16 }}> Service(s) </Typography>
                            <KeyboardArrowDownIcon sx={{ ml: '40%', color: theme.palette.grey.main }} />
                        </Grid>
                        {/* QUICK FIND */}
                        <Grid item display='flex' justifyContent={'center'} p={1} m={1}>
                            <Autocomplete
                                id='quick-find'
                                options={serviceOptions}
                                sx={{
                                    width: '88%',
                                    height: 22,
                                    mt: 1,
                                    p: 4,
                                    borderRadius: 6,
                                    border: 0,
                                    backgroundColor: theme.palette.grey[50],
                                }}
                                ListboxProps={{ sx: { fontSize: 14, padding: 1 } }}
                                freeSolo={false}
                                // disabled={true}
                                renderInput={(params) => {
                                    params.InputProps = { ...params.InputProps,
                                        style: { fontSize: '15px' },
                                    };
                                    params.InputLabelProps = { ...params.InputLabelProps,
                                        style: { fontSize: '15px', color: theme.palette.grey[800] },
                                    };
                                    return (
                                        <TextField {...params} variant={'standard'} label='Quick Find (coming soon...)' />
                                    );
                                }}
                            />
                        </Grid>
                        {/* SERVICE TYPE | REPAIR TYPE */}
                        <Grid item display='flex' justifyContent={'center'} p={1} m={1}>
                            <Autocomplete
                                sx={{
                                    mt: 1,
                                    borderRadius: 0,
                                    border: 0,
                                    borderBottom: '1px solid',
                                    borderBottomColor: theme.palette.grey[900],
                                    width: '100%',
                                }}
                                disablePortal
                                id='select-serviceType'
                                openOnFocus={false}
                                options={serviceOptions}
                                value={newJob.state.service.type}
                                // @ts-ignore
                                onInputChange={ (event, newValue) => {
                                    newJob.DISPATCH({ type: 'SET_TYPE', payload: newValue });
                                } }
                                renderInput={(params) => {
                                    params.InputProps = { ...params.InputProps,
                                        style: { fontSize: '14px' },
                                    };
                                    params.InputLabelProps = {
                                        ...params.InputLabelProps,
                                        style: { fontSize: '14px', color: theme.palette.grey[800] },
                                    };
                                    return (<TextField {...params} sx={{ p: 0 }} variant={'standard'} label='Service Type' />);
                                }}
                                PopperComponent={(props) => {
                                    return (
                                        <Popper {...props} id='select-serviceType-popper' />
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item display='flex' justifyContent={'center'} p={1} m={1}>
                            <Grid item sx={{ width: '100%' }}>
                                <Autocomplete
                                    disablePortal
                                    id='select-repairType'
                                    openOnFocus={true}
                                    options={repairOptions}
                                    disabled={disableRepairer()}
                                    value={newJob.state.service.repair}
                                    // @ts-ignore
                                    onInputChange={ (event, newValue) => {
                                        newJob.DISPATCH({ type: 'SET_REPAIR', payload: newValue });
                                    } }
                                    sx={{
                                        mt: 1,
                                        borderRadius: 0,
                                        border: 0,
                                        borderBottom: '1px solid',
                                        borderColor: (!disableRepairer() && !newJob.state.service.repair) ? theme.palette.red.main : theme.palette.grey[900],
                                        mr: 4,
                                        width: '100%',
                                    }}
                                    renderInput={(params) => {
                                        params.InputProps = { ...params.InputProps,
                                            style: { fontSize: '14px' },
                                        };
                                        params.InputLabelProps = { ...params.InputLabelProps,
                                            style: { fontSize: '14px', color: theme.palette.grey[800] },
                                        };
                                        return (<TextField {...params} sx={{ p: 0 }} variant={'standard'} label='Repair Type' />);
                                    }}
                                    PopperComponent={(props) => {
                                        return (
                                            <Popper {...props} id='select-repairType-popper' />
                                        );
                                    }}
                                />
                                {newJob.state.service.type && disableRepairer() && (
                                    <Typography color={theme.palette.grey[300]}> This is not needed </Typography>
                                )}
                            </Grid>
                        </Grid>
                        {/* PARTS */}
                        <Grid item display='flex' justifyContent={'center'} p={1} m={1}>
                            <Grid item sx={{ width: '100%' }}>
                                <Autocomplete
                                    disablePortal
                                    id='select-parts'
                                    disableCloseOnSelect
                                    disabled={disableParts()}
                                    options={partsOptions}
                                    multiple
                                    limitTags={2}
                                    value={newJob.state.service.parts}
                                    // @ts-ignore
                                    onChange={ (event, newValue) => {
                                        newJob.DISPATCH({
                                            type: 'SET_PARTS', payload: { parts: newValue, numberOfServices: (newValue.length || 1) },
                                        });
                                    } }
                                    isOptionEqualToValue={(option, value) => { return (option.part == value.part && option.position == value.position); }}
                                    getOptionLabel={(option) => `${(option.position == 'N/A' || !option.position) ? '' : option.position} ${(option.part == 'N/A' || !option.part) ? '' : option.part}`}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                                <Typography>{`${(option.position == 'N/A' || !option.position) ? '' : option.position} ${(option.part == 'N/A' || !option.part) ? '' : option.part}`}</Typography>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 10 }}
                                                    checked={selected}
                                                />
                                            </Grid>
                                        </li>
                                    )}
                                    sx={{
                                        mt: 1,
                                        borderRadius: 0,
                                        border: 0,
                                        borderBottom: '1px solid',
                                        borderColor: (!disableParts() && !newJob.state.service.parts.length) ? theme.palette.red.main : theme.palette.grey[900],
                                        width: '100%',
                                    }}
                                    renderInput={(params) => {
                                        params.InputProps = { ...params.InputProps,
                                            style: { fontSize: '14px' },
                                        };
                                        params.InputLabelProps = { ...params.InputLabelProps,
                                            style: { fontSize: '14px', color: theme.palette.grey[800] },
                                        };
                                        return (<TextField {...params} sx={{ p: 0 }} variant={'standard'} label='Part(s)' />);
                                    }}
                                    PopperComponent={(props) => {
                                        return (
                                            <Popper {...props} id='select-parts-popper' />
                                        );
                                    }}
                                />
                                {newJob.state.service.type && disableParts() && (
                                    <Typography color={theme.palette.grey[300]}> This is not needed </Typography>
                                )}
                            </Grid>
                        </Grid>

                    </Paper>
                )}
                { !view.service && (
                    <Paper onClick={() => {setView({ service: true, media: false, repairer: false });}} sx={{ p:2, mx: 8, boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)' }}>
                        <Grid item display={'flex'} justifyContent={'space-between'} my={2} alignItems={'center'}>
                            <Typography sx={{ color: theme.palette.grey[900], fontSize: 16 }}>
                                            Service(s)
                            </Typography>
                            <Box>
                                <Typography sx={{ color: theme.palette.grey[900], fontSize: 14, textAlign: 'right' }}>
                                    { `${newJob.state.service.type} - ${newJob.state.service.repair}` }
                                </Typography>
                                <Typography sx={{ color: theme.palette.grey[700], fontSize: 12, textAlign: 'right' }}>
                                    { `${newJob.state.service.numberOfServices} Part(s)` }
                                </Typography>
                            </Box>
                        </Grid>
                    </Paper>
                )}
            </Grid>
            <Grid item display='flex' justifyContent={'center'} p={1} m={1}>
                { view.media && (
                    <Paper sx={{ p:2, mx: 6, boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)', width: '100%' }}>
                        <Grid item display={'flex'} justifyContent={'center'} my={2}>
                            <Typography sx={{ color: theme.palette.grey[900], fontSize: 16 }}>
                                { `Upload Media - ${newJob.state.service.type}`}
                            </Typography>
                        </Grid>
                        <Box maxHeight={'300px'} overflow={'auto'}>
                            <Divider variant='middle' />
                            { renderMediaSubmission() }
                        </Box>
                    </Paper>
                )}
                { !view.media && (
                    <Paper
                        onClick={() => {
                            setView({ service: false, media: true, repairer: false });
                            submitService();
                        }}
                        sx={{ p:2, mx: 6, boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)', width: '100%' }}>
                        <Grid item display={'flex'} justifyContent={'end'} alignItems={'center'} my={1}>
                            <Typography sx={{ color: theme.palette.grey[900], fontSize: 16 }}>
                                Photos, Media, and Notes
                            </Typography>
                            <KeyboardArrowUpIcon sx={{ ml: '27%', color: theme.palette.grey.main }} />
                        </Grid>
                    </Paper>
                )}
            </Grid>
            <Grid item display='flex' justifyContent={'center'} p={1} m={1}>
                { view.repairer && (
                    <Paper sx={{ p:2, mx: 6, boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)', width: '100%' }}>
                        <Grid item display={'flex'} justifyContent={'center'} my={2}>
                            <Typography sx={{ color: theme.palette.grey[900], fontSize: 16 }}>
                                Select Repairer
                            </Typography>
                        </Grid>
                        <Grid item my={1} display={'flex'} justifyContent={'center'}>
                            <FormControl sx={{ width: '90%' }}>
                                <RadioGroup
                                    aria-labelledby='demo-radio-buttons-group-label'
                                    defaultValue='1'
                                    name='radio-buttons-group'
                                >
                                    <Grid item display={'flex'} justifyContent={'space-between'} my={2} alignItems={'center'}>
                                        <FormControlLabel value='1' control={<Radio />} label={'My Repairers'} />
                                        <Autocomplete
                                            id='select-repairer'
                                            disablePortal
                                            options={repairerOptions}
                                            getOptionLabel={(option) => option.name || ''}
                                            sx={{
                                                width: '50%',
                                                borderRadius: 18,
                                                border: 0,
                                            }}
                                            value={newJob.state.service.repairer}
                                            isOptionEqualToValue={(option, value) => { return (option.id && option.id == value.id); }}
                                            onChange={ (event, newValue) => { newJob.DISPATCH({ type: 'SET_REPAIRER', payload: newValue }); } }
                                            ListboxProps={{ sx: { fontSize: 14, padding: 1 } }}
                                            freeSolo={false}
                                            renderInput={(params) => {
                                                params.InputProps = { ...params.InputProps,
                                                    style: { fontSize: '16px' },
                                                };
                                                params.InputLabelProps = { ...params.InputLabelProps,
                                                    style: { fontSize: '16px', color: theme.palette.grey[900] },
                                                };
                                                return (
                                                    <TextField {...params} variant={'standard'} label='Select Repairer' />
                                                );
                                            }}
                                            PopperComponent={(props) => {
                                                return (
                                                    <Popper {...props} id='select-repairer-popper' />
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Divider />
                                    <Grid item display={'flex'} justifyContent={'space-between'} mt={1} alignItems={'center'}>
                                        <FormControlLabel value='5' control={<Radio />} label='The Marketplace' disabled={true} />
                                        <Typography sx={{ p: 1, mt: 1, width: '50%', color: (false) ? theme.palette.grey[900] : theme.palette.grey[500], fontSize: 16 }}>
                                                        Repair on Demand
                                        </Typography>
                                    </Grid>
                                    <Divider />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item container my={1} display={'flex'} direction={'column'} justifyContent={'center'} alignItems={'center'}>
                            <Typography sx={{ color: theme.palette.grey[900], fontSize: 16, mt: 2 }}>
                                    Set Price
                            </Typography>
                            { !newJob.state.service.priceEach && (
                                <Typography sx={{ color: theme.palette.grey[700], fontSize: 14, mb: 2 }}>
                                    Price will be applied to each location/part of service
                                </Typography>
                            )}
                            { newJob.state.service.priceEach && (
                                <Typography sx={{ color: theme.palette.grey[700], fontSize: 14, mb: 2 }}>
                                    Set a price for each location/part of service
                                </Typography>
                            )}
                            <Grid display={'flex'} alignItems={'center'} p={0} m={0}>
                                <Typography sx={{ color: theme.palette.grey[900], fontSize: 16, mt: 0 }}>
                                    Price individually
                                </Typography>
                                <Switch
                                    onChange={(e) => {
                                        newJob.DISPATCH({ type: 'SET_PRICE_EACH', payload: e.target.checked });
                                    }}
                                    checked={newJob.state.service.priceEach}
                                />
                            </Grid>
                            { renderPricingInputs() }
                            <Divider sx={{ width: '90%' }} />
                            <Grid item sx={{ width: '90%' }} my={2} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography sx={{ m:1, p:1, color: theme.palette.grey[900], fontSize: 16 }}>
                                                Total
                                </Typography>
                                <Typography sx={{ m:1, p:1, color: theme.palette.grey[900], fontSize: 16 }}>
                                    {newJob.state.service.totalDP}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                )}
                { !view.repairer && (
                    <Paper onClick={() => {
                        setView({ service: false, media: false, repairer: true });
                        submitService();
                    }}
                    sx={{ p:2, mx: 6, boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)', width: '100%' }}>
                        <Grid item display={'flex'} justifyContent={'end'} alignItems={'center'} my={1}>
                            <Typography sx={{ color: theme.palette.grey[900], fontSize: 16 }}>
                                Select Repairer
                            </Typography>
                            <KeyboardArrowUpIcon sx={{ ml: '35%', color: theme.palette.grey.main }} />
                        </Grid>
                    </Paper>
                )}
            </Grid>
        </Box>
    );
};

ModalForm.propTypes = {};

export default ModalForm;