import React from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
    Box,
    Chip,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    menuClasses,
    OutlinedInput,
    Select,
    selectClasses,
    Typography,
    useTheme,
    Tooltip,
} from '@mui/material';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

const getStyles = (theme) => ({
    label: {
        pr: 1,
        fontSize: '16px',
        lineHeight: '16px',
        background: theme.palette.white,
    },
    menuHeader: {
        p: '8px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    menuItem: {
        px: '24px',
        py: '12px',
        fontSize: '12px',
        color: theme.palette.grey[700],
        '&:hover': {
            background: theme.palette.grey[30],
        },
        '&.Mui-selected': {
            '&:hover': {
                background: theme.palette.grey[30],
            },
        },
    },
    divider: {
        m: '0px !important',
        height: '1px',
        maxHeight: '1px',
    },
    select: {
        p: 1,
        width: '100%',
        display: 'flex',
        [`& .${selectClasses.select}`] : {
            width: '100% !important',
        },
    },
    chip: {
        maxWidth: '120px',
        background: theme.palette.primary[30],
        color: theme.palette.primary[700],
        borderRadius: '8px',
        border: `1px solid ${theme.palette.grey[100]}`,
    },
    menuProps: {
        PaperProps: {
            sx: {
                maxHeight: 224,
                maxWidth: '350px',
                [`& .${menuClasses.list}`]: {
                    paddingTop: '0px',
                    paddingBottom: '0px',
                },
            },
        },
    },
});

// IMP: This Component only works when options have unique values (labels)
const MultiSelect = ({ label, selected, options, getOptionLabel, isOptionSelected, onChange, maxChipsShown = 2, selectMenuTitle = '' }) => {
    const theme = useTheme();
    const id = `multi-select-${label.replace(/ /g, '-')}`;

    const handleChange = (value) => {
        onChange(value.map((v) => options.filter((opt) => getOptionLabel(opt) === v)[0]));
    };

    const handleDelete = (deleted) => {
        handleChange(selected.filter((option) => getOptionLabel(option) !== deleted).map((opt) => getOptionLabel(opt)));
    };

    const menuHeaderElem = !selectMenuTitle?.length ? null : (
        <MenuItem key={`${id}-menu-header`} disabled value='' sx={getStyles(theme).menuHeader}>
            <Typography sx={{ fontSize: '12px', textTransform: 'uppercase' }}>
                {selectMenuTitle}
            </Typography>
        </MenuItem>
    );

    const dividerElem = !selectMenuTitle?.length ? null : (<Divider key={`${id}-menu-divider`} sx={getStyles(theme).divider} />);

    const getMenuItemElem = (option, indx) => {
        const divider = indx !== options.length - 1;
        const label = getOptionLabel(option);
        const icon = isOptionSelected(selected, option) ? (<DoneIcon />) : (
            <AddCircleOutlineIcon sx={{ color: theme.palette.grey[200] }} />
        );

        return (
            <MenuItem key={`${id}-menu-${label}`} value={label} divider={divider} sx={getStyles(theme).menuItem}>
                <Grid container direction='row' sx={{ m: 0, p: 0 }}>
                    <Tooltip title={label} enterDelay={1000} enterNextDelay={1000}>
                        <Grid item sm={8} sx={{ m: 0, p: 0, maxWidth: '200px', overflow: 'clip' }}>
                            {label}
                        </Grid>
                    </Tooltip>
                    <Grid item sm={4} container direction='row-reverse' sx={{ m: 0, p: 0 }}>
                        {icon}
                    </Grid>
                </Grid>
            </MenuItem>
        );
    };

    const menuItems = [
        menuHeaderElem,
        dividerElem,
        options.map(getMenuItemElem),
    ];

    return (
        <Box sx={{ my: '16px'}}>
            <FormControl sx={{ m: 0, width: '100%' }}>
                <InputLabel id={`${id}-label`} sx={getStyles(theme).label}>{label}</InputLabel>
                <Select
                    labelId={`${id}-label`}
                    id={`${id}`}
                    multiple
                    value={selected.map((opt) => getOptionLabel(opt))}
                    onChange={(event) => {
                        handleChange(event.target.value);
                    }}
                    sx={getStyles(theme).select}
                    input={
                        <OutlinedInput id={`${id}-chip`} label={label} sx={{ overflow: 'hidden', p: 1.5 }} />
                    }
                    renderValue={(selectedOptionLabels) => {
                        return (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, width: '100%' }}>
                                {
                                    selectedOptionLabels.map((optionLabel, indx) => indx > maxChipsShown ? null : (
                                        <Tooltip key={optionLabel} title={indx < maxChipsShown ? optionLabel : ''}>
                                            <Chip
                                                variant='outlined'
                                                onDelete={indx == maxChipsShown ? null : () => {}}
                                                label={
                                                    indx == maxChipsShown ? `+${selectedOptionLabels.length - maxChipsShown}` : optionLabel
                                                }
                                                deleteIcon={
                                                    <CloseIcon onMouseDown={(event) => {
                                                        event.stopPropagation();
                                                        event.preventDefault();
                                                        handleDelete(optionLabel);
                                                    }} />
                                                }
                                                sx={getStyles(theme).chip}
                                            />
                                        </Tooltip>
                                    ))
                                }
                            </Box>
                        );
                    }}
                    MenuProps={getStyles(theme).menuProps}
                >
                    {menuItems}
                </Select>
            </FormControl>
        </Box>
    );
};

MultiSelect.propTypes = {
    label: PropTypes.string.isRequired,
    selected: PropTypes.arrayOf(PropTypes.string).isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    getOptionLabel: PropTypes.func.isRequired,
    isOptionSelected: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    maxChipsShown: PropTypes.number,
    selectMenuTitle: PropTypes.string,
};

export default MultiSelect;
