import React, { useEffect, useState, useCallback } from 'react';

import debounce from 'lodash.debounce';

import { Box, styled, Grid, useTheme, Divider } from '@mui/material';
import { AddLocationAlt } from '@mui/icons-material';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';

import { useError } from 'contexts/ErrorContext';
import { useLocations, TYPES as LOCATION_TYPES } from 'services/location';

import TitlePanel from 'components/Panels/TitlePanel';
import SearchAndFilterPanel from 'components/Panels/SearchAndFilterPanel';
import ListAndDetailsPanel from 'components/Panels/ListAndDetailsPanel';

import LocationList from './content/LocationList';
import LocationDetails from './content/LocationDetails.jsx';
import LocationModal from './content/NewLocationModal';

const filtersElementsDef = {
    repairers: {
        title:'Repairers',
        options: [],
        IconComponent: BuildCircleOutlinedIcon,
    },
    status: {
        title: 'Status',
        options: [],
        IconComponent: InfoOutlinedIcon,
    },
    invoice: {
        title: 'Invoice Type',
        options: [],
        IconComponent: InfoOutlinedIcon,
    },
};

const generateFilterElementsDef = (filterOptions) => {
    return Object.entries(filterOptions).reduce((acc, [id, options]) => {
        if (filtersElementsDef[id]) {
            acc[id] = { ...filtersElementsDef[id], options };
        }
        return acc;
    }, {});
};

const ContentContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: 8,
    boxShadow: theme.shadows[1],
    padding: theme.spacing(0),
    width: '100%',
}));

const LocationsScreen = () => {
    const theme = useTheme();
    const locationContext = useLocations();
    const errorContext = useError();
    // MTODO: move to reducer and change on every location API request
    const [addLocationPopupOpen, setAddLocationPopupOpen] = useState(false);

    const locationFilters = React.useMemo(() => generateFilterElementsDef(locationContext.state.filters), [locationContext.state.filters]);

    const titleActions = [
        {
            title: 'Add Location',
            onClick: () => setAddLocationPopupOpen(true),
            iconComponent: AddLocationAlt,
        },
    ];

    const handleLocationFilterChange = (propName, selectedOptions) => {
        locationContext.DISPATCH({ type: LOCATION_TYPES.UPDATE_FILTERS, payload: { propName, selectedOptions } });
    };

    const handleLocationSearchTermChange = (searchTerm) => {
        locationContext.DISPATCH({ type: LOCATION_TYPES.UPDATE_SEARCH_TERM, payload: { searchTerm } });
    };

    const reloadLocations = () => {
        locationContext.actions.refreshLocations(
            { filter: locationContext.state.filters, textQuery: locationContext.state.searchTerm },
            errorContext,
        );
    };

    useEffect(
        () => reloadLocations(),
        [],
    );

    const debouncedReloadLocations = useCallback(debounce(reloadLocations, 500), []);

    // To disable locations reload on filter or search change (until API support is added), remove them from dependecy list
    useEffect(
        () => debouncedReloadLocations(),
        [locationContext.state.filters, locationContext.state.searchTerm],
    );

    const newLocationModalElem = addLocationPopupOpen ? (
        <LocationModal open={addLocationPopupOpen} onClose={() => {
            setAddLocationPopupOpen(false);
            locationContext.DISPATCH({ type: LOCATION_TYPES.CLEAR_NEW_LOCATION });
        }} />
    ) : null;

    return (
        <Grid container display={'flex'} direction={'column'}>
            {newLocationModalElem}
            <TitlePanel title='Locations' actions={titleActions} />
            <Grid container display={'flex'}>
                <ContentContainer>
                    <SearchAndFilterPanel
                        filters={locationFilters}
                        onFilterChange={handleLocationFilterChange}
                        searchTerm={locationContext.state.searchTerm ?? ''}
                        onSearchTermChange={handleLocationSearchTermChange}
                        searchOptions={['Lemur Lot', 'The Jags']}
                    />
                    <Divider sx={{ color: theme.palette.grey[50], width: '100%' }} />
                    <ListAndDetailsPanel
                        list={locationContext?.state?.locations || []}
                        singularTitle='Location'
                        pluralTitle='Locations'
                        listElement={LocationList}
                        detailElement={LocationDetails}
                        loaded={locationContext.state.loaded}
                    />
                </ContentContainer>
            </Grid>
        </Grid>
    );
};

export default LocationsScreen;
