
// @ts-nocheck
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Stack, Typography, useTheme } from '@mui/material';

import SendIcon from '@mui/icons-material/Send';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';

import Form from 'components/Form';
import Modal from 'components/Modal';

const modalsDefinitions = {
    'resend': {
        name: 'resend',
        title: 'Resend Invite',
        subTitle: 'Do  you want to resend this invite?',
        Icon: SendIcon,
        yesButtonText: 'Yes',
        noButtonText: 'Cancel',
    },
    'cancel': {
        name: 'cancel',
        title: 'Cancel Invite',
        subTitle: 'Do  you want to cancel this invite?',
        Icon: CancelScheduleSendIcon,
        yesButtonText: 'Yes',
        noButtonText: 'No',
    },
};

const InvitationStatusForm = ({
    repairer,
}) => {
    const theme = useTheme();
    const [openedModal, setOpenedModal] = useState(null);
    const [modalSuccess, setModalSuccess] = useState(false);

    const modalElem = !openedModal ? null : (
        <Modal
            open={openedModal != null}
            success={modalSuccess}
            title={(
                <Stack direction='column' display='flex' alignItems='center' spacing={2}>
                    <openedModal.Icon fontSize='large' />
                    <Typography sx={{ color: theme.palette.grey[900], fontSize: 24, fontWeight: 400, lineHeight: 'normal' }}>
                        {openedModal.title}
                    </Typography>
                </Stack>
            )}
            subTitle={openedModal.subTitle}
            showCloseButton={true}
            showSubmitButton={false}
            showYesNoButtons={true}
            yesButtonText={openedModal.yesButtonText}
            noButtonText={openedModal.noButtonText}
            onClose={() => {
                setOpenedModal(null);
                setModalSuccess(false);
            }}
            onSubmit={() => {
                // MTODO: handle resending and canceling invitations when API support is added
                if (openedModal.name === modalsDefinitions.resend.name) {
                    console.log('resending invitation to:', repairer.email);
                    setModalSuccess(true);
                } else if (openedModal.name === modalsDefinitions.cancel.name) {
                    console.log('canceling invitation to:', repairer.email);
                    setModalSuccess(true);
                }
            }}
        />
    );

    return (
        <Form.FormContainer
            showCancelBtn
            showSubmitBtn
            cancelBtnDisabled={false}
            submitBtnDisabled={false}
            onCancel={() => { setOpenedModal(modalsDefinitions.cancel); }}
            onSubmit={() => { setOpenedModal(modalsDefinitions.resend); }}
            submitBtnTooltip='Resend Invite'
            submitButtonText='Resend Invite'
            SubmitButtonIcon={SendIcon}
            submitButtonType='outlined'
            cancelButtonText='Cancel Invite'
            CancelButtonIcon={CancelScheduleSendIcon}
            cancelButtonType='outlined'
        >
            {modalElem}
            <Form.TextInput
                id='email-input'
                onChange={() => {}}
                label='Email'
                value={repairer?.email}
                errorMsg={null}
                helperText='Waiting for activation'
                disabled={true}
            />
            <Form.FormLabel
                onChange={() => {}}
                label='Registration Code'
                value={repairer?.registrationCode || ''}
                errorMsg={null}
                disabled={true}
            />
        </Form.FormContainer>
    );
};

InvitationStatusForm.propTypes = {
    repairer: PropTypes.shape({
        email: PropTypes.string,
        registrationCode: PropTypes.string,
    }).isRequired,
};

export default InvitationStatusForm;
