// @ts-nocheck
import notificationAPI from './api';
import TYPES from './types';

export const getNotifications = async (successCB, errorCB) => {
    const handleError = (error) => {
        if (errorCB && typeof errorCB === 'function') {
            errorCB(error);
        } else {
            throw new Error(`Error loading notifications: ${JSON.stringify(error)}`);
        }
    };

    try {
        const response = await notificationAPI.getNotifications();
        if (response && response.status === 200 && response.data) {
            response.data = response.data.map((notif) => ({
                ...notif,
                id: notif._id,
            }));

            if (successCB && typeof successCB === 'function') {
                successCB(response.data);
            }
            return response.data;
        }
        handleError({ status: response.data.status, type: 'API_ERROR', msg: response?.message ?? response });
    } catch (error) {
        handleError({ type: 'API_ERROR', msg: error });
    }
};

export const refreshNotifications = (dispatch) => async (errContext, successCB, errorCB) => {
    const onNotificationsLoadError = ({ status, type, msg }) => {
        if (errContext && typeof errContext?.DISPATCH === 'function') {
            errContext.DISPATCH({
                type,
                payload: { status, alert: msg },
            });
            if (errorCB && typeof errorCB === 'function') {
                errorCB({ status, type, msg });
            }
        } else {
            throw new Error(`Error loading notifications: ${JSON.stringify({ status, type, msg })}`);
        }
    };

    const onNotificationsLoaded = (notifications) => {
        dispatch({
            type: TYPES.SET_NOTIFICATIONS,
            payload: notifications,
        });

        if (successCB && typeof successCB === 'function') {
            successCB(notifications);
        }
    };

    await getNotifications(onNotificationsLoaded, onNotificationsLoadError);
};

export const markNotificationAsRead = (dispatch) => async (notificationId, errContext) => {
    try {
        const res = await notificationAPI.markNotificationAsRead(notificationId);
        if (res?.status === 200) {
            dispatch({ type: TYPES.UPDATE_NOTIFICATION, payload: { id: notificationId, read: true } });
        }
    } catch (error) {
        if (errContext) {
            errContext.DISPATCH({ type: 'API_ERROR', payload: error });
        } else {
            throw new Error(`Error updating notification: ${JSON.stringify(error)}`);
        }
    }
};
