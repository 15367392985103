import React from 'react';
import Lottie from 'lottie-react-web';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';

import stylesDef from './styles';
import animation from './lottie.json';

const Loading = ({ loading, fullScreen = true, width = 200, height = 200 }) => {
    const styles = fullScreen ? stylesDef.loading : {};

    return !loading ? null : (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', ...styles }}>
            <Lottie
                options={{
                    animationData: animation,
                    loop: true,
                }
                }
                width={width}
                height={height}
            />
        </Box>
    );
};

Loading.propTypes = {
    loading: PropTypes.bool,
    fullScreen: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
};

export default Loading;
