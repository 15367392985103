import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material';

const IconContainer = ({
    icon,
    width = '44px',
    height = '44px',
    padding = '8px',
    backgroundCircle = false,
    sx = {},
    iconWidth = '24px',
    iconHeight = '24px',
    iconProps = {},
    ...rest
}) => {
    const theme = useTheme();
    const IconComponent = icon;

    return (
        <div
            style={{
                display: 'flex',
                width: width,
                height: height,
                padding: padding,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
                flexShrink: 0,
                borderRadius: '100px',
                background: backgroundCircle ? theme.palette.grey[30] : 'transparent',
                ...sx,
            }}
            {...rest}
        >
            <IconComponent width={iconWidth} height={iconHeight} {...iconProps} />
        </div>
    );
};

IconContainer.propTypes = {
    icon: PropTypes.elementType.isRequired,
    width: PropTypes.string,
    height: PropTypes.string,
    padding: PropTypes.string,
    backgroundCircle: PropTypes.bool,
    sx: PropTypes.object,
    iconWidth: PropTypes.string,
    iconHeight: PropTypes.string,
    iconProps: PropTypes.object,
};

export default IconContainer;
