
import React from 'react';
import PropTypes from 'prop-types';

import { useTheme, Grid, Select, MenuItem, Box, Typography } from '@mui/material';

import IconContainer from 'components/Icons/IconContainer';

import FilterButton from './FilterButton';
import SearchButton from './SearchButton';

export default function SearchAndFilterPanel({
    id,
    filters,
    onFilterChange,
    sortConfig,
    selectedSort,
    onSortChange = (e) => {},
    searchTerm,
    onSearchTermChange,
    searchOptions = [],
}) {
    const theme = useTheme();

    const searchElem = (
        <SearchButton
            value={searchTerm}
            onChange={onSearchTermChange}
            options={searchOptions}
        />
    );

    const sortElem = sortConfig?.title && sortConfig?.options?.length ? (
        <Grid item key={`${id}-sort-element`}>
            <Select
                labelId={`${id}-sort-select-label`}
                id={`${id}-sort-select`}
                value={selectedSort || sortConfig.options[0].id}
                label='Age'
                onChange={(e) => { onSortChange(e.target.value); }}
                sx={{
                    border: '1px solid #CAD2D9',
                    borderRadius: '8px',
                    padding: '8px',
                    paddingRight: '2px',
                    width: 'auto',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '32px',
                    fontSize: '12px',
                }}
            >
                {sortConfig.options.map((option) => (
                    <MenuItem key={option.id} value={option.id} sx={{ fontSize: '12px', width: '100%' }}>
                        { !option.icon ? null : (
                            <Box sx={{ mr: 1 }}>
                                <IconContainer
                                    icon={option.icon}
                                    width='15px'
                                    height='15px'
                                    iconWidth='15px'
                                    iconHeight='15px'
                                    padding='5px'
                                />
                            </Box>
                        )}
                        <Typography variant='subtitle2' sx={{ fontSize: '12px', color: theme.palette.grey[900], marginRight: '8px' }}>
                            {option.name}
                        </Typography>
                    </MenuItem>
                ))}
            </Select>
        </Grid>
    ) : null;

    const filterElems = Object.entries(filters).map(([id, { title, options, IconComponent }]) => (
        <Grid item key={title}>
            <FilterButton
                title={title}
                options={options}
                IconComponent={IconComponent}
                onSelect={(selectedFilters) => {
                    onFilterChange(id, selectedFilters);
                }}
                multi={true}
            />
        </Grid>
    ));

    return (
        <Grid id={`${id}-search-filter-panel`} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
            <Grid item container direction='row' justifyContent='left' p={1} mx={0} xs={6} spacing={1} alignSelf='left'>
                <Grid item>
                    { searchElem }
                </Grid>
                <Grid item>
                    { sortElem }
                </Grid>
            </Grid>
            <Grid item container direction='row' justifyContent='end' p={1} mx={1} xs={10} spacing={1}>
                { filterElems }
            </Grid>
        </Grid>
    );
}

SearchAndFilterPanel.propTypes = {
    id: PropTypes.string,
    filters: PropTypes.object.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    sortConfig: PropTypes.shape({
        title: PropTypes.string,
        options: PropTypes.arrayOf(PropTypes.object),
        IconComponent: PropTypes.any,
    }),
    selectedSort: PropTypes.string,
    onSortChange: PropTypes.func,
    searchTerm: PropTypes.string.isRequired,
    onSearchTermChange: PropTypes.func.isRequired,
    searchOptions: PropTypes.arrayOf(PropTypes.string),
};
