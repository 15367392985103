
import TYPES from './types';

const INITIAL_NEW_REPAIRER_STATE = {
    name: '',
    'contact_info' : {
        email: '',
        'phone_number': '',
    },
    services: [],
    locations: [],
    status: '',
};

export const INITIAL_STATE = {
    loading: true,
    activeRepairerId: null,
    repairers: [],
    newRepairer: { ...INITIAL_NEW_REPAIRER_STATE, 'contact_info': { ...INITIAL_NEW_REPAIRER_STATE['contact_info'] } },
};

export const INIT = function () {
    return { ...INITIAL_STATE };
};

const RepairerReducer = function (state, action) {
    // console.log('RepairerReducer', JSON.stringify(action, null, 2));
    switch (action.type) {
        case TYPES.INIT:{
            return { ...INITIAL_STATE };
        }
        case TYPES.SET_REPAIRERS:{
            const newRepairers = [];

            for (const rep of action.payload) {
                newRepairers.push(formatRepairerIntoState(rep));
            }

            return { ...state, repairers: newRepairers };
        }
        case TYPES.SET_ACTIVE_REPAIRER:{
            return { ...state, activeRepairerId: action.payload.id };
        }
        case TYPES.EDIT_REPAIRER_NOTES:{
            const { notes } = action.payload;

            const repairer = { ...(state.repairers.find((l) => l.id === state.activeRepairerId) || {}) };
            const newRepairers = [...state.repairers.filter((loc) => loc.id !== state.activeRepairerId)];

            repairer.notes = notes;

            newRepairers.push(repairer);

            return { ...state, repairers: newRepairers };
        }
        case TYPES.EDIT_REPAIRER_LOCATION_ACTIVE:{
            const { name, active } = action.payload;

            const repairer = { ...(state.repairers.find((l) => l.id === state.activeRepairerId) || {}) };
            const newRepairers = [...state.repairers.filter((loc) => loc.id !== state.activeRepairerId)];

            repairer.locations = repairer.locations.map((l) => ({ ...l, active: (l.name === name) ? active : l.active }));

            newRepairers.push(repairer);

            return { ...state, repairers: newRepairers };
        }
        default:
            return { ...state };
    }
};

const formatRepairerIntoState = (repairer) => {
    return repairer;
};

export default RepairerReducer;
