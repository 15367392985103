
import React, { useState } from 'react';

import { Grid, useTheme } from '@mui/material';

import { ReactComponent as AddCircleIcon } from 'assets/images/add_circle_outlined_icon.svg';

import { ReactComponent as ToolsIcon } from 'assets/images/tools_icon.svg';

import IconContainer from 'components/Icons/IconContainer';
import ButtonCard from 'components/Cards/ButtonCard';
import ActionButton from 'components/Buttons/ActionButton';
import InviteRepairerModal from './InviteRepairerModal';
import RepairersModal from './RepairersModal';

// MTODO: replace with real repairers when support is added to RoDx API
const fakeRepairersList = [
    {
        id: 1,
        name: 'Texas Dent Works 1',
        description: 'Mechanical, Paint, PDR, Interior Repair 1',
        status: 'Pending',
        email: 'john@email.com',
        'phone_number': '9092134456',
        notes: 'Test Note',
        active: true,
    },
    {
        id: 2,
        name: 'Texas Dent Works 2',
        description: 'Mechanical, Paint, PDR, Interior Repair 2',
        status: 'Available',
        email: 'john@email.com',
        'phone_number': '9092134456',
        notes: 'Test Note',
        active: true,
    },
    {
        id: 3,
        name: 'Texas Dent Works 3',
        description: 'Mechanical, Paint, PDR, Interior Repair 3',
        status: 'Unavailable',
        email: 'john@email.com',
        'phone_number': '9092134456',
        notes: 'Test Note',
        active: true,
    },
    {
        id: 4,
        name: 'Texas Dent Works 4',
        description: 'Mechanical, Paint, PDR, Interior Repair 4',
        status: 'Inactive',
        email: 'john@email.com',
        'phone_number': '9092134456',
        notes: 'Test Note',
        active: false,
    },
];

export default function RepairerList() {
    const theme = useTheme();
    const [inviteRepairerModalOpen, setInviteRepairerModalOpen] = useState(false);
    const [inviteRepairerSuccess, setInviteRepairerSuccess] = useState(false);
    const [repairerDetailsModalOpen, setRepairerDetailsModalOpen] = useState(false);
    const [selectedRepairerId, setSelectedRepairerId] = useState(null);

    //MTODO: remove when using repairer context after RoDx API for repairers is defined
    const [fakeRepairers, setFakeRepairers] = useState(fakeRepairersList);

    const selectRepairer = async (id) => {
        // MTODO
        console.log('Repairer selected', id);
        setSelectedRepairerId(id);
        setRepairerDetailsModalOpen(true);
    };

    const inviteRepairer = async (email) => {
        // MTODO
        console.log('submit', email);
        setInviteRepairerSuccess(true);
    };

    const handlePropChange = (propName, propVal) => {
        // MTODO: update prop in context
        // console.log('prop changed', propName, propVal);
        setFakeRepairers((fakeR) => {
            const rs = fakeR.map((r) => ({ ...r }));
            rs.filter((r) => r.id === selectedRepairerId)[0][propName] = propVal;
            return rs;
        });
    };

    const repairerCards = fakeRepairers
        .sort((a, b) => (a['name'] || '')
            .localeCompare(b['name'], undefined, { numeric: true, sensitivity: 'base' }))
        .map((repairer) => {
            const cardIsActive = (repairer?.status || '').toLowerCase() === 'available';
            return (
                <Grid item key={repairer.id}>
                    <ButtonCard
                        content={{ ...repairer, text: 'Locations' }}
                        onClick={selectRepairer}
                        active={cardIsActive}
                        showStatus={true}
                        showBorder={true}
                        compact={false}
                        iconVerticalAlign='top'
                        iconElement={
                            <IconContainer
                                icon={ToolsIcon}
                                backgroundCircle
                                sx={{ background: cardIsActive ? theme.palette.grey[30] : theme.palette.grey[40] }}
                            />
                        }
                    />
                </Grid>
            );
        });

    let modalElem = null;
    if (inviteRepairerModalOpen) {
        modalElem = (
            <InviteRepairerModal
                open={inviteRepairerModalOpen}
                onSubmit={inviteRepairer}
                onClose={() => {
                    setInviteRepairerModalOpen(false);
                    setInviteRepairerSuccess(false);
                }}
                success={inviteRepairerSuccess}
            />
        );
    } else if (repairerDetailsModalOpen && selectedRepairerId != null) {
        modalElem = (
            <RepairersModal
                open={repairerDetailsModalOpen && selectedRepairerId != null}
                onClose={() => {
                    setRepairerDetailsModalOpen(false);
                }}
                repairer={fakeRepairers.filter((r) => r.id === selectedRepairerId)?.[0] ?? {}} // MTODO: REPLACE WITH REPAIRER FROM CONTEXT
                handlePropChange={handlePropChange}
            />
        );
    }

    return (
        <Grid
            container
            flexDirection={'column'}
            spacing={1}
            display={'flex'}
            sx={{ width: '100%', px: 2 }}
            justifyContent={'space-between'}
        >
            {modalElem}
            <Grid item>
                <ActionButton
                    variant='outlined'
                    text='Invite New Repairer'
                    onClick={() => { setInviteRepairerModalOpen(true); }}
                    IconComponent={AddCircleIcon}
                />
            </Grid>
            {repairerCards}
        </Grid>
    );
}
