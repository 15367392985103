import React from 'react';
import PropTypes from 'prop-types';

import { Button, useTheme } from '@mui/material';

const ActionButton = ({ onClick, text, IconComponent = null, IconProps = {}, variant = 'outlined' }) => {
    const theme = useTheme();

    return (
        <Button
            // @ts-ignore
            variant={variant}
            onClick={onClick}
            sx={{
                width: '100%',
                borderRadius: '8px',
                border: `1px solid ${theme.palette.grey[50]}`,
                background: theme.palette.white,
            }}
        >
            { !IconComponent ? null : (<IconComponent width='24px' height='24px' { ...IconProps } />)}
            <span style={{
                marginLeft: '5px',
                lineHeight: 'normal',
                textTransform: 'capitalize',
            }}>
                {text}
            </span>
        </Button>
    );
};

ActionButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    IconComponent: PropTypes.elementType,
    IconProps: PropTypes.object,
    variant: PropTypes.oneOf(['outlined']),
};

export default ActionButton;
