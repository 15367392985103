
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@mui/material';

import Accordion from 'components/Containers/Accordion';

const MultiAccordionContainer = ({ accordions, multiExpand = false, defaultState = {}, accordionProps = {}, ...rest })=> {
    const [expandedAcc, setExpandedAcc] = useState(defaultState);

    const handleAccExpandChange = (accName) => (_, state) => {
        setExpandedAcc((oldState) => {
            const newState = { ...oldState };

            if (!multiExpand) {
                if (state === true) {
                    Object.keys(newState).forEach((k) => newState[k] = false );
                }
            }

            newState[accName] = state;

            return newState;
        });
    };

    const accordionsElem = accordions.map(({ summary, content, props = {} }) => {
        if (!summary || !content) {
            return null;
        }
        return (
            <Grid item key={summary}>
                <Accordion
                    summary={summary}
                    expanded={expandedAcc[summary] === true}
                    onChange={handleAccExpandChange(summary)}
                    {...accordionProps}
                    {...props}
                >
                    {content}
                </Accordion>
            </Grid>
        );
    });

    return (
        <Grid container direction='column' rowSpacing={2} {...rest}>
            {accordionsElem}
        </Grid>
    );
};

MultiAccordionContainer.propTypes = {
    accordions: PropTypes.arrayOf(PropTypes.shape({
        summary: PropTypes.string.isRequired,
        content: PropTypes.node.isRequired,
        props: PropTypes.object,
    })).isRequired,
    multiExpand: PropTypes.bool,
    defaultState: PropTypes.object,
    accordionProps: PropTypes.object,
};

export default MultiAccordionContainer;
