
// @ts-nocheck
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Form from 'components/Form';

const CompanyForm = ({
    company,
    validations,
    handlePropChange,
    handleSubPropChange,
    handleMailingAddressChange,
    handleBillingAddressChange,
    showCancelBtn = false,
    showSubmitBtn = false,
    cancelBtnDisabled = true,
    submitBtnDisabled = true,
    onSubmit = () => {},
    onCancel = () => {},
    submitBtnTooltip = '',
}) => {
    const [mailingAddressText, setMailingAddressText] = useState('');

    const onMailingAddressTextChange = (event) => {
        setMailingAddressText(event.target.value);
        // Remove selected place when address input is edited
        handleMailingAddressChange();
    };

    const onMailingAddressSelect = (place) => {
        handleMailingAddressChange(place);
        setMailingAddressText(place.formatted_address);
    };

    useEffect(() => {
        setMailingAddressText(company?.mailing_address?.formatted_address);
    }, [company?.mailing_address?.formatted_address]);

    const [billingAddressText, setBillingAddressText] = useState('');

    const onBillingAddressTextChange = (event) => {
        setBillingAddressText(event.target.value);
        // Remove selected place when address input is edited
        handleBillingAddressChange();
    };

    const onBillingAddressSelect = (place) => {
        handleBillingAddressChange(place);
        setBillingAddressText(place.formatted_address);
    };

    useEffect(() => {
        setBillingAddressText(company?.billing_address?.formatted_address);
    }, [company?.billing_address?.formatted_address]);

    return (
        <Form.FormContainer
            showCancelBtn={showCancelBtn}
            showSubmitBtn={showSubmitBtn}
            cancelBtnDisabled={cancelBtnDisabled}
            submitBtnDisabled={submitBtnDisabled}
            onSubmit={onSubmit}
            onCancel={onCancel}
            submitBtnTooltip={submitBtnTooltip}
        >
            <Form.TextInput
                id='name-input'
                onChange={(event) => handlePropChange('name', event.target.value)}
                label='Company Name'
                value={company?.name ?? ''}
                errorMsg={validations?.name}
            />
            <Form.AddressInput
                id='mailing-address-input'
                onChange={onMailingAddressTextChange}
                onSelect={onMailingAddressSelect}
                label='Mailing Address'
                value={mailingAddressText ?? ''}
                errorMsg={validations?.mailing_address}
            />
            <Form.TextInput
                id='mailing-address2-input'
                onChange={(event) => handleSubPropChange('mailing_address', 'subpremise', event.target.value)}
                label='Mailing Address 2'
                value={company?.mailing_address?.subpremise}
                errorMsg={validations?.subpremise}
            />
            <Form.AddressInput
                id='billing-address-input'
                onChange={onBillingAddressTextChange}
                onSelect={onBillingAddressSelect}
                label='Billing Address'
                value={billingAddressText ?? ''}
                errorMsg={validations?.billing_address}
            />
            <Form.TextInput
                id='billing-address2-input'
                onChange={(event) => handleSubPropChange('billing_address', 'subpremise', event.target.value)}
                label='Billing Address 2'
                value={company?.billing_address?.subpremise}
                errorMsg={validations?.subpremise}
            />
            <Form.TextInput
                id='contact-email-input'
                onChange={(event) => handleSubPropChange('contact_info', 'email', event.target.value)}
                label='Email'
                value={company?.contact_info?.email}
                errorMsg={validations?.contact_info?.email}
            />
            <Form.TextInput
                id='contact-phone-input'
                onChange={(event) => handleSubPropChange('contact_info', 'phone_number', event.target.value)}
                label='Phone'
                value={company?.contact_info?.phone_number}
                maskType={Form.TYPES.TEXT_MASK.PHONE}
                errorMsg={validations?.contact_info?.phone_number}
            />
        </Form.FormContainer>
    );
};

CompanyForm.propTypes = {
    company: PropTypes.shape({
        'name': PropTypes.string,
        'mailing_address': PropTypes.shape({
            'formatted_address': PropTypes.string,
            subpremise: PropTypes.string,
        }),
        'billing_address': PropTypes.shape({
            'formatted_address': PropTypes.string,
            subpremise: PropTypes.string,
        }),
        'contact_info': PropTypes.shape({
            email: PropTypes.string,
            'phone_number': PropTypes.string,
        }),
    }).isRequired,
    validations: PropTypes.object,
    handlePropChange: PropTypes.func.isRequired,
    handleSubPropChange: PropTypes.func.isRequired,
    handleMailingAddressChange: PropTypes.func.isRequired,
    handleBillingAddressChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    showCancelBtn: PropTypes.bool,
    showSubmitBtn: PropTypes.bool,
    cancelBtnDisabled: PropTypes.bool,
    submitBtnDisabled: PropTypes.bool,
    submitBtnTooltip: PropTypes.string,
};

export default CompanyForm;
