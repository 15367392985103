/* eslint-disable react/prop-types */
import React from 'react';
import { AppProvider } from './contexts/AppContext';
import Router from 'router/Router';

const App = () => {
    console.log('App');

    return (
        <AppProvider>
            <div className='app'>
                <main className='content'>
                    <Router />
                </main>
            </div>
        </AppProvider>
    );
};

export default App;
