
export const MODIFIED_BY = {
    supplier: 'Job Supplier',
    repairer: 'Repairer',
};

export const SERVICE_STATUS = {
    'Open': 'open', // MTODO: same as creation?
    'Accepted': 'accepted',
    'PendingApproval': 'pending approval',
    'Declined': 'declined',
    'InProgress': 'in progress',
    'Completed': 'completed',
    'Invoiced': 'invoiced', // MTODO
    'Closed': 'closed', // MTODO
    'Cancelled': 'cancelled',
};

export const capitalizeWords = (sentence) => {
    if (!sentence?.length) return sentence;
    return sentence.split(' ').map((w) => `${w[0].toLocaleUpperCase()}${w.slice(1)}`).join(' ');
};

export const formatPrice = (price) => (new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(price));

export const formatMonthDayYear = (date) => {
    const dayInMonth = date.getDate();
    const daySuffixList = ['th', 'st', 'nd', 'rd', 'th'];
    const daySuffixIndex = Math.min(4, dayInMonth % 10);
    const daySuffix = daySuffixList[daySuffixIndex];

    return ` ${date.toLocaleString('default', { month: 'long' })} ${dayInMonth}${daySuffix}, ${date.getFullYear()}`;
};

export const mediaContentIsValid = (content) => {
    const textValid = content['media_type'] === 'Text' && content.text?.length;
    const imageValid = content['media_type'] === 'Image' && content.url?.length;

    return textValid || imageValid;
};

export const getMediaContentOverallType = (mediaItems) => {
    const existingTypes = mediaItems
        .filter((content) => mediaContentIsValid(content))
        .map((content) => content['media_type'] === 'Text' ? 'Notes' : 'Attachments')
        .filter((type) => type != null)
        .reduce((acc, cur) => ({ ...acc, [cur] : true }), {});

    const uniqueTypes = Object.keys(existingTypes);

    return uniqueTypes.length ? uniqueTypes.join(' and ') : null;
};
